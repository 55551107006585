import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Box,
  Badge,
  Tooltip,
} from "@material-ui/core";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import BusinessIcon from "@material-ui/icons/Business";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatarRed: {
    backgroundColor: colors.red[600],
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
  },
  avatarGreen: {
    backgroundColor: colors.green[600],
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
  },
  avatarYellow: {
    backgroundColor: colors.yellow[600],
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
  },
  chatAvatar: {
    backgroundColor: colors.deepOrange[600],
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: "-5px",
  },
  chatAvatarPatient: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: "-5px",
  },
  chatAvatarContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    justifyContenct: "flext-end",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  typeBold: {
    fontWeight: 700,
  },
  listPrimaryBold: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: 1,
    marginBottom: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  badgeRed: {
    color: colors.red[600],
  },
  reqGrid: {
    width: "100%",
  },
  searchArea: {
    margin: 0,
    border: 0,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
}));
const TicketQueueTab = (props) => {
  const classes = useStyles();
  return (
    <Card>
      <CardContent style={{ overflowY: "auto" }}>
        <Box height={"80vh"} position="relative">
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid className={classes.reqGrid} item>
              <div className={classes.searchArea}>
                <Typography
                  style={{ flex: "50%" }}
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  {props?.title ?? ""}
                </Typography>
                {props.search}
                {props.sortSelect}
                {props.filterSelect}
              </div>
              <List>
                {props?.requests &&
                  props.requests.map((appt, i) => (
                    <div
                      key={appt.apptrequestid + appt.apptreqstate}
                      onClick={() => props.onClick(appt.apptrequestid)}
                      style={{ width: "100%" }}
                    >
                      <ListItem
                        button
                        onClick={() => props.updateSelected(appt.apptrequestid)}
                        selected={props.selectedRequest === appt.apptrequestid}
                      >
                        <ListItemAvatar>
                          <Badge
                            variant="dot"
                            invisible={appt.addBadge === true ? false : true}
                            color="error"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            badgeContent=" "
                            overlap="rectangular"
                          >
                            <Avatar
                              className={
                                appt.scoretierabbr === "ASAP"
                                  ? classes.avatarGreen
                                  : appt.scoretierabbr === "AM"
                                  ? classes.avatarYellow
                                  : classes.avatarRed
                              }
                            >
                              {appt.scoretierabbr}
                            </Avatar>
                          </Badge>
                        </ListItemAvatar>
                        <Box display="flex" flexDirection="column">
                          <Box display="flex" flexDirection="row">
                            <ListItemText
                              disableTypography
                              className={classes.listPrimaryBold}
                              primary={
                                appt.patientfirstname +
                                " " +
                                appt.lastname +
                                " " +
                                "(" +
                                appt.totalscoremax +
                                ")"
                              }
                            />
                            {appt?.referring_physician && (
                              <Tooltip
                                title={`Referral from ${appt.referring_physician}`}
                                placement="right-start"
                              >
                                <LocalHospitalIcon color="primary" />
                              </Tooltip>
                            )}
                            {appt?.workcomps?.[0]?.wc_injury && (
                              <Tooltip
                                title={`Workers Comp`}
                                placement="right-start"
                              >
                                <BusinessIcon color="primary" />
                              </Tooltip>
                            )}
                          </Box>
                          <ListItemText secondary={appt.complaintname} />
                          <div className={classes.chatAvatarContainer}>
                            {appt?.chatUsers &&
                              appt.chatUsers.map((chatUser) => (
                                <Tooltip
                                  key={chatUser.id}
                                  title={
                                    chatUser?.id && chatUser.id.length === 6
                                      ? `${chatUser?.name} (Patient)`
                                      : chatUser?.name
                                  }
                                  placement="right-start"
                                >
                                  <Avatar
                                    alt={chatUser?.name}
                                    className={
                                      chatUser?.id && chatUser.id.length === 6
                                        ? classes.chatAvatarPatient
                                        : classes.chatAvatar
                                    }
                                  >
                                    {chatUser && chatUser?.name.charAt(0)}{" "}
                                  </Avatar>
                                </Tooltip>
                              ))}
                          </div>
                        </Box>
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TicketQueueTab;
