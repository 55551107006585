import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const LineGraphRequests = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  // const data = {
  //   datasets: [
  //     {
  //       barThickness: 12,
  //       maxBarThickness: 10,
  //       barPercentage: 0.5,
  //       categoryPercentage: 0.5,
  //       backgroundColor: theme.palette.primary.main,
  //       data: [30, 35, 41, 32, 38, 41, 52],
  //       label: "Appointments",
  //     },
  //     {
  //       barThickness: 12,
  //       maxBarThickness: 10,
  //       barPercentage: 0.5,
  //       categoryPercentage: 0.5,
  //       backgroundColor: colors.grey[200],
  //       data: [34, 61, 50, 41, 49, 55, 68],
  //       label: "Requests",
  //     },
  //   ],
  //   labels: [
  //     "Jeff Ellis",
  //     "Jane Smith",
  //     "John Doe",
  //     "Curtis Jones",
  //     "Alvin Martinez",
  //     "Ted Lasso",
  //   ],
  // };

  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774",
      },
    ],
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            Today
          </Button>
        }
        title="Appointments Scheduled vs. Appointments Requested - by Agent"
      />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          {/* <Bar data={data} options={options} /> */}
          <Line data={data} />
        </Box>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Details
        </Button>
      </Box>
    </Card>
  );
};

LineGraphRequests.propTypes = {
  className: PropTypes.string,
};

export default LineGraphRequests;
