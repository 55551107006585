import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  colors,
  makeStyles,
  Tabs,
  Tab,
  AppBar,
  Badge,
  CircularProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { BadgeContext } from "../../../BadgeContext";
import PatientSearch from "./PatientSearch";
import TicketQueueTab from "./TicketQueueTab.js";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatarRed: {
    backgroundColor: colors.red[600],
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
  },
  avatarGreen: {
    backgroundColor: colors.green[600],
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
  },
  avatarYellow: {
    backgroundColor: colors.yellow[600],
    height: 60,
    width: 60,
    marginRight: theme.spacing(2),
  },
  chatAvatar: {
    backgroundColor: colors.deepOrange[600],
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: "-5px",
  },
  chatAvatarPatient: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: "-5px",
  },
  chatAvatarContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "No Service Line",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    justifyContenct: "flext-end",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  typeBold: {
    fontWeight: 700,
  },
  listPrimaryBold: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: 1,
    marginBottom: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  badgeRed: {
    color: colors.red[600],
  },
  reqGrid: {
    width: "100%",
  },
  searchArea: {
    margin: 0,
    border: 0,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  loader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    marginTop: theme.spacing(7),
  },
}));

const TicketQueue = (props) => {
  const classes = useStyles();

  const [selected, setSelected] = useState(null);
  const { badges, setBadges } = useContext(BadgeContext);
  const [tabBadgeVisible, setTabBadgeVisible] = useState({
    newInvisible: true,
    pendingInvisible: true,
    archivedInvisible: true,
  });

  // tabs
  const tabNameToIndex = {
    0: "new",
    1: "pending",
    2: "archived",
  };

  const indexToTabName = {
    new: 0,
    pending: 1,
    archived: 2,
  };

  const [selectedTab, setSelectedTab] = useState(indexToTabName["new"]);
  const [newReqs, setNewReqs] = useState();
  const [pendingReqs, setPendingReqs] = useState();
  const [archivedReqs, setArchivedReqs] = useState();
  const [sortOption, setSortOption] = useState("totalscoremax");
  const [filterOption, setFilterOption] = useState("All Service Lines");

  const updateSelected = (event) => {
    setSelected(event);
  };

  const handleChange = (event, newValue) => {
    // history.push(`/home/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  const updateTab = (tabId) => {
    setSelectedTab(tabId);
  };

  let apptReqs = props?.tickets;

  const handleSortTabData = (tabId, sortBy) => {
    /**
     * return sorted requests per tab
     * sort null values to the bottom
     */
    let sortedRequests = [];

    if (sortBy === "apptrequestid") {
      sortedRequests = apptReqs
        .filter(
          (apptReq) =>
            apptReq.apptreqstate === tabId &&
            (filterOption === "All Service Lines"
              ? apptReq.servicelinename !== -1
              : apptReq.servicelinename === filterOption)
        )
        .sort((a, b) => {
          if (a[sortBy] === b[sortBy]) {
            return 0;
          } else if (a[sortBy] === null) {
            return 1;
          } else if (b[sortBy] === null) {
            return -1;
          } else {
            return a[sortBy] > b[sortBy] ? 1 : -1;
          }
        });
    } else {
      sortedRequests = apptReqs
        .filter(
          (apptReq) =>
            apptReq.apptreqstate === tabId &&
            (filterOption === "All Service Lines"
              ? apptReq.servicelinename !== -1
              : apptReq.servicelinename === filterOption)
        )
        .sort((a, b) => {
          if (a[sortBy] === b[sortBy]) {
            return 0;
          } else if (a[sortBy] === null) {
            return 1;
          } else if (b[sortBy] === null) {
            return -1;
          } else {
            return a[sortBy] < b[sortBy] ? 1 : -1;
          }
        });
    }

    if (tabId === 1) {
      setNewReqs([...sortedRequests]);
      return;
    }
    if (tabId === 2) {
      setPendingReqs([...sortedRequests]);
      return;
    }
    if (tabId === 3) {
      setArchivedReqs([...sortedRequests]);
      return;
    }
  };

  const handleSort = (sortBy, tabId) => {
    setSortOption(sortBy);
    handleSortTabData(tabId + 1, sortBy);
  };

  useEffect(() => {
    if (apptReqs) {
      handleSortTabData(1, sortOption);
      handleSortTabData(2, sortOption);
      handleSortTabData(3, sortOption);
    }
  }, [apptReqs, selectedTab, badges, filterOption]);

  const sortSelectOptions = [
    { id: "totalscoremax", sortBy: "Highest Value First" },
    { id: "apptrequestid", sortBy: "Oldest First" },
    { id: "referring_physician", sortBy: "Referrals First" },
  ];

  const filterSelectOptions = [
    { id: "All Service Lines", filterBy: "All Service Lines" },
    { id: "Foot/Ankle", filterBy: "Foot/Ankle" },
    { id: "Sports", filterBy: "Sports" },
    { id: "Shoulder", filterBy: "Shoulder" },
    { id: "Spine", filterBy: "Spine" },
    { id: "Joints", filterBy: "Joints" },
    { id: "Hand", filterBy: "Hand" },
    { id: "Pediatric", filterBy: "Pediatric" },
    {
      id: "Pediatric Nurse Practitioner",
      filterBy: "Pediatric Nurse Practitioner",
    },
    { id: "Physical Medicine", filterBy: "Physical Medicine" },
    {
      id: "Primary Care Sports Medicine",
      filterBy: "Primary Care Sports Medicine",
    },
    { id: "No Service Line", filterBy: "No Service Line" },
  ];

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <Badge
                variant="dot"
                color="error"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                badgeContent=" "
                overlap="rectangular"
                invisible={
                  newReqs && newReqs.find((newReq) => newReq.addBadge === true)
                    ? false
                    : true
                }
              >
                Active ({newReqs && newReqs.length})
              </Badge>
            }
          />
          <Tab
            label={
              <Badge
                variant="dot"
                invisible={tabBadgeVisible.pendingInvisible}
                color="error"
              >
                Pending ({pendingReqs && pendingReqs.length})
              </Badge>
            }
          />
          <Tab
            label={
              <Badge
                variant="dot"
                invisible={tabBadgeVisible.archivedInvisible}
                color="secondary"
              >
                Archived ({archivedReqs && archivedReqs.length})
              </Badge>
            }
          />
        </Tabs>
      </AppBar>
      {selectedTab === 0 &&
        (newReqs ? (
          <TicketQueueTab
            title={`APPOINTMENT REQUESTS (${newReqs ? newReqs.length : ""})`}
            search={
              <PatientSearch
                style={{ flex: "50%" }}
                updateSelected={(apptId) => updateSelected(apptId)}
                handleTicketSelect={(apptId) => props.onClick(apptId)}
                updateSelectedTab={(tab) => updateTab(tab)}
                reqList={apptReqs}
              />
            }
            sortSelect={
              <TextField
                fullWidth
                label="Requests Sorted By:"
                margin="dense"
                name="selectSort"
                onChange={(e) => handleSort(e.target.value, selectedTab)}
                required
                select
                type="text"
                value={sortOption}
                variant="outlined"
              >
                {sortSelectOptions.map((option, index) => (
                  <MenuItem key={option.id} value={option.id}>
                    {index === 0 ? <em>{option.sortBy}</em> : option.sortBy}
                  </MenuItem>
                ))}
              </TextField>
            }
            filterSelect={
              <TextField
                fullWidth
                label="Requests Filtered By Service Line:"
                margin="dense"
                name="selectFilter"
                onChange={(e) => setFilterOption(e.target.value)}
                // onChange={(e) => handleFilter(e.target.value, selectedTab)}
                select
                type="text"
                value={filterOption}
                variant="outlined"
                defaultValue=""
              >
                {filterSelectOptions.map((option, index) => (
                  <MenuItem key={option.id} value={option.id}>
                    {index === 0 ? <em>{option.filterBy}</em> : option.filterBy}
                  </MenuItem>
                ))}
              </TextField>
            }
            requests={newReqs}
            updateSelected={updateSelected}
            selectedRequest={selected}
            onClick={props.onClick}
          />
        ) : !newReqs && apptReqs !== null ? (
          <div className={classes.loader} style={{ alignItems: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <div></div>
        ))}
      {selectedTab === 1 &&
        (pendingReqs ? (
          <TicketQueueTab
            title={`PENDING REPLY (${pendingReqs ? pendingReqs.length : ""})`}
            search={
              <PatientSearch
                style={{ flex: "50%" }}
                updateSelected={(apptId) => updateSelected(apptId)}
                handleTicketSelect={(apptId) => props.onClick(apptId)}
                updateSelectedTab={(tab) => updateTab(tab)}
                reqList={apptReqs}
              />
            }
            sortSelect={
              <TextField
                fullWidth
                label="Requests Sorted By:"
                margin="dense"
                name="selectSort"
                onChange={(e) => handleSort(e.target.value, selectedTab)}
                required
                select
                type="text"
                value={sortOption}
                variant="outlined"
              >
                {sortSelectOptions.map((option, index) => (
                  <MenuItem key={option.id} value={option.id}>
                    {index === 0 ? <em>{option.sortBy}</em> : option.sortBy}
                  </MenuItem>
                ))}
              </TextField>
            }
            filterSelect={
              <TextField
                fullWidth
                label="Requests Filtered By Service Line:"
                margin="dense"
                name="selectFilter"
                onChange={(e) => setFilterOption(e.target.value)}
                // onChange={(e) => handleFilter(e.target.value, selectedTab)}
                select
                type="text"
                value={filterOption}
                variant="outlined"
                defaultValue=""
              >
                {filterSelectOptions.map((option, index) => (
                  <MenuItem key={option.id} value={option.id}>
                    {index === 0 ? <em>{option.filterBy}</em> : option.filterBy}
                  </MenuItem>
                ))}
              </TextField>
            }
            requests={pendingReqs}
            updateSelected={updateSelected}
            selectedRequest={selected}
            onClick={props.onClick}
          />
        ) : !pendingReqs && apptReqs !== null ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <div></div>
        ))}
      {selectedTab === 2 &&
        (archivedReqs ? (
          <TicketQueueTab
            title={`ARCHIVED (${archivedReqs ? archivedReqs.length : ""})`}
            search={
              <PatientSearch
                style={{ flex: "50%" }}
                updateSelected={(apptId) => updateSelected(apptId)}
                handleTicketSelect={(apptId) => props.onClick(apptId)}
                updateSelectedTab={(tab) => updateTab(tab)}
                reqList={apptReqs}
              />
            }
            sortSelect={
              <TextField
                fullWidth
                label="Requests Sorted By:"
                margin="dense"
                name="selectSort"
                onChange={(e) => handleSort(e.target.value, selectedTab)}
                required
                select
                type="text"
                value={sortOption}
                variant="outlined"
              >
                {sortSelectOptions.map((option, index) => (
                  <MenuItem key={option.id} value={option.id}>
                    {index === 0 ? <em>{option.sortBy}</em> : option.sortBy}
                  </MenuItem>
                ))}
              </TextField>
            }
            filterSelect={
              <TextField
                fullWidth
                label="Requests Filtered By Service Line:"
                margin="dense"
                name="selectFilter"
                onChange={(e) => setFilterOption(e.target.value)}
                // onChange={(e) => handleFilter(e.target.value, selectedTab)}
                select
                type="text"
                value={filterOption}
                variant="outlined"
                defaultValue=""
              >
                {filterSelectOptions.map((option, index) => (
                  <MenuItem key={option.id} value={option.id}>
                    {index === 0 ? <em>{option.filterBy}</em> : option.filterBy}
                  </MenuItem>
                ))}
              </TextField>
            }
            requests={archivedReqs}
            updateSelected={updateSelected}
            selectedRequest={selected}
            onClick={props.onClick}
          />
        ) : !archivedReqs && apptReqs !== null ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <div></div>
        ))}
    </div>
  );
};

TicketQueue.propTypes = {
  className: PropTypes.string,
};

export default TicketQueue;
