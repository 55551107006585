import React, { useContext } from "react";
import { ApptReqContext } from "../../../ApptReqContext";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import Dropzone from "../../../common/Dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    margin: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(4, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  prompt: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

const PatientDocuments = (props) => {
  const classes = useStyles();
  const { oQues } = useContext(ApptReqContext);

  return (
    <div className={classes.contentBody}>
      <Card className={classes.cardBg} variant="outlined">
        <CardContent>
          <Typography className={classes.title} align="center" variant="h1">
            {oQues && oQues.location}
          </Typography>
          <Typography
            className={classes.linkExpired}
            variant="h4"
            align="center"
          >
            To expedite your check in process at the office, please include your
            insurance card, referral documents, and any diagnostic results you
            may have incluing MRI, X-ray, nerve testing, etc.
          </Typography>
          <Grid className={classes.grid} spacing={2} container>
            <Grid item md={12} xs={12}>
              <Dropzone apptId={props.apptId} rky={props.rky} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

PatientDocuments.propTypes = {
  className: PropTypes.string,
};

export default PatientDocuments;
