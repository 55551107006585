import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import {
  RequestsByPeriod,
  RequestBreakdown,
  AbandonRate,
  LineGraphRequests,
  ReportList,
  AverageHoldTime,
  TotalCustomers,
  AbandonRateNew,
  TotalProfit,
} from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    padding: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
          <AverageHoldTime />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalCustomers />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <AbandonRateNew />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalProfit />
        </Grid>*/}
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <RequestBreakdown />
          {/* <LineGraphRequests /> */}
          {/* <AbandonRate />*/}
        </Grid>
        <Grid item lg={4} md={12} xl={3} xs={12}>
          <RequestsByPeriod />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <ReportList />
        </Grid>
        {/* <Grid item lg={12} md={12} xl={12} xs={12}>
          <AbandonRate />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Dashboard;
