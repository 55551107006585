import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles,
  Box,
  // IconButton,
  // Button,
} from "@material-ui/core";
import axios from "axios";
import apiUrl from "../../../common/apiUrl.js";
// import Dialer from "./Dialer";
// import SetPending from "../components/SetPending";
import SetPendingStatic from "../components/SetPendingStatic";
import SetArchived from "../components/SetArchived";
import ReqStatusReason from "./ReqStatusReason";
// import TextsmsIcon from "@material-ui/icons/Textsms";
import PlayVoicemail from "./PlayVoicemail";
import ViewAttachment from "./ViewAttachment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: colors.red[900],
  },
  differenceValue: {
    color: colors.red[900],
    marginRight: theme.spacing(1),
  },
  typeBold: {
    fontWeight: 900,
  },
  patientDetailContainer: {
    display: "flex",
    flexDirection: "column",
  },
  patientDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: theme.spacing(1),
  },
  complaintDetails: {
    borderColor: theme.palette.background.default,
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  chatContainer: {
    width: "100%",
  },
  complaintHeading: {
    fontWeight: 900,
    color: theme.palette.primary.main,
    display: "inline-block",
  },
  detailGrid: {
    width: "100%",
  },
}));

const TicketDetail = (props, { className, ...rest }) => {
  const classes = useStyles();

  const ticket = props.ticket;
  const [token, setToken] = useState("");

  useEffect(() => {
    // Fetch capability voice token
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/voice/generate");
        setToken(result.data.token);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const preferredTimeMap = (preferredTime) => {
    switch (preferredTime) {
      case "monamearly":
        return "Monday - Early AM";
      case "monamlate":
        return "Monday - Late AM";
      case "monpmearly":
        return "Monday - Early PM";
      case "monpmlate":
        return "Monday - Late PM";
      case "tueamearly":
        return "Tuesday - Early AM";
      case "tueamlate":
        return "Tuesday - Late AM";
      case "tuepmearly":
        return "Tuesday - Early PM";
      case "tuepmlate":
        return "Tuesday - Late PM";
      case "wedamearly":
        return "Wednesday - Early AM";
      case "wedamlate":
        return "Wednesday - Late AM";
      case "wedpmearly":
        return "Wednesday - Early PM";
      case "wedpmlate":
        return "Wednesday - Late PM";
      case "thuamearly":
        return "Thursday - Early AM";
      case "thuamlate":
        return "Thursday - Late AM";
      case "thupmearly":
        return "Thursday - Early PM";
      case "thupmlate":
        return "Thursday - Late PM";
      case "friamearly":
        return "Friday - Early AM";
      case "friamlate":
        return "Friday - Late AM";
      case "fripmearly":
        return "Friday - Early PM";
      case "fripmlate":
        return "Friday - Late PM";
      case "satamearly":
        return "Saturday - Late AM";
      case "satamlate":
        return "Saturday - Late AM";
      case "satpmearly":
        return "Saturday - Early PM";
      case "satpmlate":
        return "Saturday - Late PM";
      case "sunamearly":
        return "Sunday - Late AM";
      case "sunamlate":
        return "Sunday - Late AM";
      case "sunpmearly":
        return "Sunday - Early PM";
      case "sunpmlate":
        return "Sunday - Late PM";
      default:
        return "N/A";
    }
  };

  const formatPrimaryPhoneLocal = (phone) => {
    var shortPhone = phone.substring(2);

    var x = shortPhone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = shortPhone = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    return x;
  };

  const getFormattedDate = (rawDate) => {
    const formattedRawDate = new Date(rawDate + "Z");

    const updatedDate = formattedRawDate.toLocaleDateString("en-US");
    const updatedTime = formattedRawDate.toLocaleTimeString("en-US");

    return `${updatedDate} at ${updatedTime}`;
  };

  return (
    <>
      {ticket ? (
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent style={{ overflowY: "auto" }}>
            <Box height={"85vh"} position="relative">
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid className={classes.detailGrid} item>
                  <Typography color="textSecondary" gutterBottom variant="h6">
                    APPOINTMENT REQUEST DETAILS
                  </Typography>

                  <div className={classes.patientDetailContainer}>
                    <div className={classes.patientDetails}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                        className={classes.typeBold}
                        style={{ paddingBottom: "1rem" }}
                      >
                        {`${ticket?.patientfirstname ?? ""} ${
                          ticket?.lastname ?? ""
                        }`}
                      </Typography>
                      <Typography color="textSecondary" variant="h5">
                        {ticket?.dtCreated &&
                          "Request Date: " + getFormattedDate(ticket.dtCreated)}
                      </Typography>
                      <Typography color="textSecondary" variant="h5">
                        {ticket?.birthdateage &&
                        ticket?.birthdateage !== `// ()`
                          ? "DOB: " + ticket.birthdateage
                          : ""}
                      </Typography>
                      <Typography color="textSecondary" variant="h5">
                        {ticket?.ssn && "SSN: " + ticket?.ssn}
                      </Typography>
                      <Typography color="textSecondary" variant="h5">
                        {ticket?.primaryphone &&
                          `Phone: ${formatPrimaryPhoneLocal(
                            ticket?.primaryphone
                          )}`}
                        {ticket?.primaryextension &&
                          `, Ext: ${ticket?.primaryextension}`}
                      </Typography>
                      <Typography color="textSecondary" variant="h5">
                        {ticket?.emailaddress &&
                          "Email: " + ticket.emailaddress}
                      </Typography>
                      <Typography color="textSecondary" variant="body1">
                        {ticket?.streetaddress &&
                          "Address: " + ticket.streetaddress}
                      </Typography>
                      <Typography color="textSecondary" variant="body1">
                        {
                          (ticket?.city && ticket.city,
                          ticket?.state && ticket.state)
                        }{" "}
                        {ticket?.zipcode && ticket.zipcode}
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {/* <div style={{ margin: "0.5rem" }}> */}
                        {/* {ticket?.callstatus === "in-queue"
                        ? "call is in-queue"
                        : "no call is in-queue"} */}
                        {/* {ticket?.primaryphone ? (
                          <Dialer
                            number={ticket?.primaryphone}
                            token={token}
                            disabled={false}
                          />
                        ) : (
                          <Dialer
                            number={ticket?.primaryphone}
                            token={token}
                            disabled={true}
                          />
                        )} */}
                        {/* </div> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0.5rem",
                          }}
                        >
                          {ticket?.otherinfourl && (
                            <PlayVoicemail
                              otherinfourl={ticket?.otherinfourl}
                            />
                          )}
                        </div>
                        {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0.5rem",
                        }}
                      >
                        <IconButton
                          onClick={handleSms}
                          variant="outlined"
                          color="primary"
                          border={0}
                          disabled={ticket?.cantext === true ? false : true}
                        >
                          <TextsmsIcon fontSize="large" />
                        </IconButton>
                        <Typography
                          style={{ display: "inline-block" }}
                          color="textPrimary"
                          variant="body1"
                        >
                          Text Patient
                        </Typography>
                      </div> */}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      {ticket?.apptreqstate === 1 ||
                      ticket?.apptreqstate === 3 ? (
                        // <SetPending
                        //   apptRequestId={ticket?.apptrequestid}
                        //   updateCurrent={props.onClick}
                        // />
                        <SetPendingStatic
                          apptRequestId={ticket?.apptrequestid}
                          apptPartnerId={ticket?.partnerid}
                          updateCurrent={props.onClick}
                          pendingreasons={props.pendingReasons}
                        />
                      ) : (
                        ""
                      )}
                      &nbsp;
                      {ticket?.apptreqstate === 1 ||
                      ticket?.apptreqstate === 2 ? (
                        <SetArchived
                          apptRequestId={ticket?.apptrequestid}
                          apptPartnerId={ticket?.partnerid}
                          updateCurrent={props.onClick}
                          archivereasons={props.archiveReasons}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {ticket?.apptreqstate !== 1 ? (
                      <ReqStatusReason
                        status={ticket?.apptreqstate}
                        updatedOn={ticket?.apptreqstate_updated_on}
                        statusReason={ticket?.appt_req_state_reason}
                      />
                    ) : (
                      ""
                    )}
                    <div className={classes.complaintDetails}>
                      {ticket?.ctclassify && (
                        <>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Currently Treating - Question: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {` ${ticket?.ctclassify ?? ""}`}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Additional Information: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {` ${ticket?.otherinfo ?? ""}`}
                          </Typography>
                        </>
                      )}

                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                          className={classes.complaintHeading}
                        >
                          Complaint: &nbsp;
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body1"
                          style={{ display: "inline-block" }}
                        >
                          {` ${ticket?.complaintname ?? ""}`}
                        </Typography>
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                          className={classes.complaintHeading}
                        >
                          Acuity: &nbsp;
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body1"
                          style={{ display: "inline-block" }}
                        >
                          {` ${ticket?.acuityname ?? ""}`}
                        </Typography>
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                          className={classes.complaintHeading}
                        >
                          Body Part: &nbsp;
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body1"
                          style={{ display: "inline-block" }}
                        >
                          {` ${ticket?.bodypartname ?? ""}`}
                        </Typography>
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                          className={classes.complaintHeading}
                        >
                          Insurance: &nbsp;
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body1"
                          style={{ display: "inline-block" }}
                        >
                          {` ${ticket?.insurance ?? ""}`}
                        </Typography>
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                          className={classes.complaintHeading}
                        >
                          Referral Source: &nbsp;
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body1"
                          style={{ display: "inline-block" }}
                        >
                          {` ${ticket?.referralsource ?? ""}`}
                        </Typography>
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                          className={classes.complaintHeading}
                        >
                          Additional Information: &nbsp;
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body1"
                          style={{ display: "inline-block" }}
                        >
                          {` ${ticket?.otherinfo ?? ""}`}
                        </Typography>
                        {ticket?.reqsitename && (
                          <>
                            <br />
                            <Typography
                              color="textPrimary"
                              gutterBottom
                              variant="body1"
                              className={classes.complaintHeading}
                            >
                              Preferred Location: &nbsp;
                            </Typography>
                            <Typography
                              color="textSecondary"
                              gutterBottom
                              variant="body1"
                              style={{ display: "inline-block" }}
                            >
                              {ticket.reqsitename}
                            </Typography>
                          </>
                        )}
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body1"
                          className={classes.complaintHeading}
                        >
                          Recommended Service Line: &nbsp;
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body1"
                          style={{ display: "inline-block" }}
                        >
                          {` ${ticket?.servicelinename ?? ""}`}
                        </Typography>
                      </>

                      {ticket?.reqprovidername && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Preferred Provider: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.reqprovidername}
                          </Typography>
                        </>
                      )}

                      {ticket?.docs && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Service Line Providers: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            // style={{ display: "inline-block" }}
                            component="div"
                          >
                            <ul
                              style={{
                                listStyle: "none",
                                paddingTop: 0,
                                paddingBottom: 0,
                                margin: 0,
                              }}
                            >
                              {ticket?.docs.map((doc, i) => (
                                <div
                                  key={doc.providerid + "a"}
                                  style={{ padding: 0, margin: 0 }}
                                >
                                  <a href={doc.providerid}>
                                    {doc.providername}
                                  </a>
                                  <br />
                                </div>
                              ))}
                            </ul>
                          </Typography>
                        </>
                      )}

                      {ticket?.referring_physician && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Referring Physician: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.referring_physician}
                          </Typography>
                        </>
                      )}

                      {ticket?.referring_contact && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Referring Contact: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.referring_contact}
                          </Typography>
                        </>
                      )}

                      {ticket?.referring_office_phone && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Referring Office Phone: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {formatPrimaryPhoneLocal(
                              ticket.referring_office_phone
                            )}
                          </Typography>
                        </>
                      )}

                      {ticket?.gender && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Patient Gender: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.gender}
                          </Typography>
                        </>
                      )}

                      {ticket?.previous_surgery && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Previous Surgery: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.previous_surgery === true ? "Yes" : "No"}
                          </Typography>
                        </>
                      )}

                      {ticket?.previous_surgery_side && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Previous Surgery Side: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.previous_surgery_side}
                          </Typography>
                        </>
                      )}

                      {ticket?.attachments && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Attachments: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            component="div"
                          >
                            <ul
                              style={{
                                listStyle: "none",
                                paddingTop: 0,
                                paddingBottom: 0,
                                margin: 0,
                              }}
                            >
                              {ticket?.attachments.map((attachment) => (
                                <ViewAttachment
                                  key={attachment.apptattachid + "b"}
                                  apptId={ticket?.apptrequestid}
                                  fileName={attachment.apptattachfname}
                                  attachId={attachment.apptattachid}
                                />
                              ))}
                            </ul>
                          </Typography>
                        </>
                      )}

                      {Object.keys(ticket?.tods[0]).some(
                        (k) => ticket?.tods[0][k] === 0
                      ) && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Times Unavailable: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            component="div"
                          >
                            <ul
                              style={{
                                listStyle: "none",
                                paddingTop: 0,
                                paddingBottom: 0,
                                margin: 0,
                              }}
                            >
                              {ticket &&
                                Object.entries(ticket?.tods[0]).map(
                                  ([key, value]) =>
                                    value === 0 ? (
                                      <li
                                        style={{ marginBottom: 0 }}
                                        key={key + "c"}
                                      >
                                        {preferredTimeMap(key)}
                                      </li>
                                    ) : (
                                      ""
                                    )
                                )}
                            </ul>
                          </Typography>
                        </>
                      )}

                      {ticket?.workcomps && ticket?.workcomps[0]?.wc_injury && (
                        <>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Job Title: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_job_title}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Injury Date: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_injury_date}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Injury State: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_injury_state}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            How the injury occurred: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_injury_how}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Body Parts Injured at Work: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {`${
                              ticket.workcomps[0].wc_body_ankle ? `ankle, ` : ""
                            }
                            ${ticket.workcomps[0].wc_body_arm ? `arm, ` : ""}
                            ${ticket.workcomps[0].wc_body_back ? `back, ` : ""}
                            ${
                              ticket.workcomps[0].wc_body_elbow ? `elbow, ` : ""
                            }
                            ${ticket.workcomps[0].wc_body_foot ? `foot, ` : ""}
                            ${ticket.workcomps[0].wc_body_hand ? `hand, ` : ""}
                            ${ticket.workcomps[0].wc_body_head ? `head, ` : ""}
                            ${ticket.workcomps[0].wc_body_hip ? `hip, ` : ""}
                            ${ticket.workcomps[0].wc_body_knee ? `knee, ` : ""}
                            ${ticket.workcomps[0].wc_body_leg ? `leg, ` : ""}
                            ${ticket.workcomps[0].wc_body_neck ? `neck, ` : ""}
                            ${
                              ticket.workcomps[0].wc_body_shoulder
                                ? `shoulder, `
                                : ""
                            }
                            ${
                              ticket.workcomps[0].wc_body_thigh ? `thigh, ` : ""
                            }
                            ${ticket.workcomps[0].wc_body_wrist ? `wrist` : ""}
                            `}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Employer: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_employer_name}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Employer Phone: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_employer_phone}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Employer Contact: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_employer_contact}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Employer Address: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_employer_address}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Workers Comp Claim Number: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_claimno}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Insurance Provider: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_insurance}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Insurance Address: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_bill_address}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Adjuster Name: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_adjuster_name}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Adjuster Phone: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_adjuster_phone}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="body1"
                            className={classes.complaintHeading}
                          >
                            Adjuster Email: &nbsp;
                          </Typography>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body1"
                            style={{ display: "inline-block" }}
                          >
                            {ticket.workcomps[0].wc_adjuster_email}
                          </Typography>
                          <br />
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

TicketDetail.propTypes = {
  className: PropTypes.string,
};

export default TicketDetail;
