import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  MenuItem,
} from "@material-ui/core";
import validate from "validate.js";

const schema = {
  first_name: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  last_name: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  mobile_phone: {
    presence: {
      allowEmpty: false,
      message: "is required in the format 123-456-7890",
    },
    length: {
      minimum: 12,
    },
    format: {
      pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
      flags: "i",
      message: "is required in the format 123-456-7890",
    },
  },
};

const EditUserForm = (props) => {
  const [user, setUser] = useState(props.currentUser);

  const [roles] = useState([
    { id: 2, role: "Admin" },
    { id: 3, role: "User" },
  ]);

  useEffect(() => {
    setUser(props.currentUser);
  }, [props]);

  useEffect(() => {
    const errors = validate(user.values, schema);

    setUser((user) => ({
      ...user,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [user.values]);

  const handleInputChange = (event) => {
    event.persist();

    setUser((user) => ({
      ...user,
      values: {
        ...user.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...user.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setUser((user) => ({
      ...user,
      values: {
        ...user.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...user.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.updateUser(user.values.id, user);
  };

  const hasError = (field) =>
    user.touched[field] && user.errors[field] ? true : false;

  return (
    <Card>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          subheader="Make changes to user details below"
          title="Edit Users"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("first_name")}
                fullWidth
                helperText={
                  hasError("first_name") ? user.errors.first_name[0] : null
                }
                label="First Name"
                margin="dense"
                name="first_name"
                onChange={handleInputChange}
                required
                type="text"
                value={user.values.first_name || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("last_name")}
                fullWidth
                helperText={
                  hasError("last_name") ? user.errors.last_name[0] : null
                }
                label="Last Name"
                margin="dense"
                name="last_name"
                onChange={handleInputChange}
                required
                type="text"
                value={user.values.last_name || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("mobile_phone")}
                fullWidth
                helperText={
                  hasError("mobile_phone") ? user.errors.mobile_phone[0] : null
                }
                inputProps={{ maxLength: 12 }}
                label="Mobile Phone"
                margin="dense"
                name="mobile_phone"
                onChange={handlePhoneChange}
                required
                type="text"
                value={user.values.mobile_phone || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("email")}
                fullWidth
                helperText={hasError("email") ? user.errors.email[0] : null}
                label="Email"
                margin="dense"
                name="email"
                onChange={handleInputChange}
                required
                type="text"
                value={user.values.email || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Role"
                margin="dense"
                name="role"
                onChange={handleInputChange}
                required
                select
                type="text"
                value={user.values.role || "User"}
                variant="outlined"
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.role}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Agent"
                margin="dense"
                name="isAgent"
                onChange={handleInputChange}
                required
                select
                type="text"
                value={user.values.isAgent || false}
                variant="outlined"
              >
                <MenuItem selected value={false}>
                  No
                </MenuItem>
                <MenuItem value>Yes</MenuItem>
              </TextField>
            </Grid>

            {props && props.userId !== user.values.id && (
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="User Status"
                  margin="dense"
                  name="isActive"
                  onChange={handleInputChange}
                  required
                  select
                  type="text"
                  value={user.values.isActive}
                  variant="outlined"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Disabled</MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!user.isValid || props.loading}
            type="submit"
            variant="contained"
          >
            Update User
          </Button>
          <Button
            color="primary"
            onClick={() => props.setEditing(false)}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default EditUserForm;
