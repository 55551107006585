import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

export const styles = StyleSheet.create({
  // font: { fontFamily: "Oswald" },
  font: {
    fontSize: "9px",
    padding: "30px",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: "none",
    textAlign: "center",
    padding: "5px",
  },
  bold: {
    fontWeight: 900,
  },
  row1: {
    width: "9%",
    textAlign: "left",
  },
  row2: {
    width: "9%",
    textAlign: "center",
  },
  row3: {
    width: "9%",
    textAlign: "center",
  },
  row4: {
    width: "9%",
    textAlign: "center",
  },
  row5: {
    width: "9%",
    textAlign: "center",
  },
  row6: {
    width: "9%",
    textAlign: "center",
  },
  row7: {
    width: "9%",
    textAlign: "left",
  },
  row8: {
    width: "9%",
    textAlign: "left",
  },
  row9: {
    width: "9%",
    textAlign: "left",
  },
  row10: {
    width: "9%",
    textAlign: "left",
  },
  row11: {
    width: "9%",
    textAlign: "left",
  },
});

const ReportAgentDetail = ({ data, title, startDate, endDate }) => {
  const member = data[0];
  if (member) {
    return (
      <Document>
        <Page size="A4" style={styles.font}>
          <View>
            <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
              {title}
            </Text>
            <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
              Results for dates{" "}
              {startDate &&
                new Date(startDate.replace(/-/g, "/")).toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}{" "}
              -{" "}
              {endDate &&
                new Date(endDate.replace(/-/g, "/")).toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.row1}>Request ID</Text>
              <Text style={styles.row2}>Request Date</Text>
              <Text style={styles.row3}>Patient First</Text>
              <Text style={styles.row4}>Patient Last</Text>
              <Text style={styles.row5}>Update Date</Text>
              <Text style={styles.row6}>Update Time</Text>
              <Text style={styles.row7}>Status</Text>
              <Text style={styles.row8}>Action</Text>
              <Text style={styles.row9}>Employee</Text>
              <Text style={styles.row10}>Outcome</Text>
            </View>
            {data.map((item, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.row1}>
                  {item?.request_id && item.request_id}
                </Text>
                <Text style={styles.row2}>
                  {item?.request_date && item.request_date}
                </Text>
                <Text style={styles.row3}>
                  {item?.request_date_time && item.request_date_time}
                </Text>
                <Text style={styles.row4}>
                  {item?.patient_first_name && item.patient_first_name}
                </Text>
                <Text style={styles.row5}>
                  {item?.patient_last_name && item.patient_last_name}
                </Text>
                <Text style={styles.row6}>
                  {item?.request_updated_date && item.request_updated_date}
                </Text>
                <Text style={styles.row7}>
                  {item?.request_updated_time && item.request_updated_time}
                </Text>
                <Text style={styles.row8}>
                  {item?.request_status && item.request_status}
                </Text>
                <Text style={styles.row9}>{item?.action && item.action}</Text>
                <Text style={styles.row10}>
                  {item?.employee && item.employee}
                </Text>
                <Text style={styles.row11}>
                  {item?.outcome && item.outcome}
                </Text>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  } else
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>This Pdf could not be generated</Text>
          </View>
        </Page>
      </Document>
    );
};

export default ReportAgentDetail;
