import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../UserContext";
import jwt_decode from "jwt-decode";
import apiUrl from "../../../common/apiUrl";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import Loading from "./Loading";
import { HorizontalBar } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  colors,
  makeStyles,
  useTheme,
} from "@material-ui/core";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PickDateRange from "../../../common/PickDateRange";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

const RequestBreakdown = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { user } = useContext(UserContext);
  const { locationId } = jwt_decode(user.accessToken);

  const initialFormState = {
    period: {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    },
  };

  const [period, setPeriod] = useState(initialFormState);
  const [requestsByPeriodValues, setRequestsByPeriodValues] = useState();
  const [requestsByPeriodLabels, setRequestsByPeriodLabels] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // retrieve dashboard data
  useEffect(() => {
    let unmounted = false;
    setIsLoading(() => true);
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/dashboard/breakdown", {
          locationId: locationId,
          period: period.period,
        });

        if (result && !unmounted) {
          var chartObj = result.data.data;

          // calculate chart values
          let chartPartners = [
            { partnername: "Patient", partnerid: null, total: 0 },
          ];
          if (chartObj !== null) {
            chartObj.map((item) => {
              const itemIndex = chartPartners.findIndex(
                (el) => el.partnerid === item.partnerid
              );

              if (itemIndex === -1) {
                const obj = {
                  partnername: item.partnername,
                  partnerid: item.partnerid,
                  total: 1,
                };
                return chartPartners.push(obj);
              } else {
                return chartPartners[itemIndex].total++;
              }
            });
          }

          let sortedChartPartners = chartPartners.sort((a, b) =>
            a.total < b.total ? 1 : -1
          );

          setRequestsByPeriodValues(() =>
            sortedChartPartners.map((el) => el.total)
          );
          setRequestsByPeriodLabels(() =>
            sortedChartPartners.map((el) => el.partnername)
          );
          setIsLoading(() => false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    return () => {
      unmounted = true;
    };
  }, [locationId, period.period]);

  const data = {
    labels: requestsByPeriodLabels,
    datasets: [
      {
        label: "# of Requests",
        data: requestsByPeriodValues,
        backgroundColor: [
          theme.palette.ndGreen.main,
          theme.palette.primary.light,
          colors.yellow[200],
          colors.red[300],
          colors.purple[200],
          colors.orange[300],
          colors.brown[300],
          colors.pink[300],
          colors.blueGrey[300],
          colors.indigo[300],
        ],
        borderColor: [
          theme.palette.ndGreen.dark,
          theme.palette.primary.main,
          colors.yellow[600],
          colors.red[600],
          colors.purple[600],
          colors.orange[600],
          colors.brown[600],
          colors.pink[600],
          colors.blueGrey[600],
          colors.indigo[600],
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "Appointment Requests by Source",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const selectPeriod = (event) => {
    setPeriod(() => ({
      period: {
        startDate: event[0].startDate,
        endDate: event[0].endDate,
      },
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader
            action={
              <PickDateRange
                selectPeriod={(startDate, endDate) =>
                  selectPeriod(startDate, endDate)
                }
              />
            }
            title="Appointment Requests by Source"
          />
          <Divider />
          <CardContent>
            <Box height={500} position="relative">
              <HorizontalBar data={data} options={options} />
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};

RequestBreakdown.propTypes = {
  className: PropTypes.string,
};

export default RequestBreakdown;
