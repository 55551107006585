import React from "react";
import axios from "axios";
import apiUrl from "../../../common/apiUrl";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

function ConfirmationDialog(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [rotation, setRotation] = React.useState(0);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleOk = () => {
    onClose(value);
  };

  const rotateLeft = () => {
    let newRotation = rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }

    setRotation(() => rotation + 90);
  };

  const rotateRight = () => {
    let newRotation = rotation - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(() => rotation - 90);
  };

  return (
    <Dialog
      onClose={() => {}}
      disableEscapeKeyDown
      fullScreen
      aria-labelledby="dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="dialog-title">
        <span style={{ fontWeight: 900 }}>Attachment Viewer</span>
      </DialogTitle>

      <DialogContent dividers>
        <div style={{ width: "80vw" }}>
          {props.filename && props.filename.split(".").pop() === "pdf" ? (
            <iframe
              title={props.fileName}
              src={props.value}
              style={{ width: "100%", height: "100vh" }}
              frameBorder="0"
            ></iframe>
          ) : (
            <div>
              <div style={{ display: "flex" }}>
                <RotateLeftIcon
                  size="medium"
                  fontSize="large"
                  onClick={rotateLeft}
                />
                <RotateRightIcon
                  size="medium"
                  fontSize="large"
                  onClick={rotateRight}
                />
              </div>
              <img
                style={{ transform: `rotate(${rotation}deg)` }}
                src={props.value}
                width="100%"
                alt="attachment"
              />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    height: "90%",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "90%",
    maxHeight: "90%",
  },
}));

const ViewAttachment = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);

  const handleClickOpen = async (attachId, apptId, fileName) => {
    try {
      const result = await axios.post(apiUrl + "/appt/get-url", {
        apptId: apptId,
        objectId: fileName,
        attachId: attachId,
      });

      const attachmentUrl = await result.data.s3GetObjUrl;

      setValue(() => attachmentUrl);
      setOpen(true);

      return attachmentUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async (newValue) => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() =>
          handleClickOpen(props.attachId, props.apptId, props.fileName)
        }
      >
        {props.fileName}
      </Button>
      <List component="div" role="list">
        <ConfirmationDialog
          classes={{
            paper: classes.paper,
          }}
          id="archive-menu"
          filename={props.fileName}
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />
      </List>
    </>
  );
};
export default ViewAttachment;
