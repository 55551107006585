import { createContext } from "react";
import io from "socket.io-client";
import apiUrl from "./common/apiUrl";

export const socket = io(apiUrl, {
  transports: ["websocket"],
  upgrade: false,
});

export const SocketContext = createContext();
