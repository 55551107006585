import { Card, CardContent, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import PatientCondition from "./PatientCondition";
import PatientDocuments from "./PatientDocuments";
import PatientInformation from "./PatientInformation";
import PatientPreferences from "./PatientPreferences";
// import { ApptReqContext } from "../../../ApptReqContext";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Alert from "@material-ui/lab/Alert";
import { useIdleTimer } from "react-idle-timer";
import { ApptReqContext } from "../../../ApptReqContext";
import apiUrl from "../../../common/apiUrl";
import RequestBottomNavigation from "./BottomNavigation";
import Challenge from "./Challenge.js";
import ChatBottomNavigation from "./ChatBottomNavigation";
import Loading from "./Loading";
import PatientChat from "./PatientChat";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "5%",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
    },
  },
  grid: {
    spacing: 0,
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
      borderRadius: "0px",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
      marginBottom: "4rem",
    },
  },
  stepper: {
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0px 0.5rem 0px",
      margin: 0,
    },
  },
}));

function getSteps() {
  return [
    "Patient Information",
    "Patient Condition",
    "Patient Preferences",
    "Associated Documents",
  ];
}

export default function ApptReqStepper(props) {
  const {
    oQues,
    setOQues,
    wwConfig,
    setWwConfig,
    emailSentThisSession,
    setEmailSentThisSession,
    patientInfo,
  } = useContext(ApptReqContext);

  const classes = useStyles();

  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [component, setComponent] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [showChallenge, setShowChallenge] = useState(props.isExistingRequest);
  const [emailEnabled, setEmailEnabled] = useState(false);

  /** handle idle */
  var timerMs = 1000 * 60 * 60;

  const handleOnIdle = (event) => {
    setShowChallenge(() => true);
    // console.log("user is idle", event);
    // console.log("last active", getLastActiveTime());
  };

  const handleOnActive = (event) => {
    if (showChallenge === false) {
      timerMs = 1000 * 60 * 60;
      // console.log("user is active", event);
      // console.log("time remaining", getRemainingTime());
    }
  };

  const handleOnAction = (e) => {
    // console.log("user action", e);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: timerMs,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  /** end handle idle */

  const steps = getSteps();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PatientInformation
            onClick={(ky, el, vl, vi, mw) => saveOQues(ky, el, vl, vi, mw)}
            isInformationComplete={(isComplete) =>
              isInformationComplete(isComplete)
            }
          />
        );
      case 1:
        return (
          <PatientCondition
            onClick={(ky, el, vl, vi, mw) => saveOQues(ky, el, vl, vi, mw)}
            isConditionComplete={(isComplete) =>
              isConditionComplete(isComplete)
            }
            showWorkersCompQuestions={(stepId) => handleNext(stepId)}
          />
        );
      // case 2:
      //   return (
      //     <WorkersComp
      //       onClick={(ky, el, vl, vi, mw) => saveOQues(ky, el, vl, vi, mw)}
      //       isInformationComplete={(isComplete) =>
      //         isInformationComplete(isComplete)
      //       }
      //     />
      //   );
      case 2:
        return (
          <PatientPreferences
            onClick={(ky, el, vl, vi, mw) => saveOQues(ky, el, vl, vi, mw)}
          />
        );
      case 3:
        return <PatientDocuments apptId={oQues.rqid} rky={oQues.rky} />;
      default:
        return "Unknown step";
    }
  }

  /** determine if Continue button is disabled for PatientInformation */
  const isInformationComplete = (isComplete) => {
    if (isComplete && activeStep === 0) {
      setDisabled(false);
      return;
    } else {
      setDisabled(true);
    }
  };

  const sendNotification = async () => {
    if (wwConfig && emailEnabled && oQues?.rqid && !emailSentThisSession) {
      const msgConfig = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          apptReqId: oQues.rqid,
        }),
      };
      await fetch(`${apiUrl}/appt/appt-msg`, msgConfig);
      // if (wwMsgConfig) {
      /** only make one attempt per session */
      // emailSentThisSessionRef.current = true;
      setEmailSentThisSession(true);
      //}
    }
  };

  const setComplete = async () => {
    if (oQues?.rqid) {
      const setCompleteConfig = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          apptReqId: oQues.rqid,
        }),
      };

      await fetch(`${apiUrl}/appt/complete`, setCompleteConfig);
    }
  };

  const finalizeRequest = async () => {
    await sendNotification();
    await setComplete();
    handleNext();
  };

  /** determine if Continue button is disabled for PatientCondition */
  const isConditionComplete = (isComplete) => {
    if (isComplete && activeStep === 1) {
      setDisabled(false);

      /** notify scheduling team if config */
      // if (!emailSentThisSession) {
      //   sendNotification();
      // }

      return;
    } else {
      setDisabled(true);
    }
  };

  const isStepOptional = (step) => {
    switch (step) {
      // case 2:
      //   return 2;
      // case 3:
      //   return 3;
      default:
        return null;
    }
    // return step === 3;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // const isStepFailed = (step) => {
  //   return step === 0;
  // };

  const handleNext = (stepId = null) => {
    // {activeStep === steps.length - 1
    //   ? "Finish"
    //   : "Continue"}

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0) {
      setDisabled(true);
    }

    if (stepId) {
      setActiveStep((prevActiveStep) => stepId);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getFormattedDate = (rawDate) => {
    let updatedDate = null;
    if (rawDate === null || !rawDate) {
      return (updatedDate = null);
    }
    const formattedRawDate = new Date(rawDate);
    updatedDate = formattedRawDate.toLocaleDateString("en-US");

    return updatedDate;
  };

  const saveOQues = async (ky, el, vl, vi, mw) => {
    // console.log(`saveOQues: `, { ky, el, vl, vi, mw });

    const configSave = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        ky: ky,
        el: el, // field name - from oQues
        vl: el === "BirthDate" ? getFormattedDate(vl) : vl, // value - input fields, the actual last name
        vi: vi, // -1 on input fields bc for multiple choice
        mw: mw, // always a blank string
      }),
    };

    // ky: token,
    // el: "FirstName", // field name - from oQues
    // vl: "Jeff", // value - input fields, the actual last name
    // vi: -1, // -1 on input fields bc for multiple choice
    // mw: "", // always a blank string

    //   ky: oQues.rky (e.g. "SDH5E9",
    //   el: oQues.question[i].name (e.g. "bodypart"),
    //   vl: thischoiceval (e.g. "1","2","3" choice# aka order),
    //   vi: oQues.question[i].choices[j + 1].aid (e.g. 38, 86 table key id),
    //   mw: oQues.question[i].choices[j + 1].ctrlid (e.g. "spine", "shoulder" etc.)

    try {
      const response = await fetch(
        "https://ivr.nextdrvisit.com/appt/zwsavejson",
        configSave
      );

      const json = await response.json();
      const obj = await JSON.parse(json);

      setOQues(obj);
      return obj;
    } catch (error) {
      console.error(error);
    }
  };

  const displayComponent = (component) => {
    setComponent(() => component);
  };

  /** Retreive oQues or create a new one if none exists */
  const getOQues = async () => {
    setIsLoading(() => true);

    try {
      const configOQues = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          ky: props?.challengeData?.rkeyurl
            ? props.challengeData.rkeyurl
            : props.token,
          el: "FirstName", // field name - from oQues
          vl: "", // value - input fields, the actual last name === choiceid
          vi: -1, // -1 on input fields bc for multiple choice === aid
          mw: "", // always a blank string on multiple choice, ctrlname post var for input
        }),
      };

      const oQuesResponse = await fetch(
        `https://ivr.nextdrvisit.com/appt/ww?ky=${
          props?.challengeData?.rkeyurl
            ? props.challengeData.rkeyurl
            : props.token
        }`,
        configOQues
      );
      const json = await oQuesResponse.json();
      const obj1 = JSON.parse(json);
      // const obj = JSON.parse(obj1);
      const obj = obj1;
      setOQues(obj);

      /** fetch client config */

      if (props?.location) {
        const configWwConfig = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Cache: "no-cache",
          },
          body: JSON.stringify({
            locationTag: props.location,
          }),
        };

        const wwConfigResult = await (
          await fetch(`${apiUrl}/appt/wwconfig`, configWwConfig)
        ).json();

        if (wwConfigResult?.data?.[0]?.config?.wwFields) {
          setWwConfig(wwConfigResult.data[0].config.wwFields);
          setEmailEnabled(wwConfigResult.data[0].config.isEmailEnabled);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  /** get token and wwConfig if data is valid but no challenge required */
  useEffect(() => {
    if (!props.challengeData && props.token && props.location) {
      getOQues();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.challengeData, props.location, props.token]);

  const handleChallenge = async (primaryPhone, birthDate, zipCode) => {
    setIsLoading(() => true);

    try {
      const res = await fetch(apiUrl + "/appt/challenge", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          apptId: props.challengeData.apptrequestid,
          primaryPhone: primaryPhone,
          birthDate: birthDate,
          zipCode: zipCode,
          required: props.challengeData,
        }),
      });

      const data = await res.json();

      if (data && data === true) {
        getOQues();
        setIsLoading(() => false);
        setShowChallenge(() => false);
      } else {
        setIsLoading(() => false);
        setShowChallenge(() => true);
        setSeverity(() => "error");
        setMessage(() => data.message);
      }
    } catch (error) {
      setIsLoading(() => false);
      setSeverity(() => "error");
      setMessage(() => error.message);

      return error;
    }
  };

  const formatPrimaryPhone = (phone) => {
    let formattedPhone = "+1" + phone.replace(/-/g, "");
    return formattedPhone;
  };

  const saveAutoFill = () => {
    saveOQues(oQues.rky, "FirstName", patientInfo?.values?.FirstName, -1, "");

    saveOQues(
      oQues.rky,
      "PrimaryPhone",
      formatPrimaryPhone(patientInfo?.values?.PrimaryPhone),
      -1,
      ""
    );

    saveOQues(
      oQues.rky,
      "PatientFirstName",
      patientInfo?.values?.PatientFirstName,
      -1,
      ""
    );

    saveOQues(oQues.rky, "LastName", patientInfo?.values?.LastName, -1, "");

    saveOQues(oQues.rky, "ZipCode", patientInfo?.values?.ZipCode, -1, "");

    saveOQues(
      oQues.rky,
      "StreetAddress",
      patientInfo?.values?.StreetAddress,
      -1,
      ""
    );
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Loading />
      ) : showChallenge === true && props.isExistingRequest ? (
        <Challenge
          severity={severity}
          message={message}
          open={true}
          challengeData={props.challengeData}
          handleChallenge={(primaryPhone, birthDate, zipCode) =>
            handleChallenge(primaryPhone, birthDate, zipCode)
          }
        />
      ) : component === 0 ? (
        <Grid className={classes.grid} container>
          <Grid item xl={6} lg={6} sm={10} xs={12}>
            <Card className={classes.cardBg} variant="outlined">
              <CardContent className={classes.cardContent}>
                <Stepper
                  className={classes.stepper}
                  activeStep={activeStep}
                  alternativeLabel
                >
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography
                          component={"div"}
                          variant="caption"
                          align="center"
                        >
                          Optional
                        </Typography>
                      );
                    }
                    // if (isStepFailed(index)) {
                    //   labelProps.error = true;
                    // }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Typography
                        component={"div"}
                        className={classes.instructions}
                        variant="h4"
                        align="center"
                      >
                        Thank you for requesting an appointment!
                      </Typography>
                      <Typography
                        component={"div"}
                        className={classes.instructions}
                        variant="body1"
                        align="center"
                      >
                        Our scheduling team will be in touch with you as soon as
                        possible. Please use the chat link below to communicate
                        with our scheduling team.
                      </Typography>
                      <span
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          alt="Finished"
                          src="/images/finish_line.svg"
                          width="60%"
                          style={{ paddingTop: "1rem", paddingBottom: "2rem" }}
                        />
                      </span>
                      <Button
                        onClick={handleReset}
                        className={classes.button}
                        variant="contained"
                        color="primary"
                      >
                        Update Appointment Request
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Typography
                        component={"div"}
                        className={classes.instructions}
                      >
                        {getStepContent(activeStep)}
                      </Typography>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        {isStepOptional(activeStep) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSkip}
                            className={classes.button}
                          >
                            Skip
                          </Button>
                        )}

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            activeStep === steps.length - 1
                              ? finalizeRequest()
                              : handleNext();
                            activeStep === 0 && !disabled && saveAutoFill();
                          }}
                          className={classes.button}
                          disabled={disabled}
                          endIcon={<NavigateNextIcon />}
                        >
                          {activeStep === steps.length - 1
                            ? "Finish"
                            : "Continue"}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </CardContent>
              <RequestBottomNavigation
                displayComponent={(component) => displayComponent(component)}
                badgeInvisible={false}
              />
            </Card>
          </Grid>
        </Grid>
      ) : component === 1 ? (
        <Grid className={classes.grid} container>
          <Grid item xl={6} lg={6} sm={6} xs={12}>
            <Card className={classes.cardBg} variant="outlined">
              <ScopedCssBaseline>
                <CardContent className={classes.cardContent}>
                  <PatientChat canText={false} />
                </CardContent>
                <ChatBottomNavigation
                  navSelected={1}
                  displayComponent={(component) => displayComponent(component)}
                />
              </ScopedCssBaseline>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid className={classes.grid} container>
          <Grid item xl={3} lg={6} sm={6} xs={10}>
            <Card className={classes.cardBg} variant="outlined">
              <CardContent>
                <Typography
                  className={classes.title}
                  align="center"
                  variant="h1"
                >
                  Clinic
                </Typography>
                <Typography
                  className={classes.prompt}
                  align="center"
                  variant="h4"
                >
                  How to request an appointment
                </Typography>
                <Grid className={classes.grid} spacing={2} container>
                  <Grid item md={12} xs={12}>
                    Instructions
                  </Grid>

                  {message && (
                    <div className={classes.resMessage}>
                      <Alert severity={severity}>{message}</Alert>
                    </div>
                  )}
                </Grid>
              </CardContent>
              <RequestBottomNavigation
                navSelected={2}
                displayComponent={(component) => displayComponent(component)}
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
