import React, { useState, useEffect, useContext } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ApptReqContext } from "../../../ApptReqContext";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import WorkersComp from "./WorkersComp";

const schema = {
  OtherInfo: {
    length: {
      maximum: 1000,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
    width: "100%",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    margin: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(0, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  prompt: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  selectedOption: {
    color: "#FFFFFF",
  },
  summary: {
    padding: theme.spacing(2),
    width: "100%",
  },
  summaryItem: {
    padding: theme.spacing(1),
  },
}));

const PatientCondition = (props) => {
  const classes = useStyles();

  const { oQues, emailSentThisSession } = useContext(ApptReqContext);

  const [showSummary, setShowSummary] = useState(false);
  const [severity] = useState("");
  const [message] = useState("");
  const [qName, setQName] = useState("CurrentlyTreating");
  const [qPrompt, setQPrompt] = useState(
    "Are we currently treating this condition?"
  );
  const [showWorkersCompQuestions, setShowWorkersCompQuestions] =
    useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      CurrentlyTreating: "",
      BodyPart: "",
      Complaint: "",
      Acuity: "",
      ReferralSource: "",
      Insurance: "",
      wc_injury: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  });

  const [showOtherInfo, setShowOtherInfo] = useState(false);
  // oQues?.question[7]?.choices[1]?.answer ||
  const [otherInfo, setOtherInfo] = useState("");

  const handleChange = (event) => {
    setOtherInfo(() => event.target.value);
  };

  useEffect(() => {
    if (oQues?.numQues) {
      var i, formStateData;
      for (i = 0; i < oQues.numQues - 2; i++) {
        // eslint-disable-next-line
        Object.entries(oQues.question[i].choices).map(([key, choice]) => {
          if (
            oQues.question[i].answerid !== -1 &&
            oQues.question[i].answerid === choice.aid
          ) {
            formStateData = {
              ...formStateData,
              [oQues.question[i].name]: choice.prompt,
            };
          }
        });
      }

      setFormState((formState) => ({
        ...formState,
        values: formStateData,
      }));
    }
  }, [oQues]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    if (formState?.values?.CurrentlyTreating === "No") {
      if (
        formState?.values?.Acuity &&
        formState?.values?.BodyPart &&
        formState?.values?.Complaint &&
        formState?.values?.Insurance &&
        formState?.values?.ReferralSource
      ) {
        props.isConditionComplete(true);

        // if (oQues.is_workcomp && formState?.values?.wc_injury) {
        //   props.isConditionComplete(true);
        // } else if (!oQues.is_workcomp) {
        //   props.isConditionComplete(true);
        // }
      } else {
        props.isConditionComplete(false);
      }
    }

    if (formState?.values?.CurrentlyTreating === "Yes") {
      if (formState?.values?.CTClassify) {
        props.isConditionComplete(true);
      } else {
        props.isConditionComplete(false);
      }
    }
  }, [
    formState?.values?.Acuity,
    formState?.values?.BodyPart,
    formState?.values?.CTClassify,
    formState?.values?.Complaint,
    formState?.values?.CurrentlyTreating,
    formState?.values?.Insurance,
    formState?.values?.ReferralSource,
    oQues.is_workcomp,
    formState?.values?.wc_injury,
    props,
  ]);

  const nextQues = (ky, el, vl, vi, mw, nextQ, prompt, oQuesNew = null) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [el]: prompt,
      },
    }));

    /** check to see if patient condition is complete */
    // isComplete();

    var qId = 0;

    if (nextQ === "BodyPart") {
      qId = 1;
    } else if (nextQ === "Complaint") {
      qId = 2;
    } else if (nextQ === "Acuity") {
      qId = 3;
    } else if (nextQ === "ReferralSource") {
      qId = 4;
    } else if (nextQ === "Insurance") {
      qId = 5;
    } else if (nextQ === "CTClassify") {
      qId = 6;
    } else if (nextQ === "OtherInfo") {
      qId = 7;
      var promptName = oQues.question[7].choices[1].prompt;

      setShowOtherInfo(() => true);

      setQPrompt(promptName);
      setQName("");
      return;
    } else if (nextQ === "wc_injury") {
      // only show work comp questions if enabled for the client
      qId = 8;
      setShowOtherInfo(() => false);
    } else if (nextQ === "CurrentlyTreating") {
      if (showSummary === false) {
        setShowSummary(() => true);
      }
      if (showOtherInfo === true) {
        setShowOtherInfo(() => false);
      }

      setQName("");
      return;
    } else if (nextQ === "WorkersComp") {
      if (showSummary === true) {
        setShowSummary(() => false);
      }
      // if (showOtherInfo === false) {
      //   setShowOtherInfo(() => true);
      // }
      setShowOtherInfo(() => true);

      setShowWorkersCompQuestions(true);
      return;
    } else if (nextQ === "Completed") {
      setShowWorkersCompQuestions(false);
      props.showWorkersCompQuestions(); // continue to the next step
      return;
    }

    let currentOQues = oQuesNew || oQues; // this line updates complaints based on selection
    var view = Object.entries(currentOQues.question[qId].choices).map(
      ([key, choice]) => (
        <Grid key={key} item md={12} xs={12}>
          <Button
            className={classes.submitButton}
            onClick={
              oQues.question[qId].name !== "WorkersComp" &&
              (() => {
                oQuesUpdate(
                  oQues.rky,
                  oQues.question[qId].name,
                  key.toString(),
                  choice.aid,
                  choice.ctrlid,
                  choice.qnext,
                  choice.prompt
                );
              })
            }
            color="primary"
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            startIcon={
              oQues.question[qId].answerid === choice.aid ? (
                <CheckCircleOutlineIcon className={classes.selectedOption} />
              ) : (
                ""
              )
            }
          >
            {choice.prompt}
          </Button>
        </Grid>
      )
    );

    let activeOQues = oQuesNew || oQues;

    promptName = activeOQues.question[qId].prompt;

    setQPrompt(promptName);
    setQName(view);
  };

  const oQuesUpdate = async (ky, el, vl, vi, mw, nextQ, prompt) => {
    var oQuesNew = await props.onClick(ky, el, vl, vi, mw);

    nextQues(ky, el, vl, vi, mw, nextQ, prompt, oQuesNew);
  };

  return (
    <div className={classes.contentBody}>
      <Card className={classes.cardBg} variant="outlined">
        <CardContent>
          {showWorkersCompQuestions && (
            <WorkersComp
              isConditionComplete={(isComplete) =>
                props.isConditionComplete(isComplete)
              }
              saveWorkCompData={(ky, el, vl, vi, mw) =>
                props.onClick(ky, el, vl, vi, mw)
              }
            />
          )}
          {!showWorkersCompQuestions && (
            <>
              <Typography className={classes.title} align="center" variant="h1">
                {oQues && oQues.location}
              </Typography>
              <Grid className={classes.grid} spacing={2} container>
                {message && (
                  <div className={classes.resMessage}>
                    <Alert severity={severity}>{message}</Alert>
                  </div>
                )}
                {oQues && showSummary === false && (
                  <Typography className={classes.prompt} variant="h4">
                    {qPrompt}
                  </Typography>
                )}
                {oQues && showOtherInfo === true && showSummary === false && (
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows="4"
                      label=""
                      name="OtherInfo"
                      onChange={handleChange}
                      type="text"
                      value={otherInfo || ""}
                      variant="outlined"
                      InputProps={{
                        className: classes.input,
                      }}
                      onBlur={() => {
                        props.onClick(
                          oQues.rky,
                          "OtherInfo",
                          otherInfo,
                          -1,
                          ""
                        );
                      }}
                    />
                    <br /> <br />
                    {oQues.is_workcomp && (
                      <Button
                        className={classes.submitButton}
                        onClick={() => {
                          nextQues(
                            oQues.rky,
                            oQues.question[8].name,
                            "1",
                            -1,
                            "",
                            "wc_injury",
                            ""
                          );
                        }}
                        color="primary"
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                      >
                        Click Here To Continue
                      </Button>
                    )}
                    {!oQues.is_workcomp && (
                      <Button
                        className={classes.submitButton}
                        onClick={() => {
                          nextQues(
                            oQues.rky,
                            oQues.question[7].name,
                            "1",
                            -1,
                            "",
                            "CurrentlyTreating",
                            ""
                          );
                        }}
                        color="primary"
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                      >
                        View Request Summary
                      </Button>
                    )}
                  </Grid>
                )}
                {oQues && showSummary === false && qName === "CurrentlyTreating"
                  ? Object.entries(oQues.question[0].choices).map(
                      ([key, choice]) => (
                        <Grid key={key} item md={12} xs={12} lg={12} xl={12}>
                          <Button
                            className={classes.submitButton}
                            onClick={() => {
                              nextQues(
                                oQues.rky,
                                oQues.question[0].name,
                                key.toString(),
                                choice.aid,
                                choice.ctrlid,
                                choice.qnext,
                                choice.prompt
                              );
                              props.onClick(
                                oQues.rky,
                                oQues.question[0].name,
                                key.toString(),
                                choice.aid,
                                choice.ctrlid,
                                choice.qnext
                              );
                            }}
                            color="primary"
                            type="submit"
                            fullWidth
                            size="large"
                            variant="contained"
                            startIcon={
                              oQues.question[0].answerid === choice.aid ? (
                                <CheckCircleOutlineIcon
                                  className={classes.selectedOption}
                                />
                              ) : (
                                ""
                              )
                            }
                          >
                            {choice.prompt}
                          </Button>
                        </Grid>
                      )
                    )
                  : ""}
                {qName && qName !== "CurrentlyTreating" ? qName : ""}
                {showSummary === true && (
                  <Typography
                    className={classes.prompt}
                    variant="h4"
                    align="center"
                  >
                    Please review your responses below. Click the "Back" button
                    below to make changes.
                  </Typography>
                )}
                {showSummary === true ? (
                  <Paper className={classes.summary}>
                    {showSummary === true && oQues.question[0].answerid === 1
                      ? Object.entries(formState.values).map(([key, value]) => (
                          <Grid key={key} item md={12} xs={12}>
                            <Box display="flex" className={classes.summaryItem}>
                              <Typography
                                className={classes.prompt}
                                variant="h5"
                                align="left"
                                style={{ margin: 0, padding: 0 }}
                              >
                                {key !== "ZipCode" &&
                                key !== "CurrentlyTreating" &&
                                key !== "BodyPart" &&
                                key !== "Complaint" &&
                                key !== "Acuity" &&
                                key !== "ReferralSource" &&
                                key !== "Insurance" &&
                                key !== "OtherInfo" &&
                                key !== "wc_injury" ? (
                                  key === "CTClassify" ? (
                                    <p style={{ margin: 0 }}>
                                      <strong>Reason for appointment: </strong>{" "}
                                      {value}
                                    </p>
                                  ) : (
                                    <p style={{ margin: 0 }}>
                                      <strong>{key}: </strong> {value}
                                    </p>
                                  )
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        ))
                      : Object.entries(formState.values).map(([key, value]) => (
                          <Grid key={key} item md={12} xs={12}>
                            <Box display="flex" className={classes.summaryItem}>
                              <Typography
                                className={classes.prompt}
                                variant="h5"
                                align="left"
                                style={{ margin: 0, padding: 0 }}
                              >
                                {key !== "ZipCode" &&
                                key !== "OtherInfo" &&
                                key !== "CTClassify" ? (
                                  <p style={{ margin: 0 }}>
                                    <strong>{key}: </strong> {value}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                    {showSummary === true && (
                      <Grid item md={12} xs={12}>
                        <Box display="flex" className={classes.summaryItem}>
                          <Typography
                            className={classes.prompt}
                            variant="h5"
                            align="left"
                            style={{ margin: 0, padding: 0 }}
                          >
                            <strong>{`Additional Information: `}</strong>
                            {`${otherInfo}`}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Paper>
                ) : (
                  ""
                )}
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

PatientCondition.propTypes = {
  className: PropTypes.string,
};

export default PatientCondition;
