import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import Loading from "./components/Loading";
import { ApptReqContext } from "../../ApptReqContext";
import ApptReqStepper from "./components/ApptReqStepper.js";
import apiUrl from "../../common/apiUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    spacing: 0,
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(4, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  linkExpired: {
    paddingTop: theme.spacing(4),
  },
}));

const AppointmentRequest = () => {
  const classes = useStyles();
  let history = useHistory();

  const { location, token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const { oQues, setOQues, setWwConfig } = useContext(ApptReqContext);

  let locationIsValid = useRef(true);
  let tokenIsValid = useRef(true);
  let existingRequest = useRef(null);
  let challengeData = useRef(null);

  /** Retreive oQues if none exists */
  const getNewOQues = async () => {
    setIsLoading(true);

    try {
      const configOQuesCy = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          cy: location,
          el: "FirstName",
          vl: "",
          vi: -1,
          mw: "",
        }),
      };

      const responseCy = await fetch(
        `https://ivr.nextdrvisit.com/appt/ww?cy=${location}`,
        configOQuesCy
      );

      const jsonCy = await responseCy.json();
      const objCy = JSON.parse(jsonCy);
      //const tmpObj = JSON.parse(objCy); // parse twice to convert from string
      const tmpObj = objCy;

      setOQues(tmpObj);

      if (
        tmpObj?.rky !== "" &&
        tmpObj?.rky !== undefined &&
        tokenIsValid.current &&
        location
      ) {
        history.push({
          pathname: `/request-appointment/${location}/${tmpObj.rky}`,
        });
      }

      /** fetch client config */
      const configWwConfig = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          locationTag: location,
        }),
      };

      const wwConfigResult = await (
        await fetch(`${apiUrl}/appt/wwconfig`, configWwConfig)
      ).json();

      if (wwConfigResult?.data[0]?.config?.wwFields) {
        // console.log(wwConfigResult.data[0].config.wwFields);
        setWwConfig(wwConfigResult.data[0].config.wwFields);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  /** check challenge status */
  useEffect(() => {
    const testAlphaNumeric = new RegExp(/^[a-z0-9]+$/i);
    const isAlphaLocation = testAlphaNumeric.test(location);
    const locationLength = location.length;

    if (!location || !isAlphaLocation || locationLength >= 10) {
      locationIsValid.current = false;
    }

    if (token) {
      const isAlphaToken = testAlphaNumeric.test(token);
      const tokenLength = token.length;

      if (!isAlphaToken || tokenLength !== 6) {
        tokenIsValid.current = false;
      }
    }

    if (locationIsValid.current && tokenIsValid.current && token) {
      setIsLoading(() => true);

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        body: JSON.stringify({
          rky: token,
        }),
      };

      const fetchData = async () => {
        try {
          /** check to see if challenge is required */
          const response = await fetch(
            `${apiUrl}/appt/require-challenge`,
            config
          );

          const json = await response.json();

          existingRequest.current =
            json?.data[0]?.mustchallenge && json.data[0].mustchallenge;

          challengeData.current = json?.data[0] && json.data[0];
          if (existingRequest.current === true) {
            challengeData.current = json?.data[0] && json.data[0];
          }

          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      getNewOQues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : !locationIsValid.current ||
        !tokenIsValid.current ||
        oQues?.rky === "" ? (
        <div className={classes.root}>
          <Grid className={classes.grid} container>
            <Grid item lg={12} xs={10}>
              <div className={classes.contentHeader} />
              <div className={classes.contentBody}>
                <Card className={classes.cardBg} variant="outlined">
                  <CardContent>
                    <div className={classes.logo}>
                      <img alt="Logo" src="/images/logo.png" width="250px" />
                    </div>
                    <Typography
                      component={"div"}
                      className={classes.linkExpired}
                      variant="h5"
                    >
                      This link is invalid. Please check the URL and try again.
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <ApptReqStepper
          isExistingRequest={existingRequest.current}
          challengeData={challengeData.current}
          location={location}
          token={token}
        />
      )}
    </>
  );
};

AppointmentRequest.propTypes = {
  className: PropTypes.string,
};

export default AppointmentRequest;
