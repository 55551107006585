import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import validate from "validate.js";
import PropTypes from "prop-types";
import apiUrl from "../../common/apiUrl.js";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Button,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    spacing: 0,
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(4, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  subTitle: {
    paddingLeft: "2px",
    paddingRight: "2px",
    color: "rgba(0, 0, 0, 0.65)",
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();

  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      email: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormState((formState) => ({
      ...formState,
      isLoading: true,
    }));

    try {
      const response = await fetch(apiUrl + "/auth/forgot-password", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Cache: "no-cache",
        },
        credentials: "include",
        body: JSON.stringify({
          email: formState.values.email,
        }),
      });

      const data = await response.json();

      if (data) {
        setFormState((formState) => ({
          ...formState,
          isLoading: false,
        }));

        setSeverity(data.severity);
        setMessage(data.message);
      }
    } catch (e) {
      setFormState((formState) => ({
        ...formState,
        isLoading: false,
      }));

      setSeverity("error");
      setMessage(
        "There was a problem sending your reset email.  Please try again."
      );
      return e;
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid item xl={3} lg={6} sm={6} xs={10}>
          <Card className={classes.cardBg} variant="outlined">
            <CardContent>
              <div className={classes.logo}>
                <img alt="Logo" src="/images/logo.png" width="250px" />
              </div>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Typography className={classes.title} variant="h2">
                  Forgot Password
                </Typography>
                <Typography className={classes.subTitle} variant="body1">
                  Enter the email address you use to login to NextDoc below to
                  have a password reset link emailed to you.
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError("email")}
                  fullWidth
                  helperText={
                    hasError("email") ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  required
                  value={formState.values.email || ""}
                  variant="outlined"
                  inputProps={{ maxLength: 64 }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
                {message && (
                  <div className={classes.resMessage}>
                    <Alert severity={severity}>{message}</Alert>
                  </div>
                )}
                <Button
                  className={classes.submitButton}
                  color="primary"
                  disabled={!formState.isValid || severity === "success"}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Send Reset Email
                </Button>
                <Typography color="textSecondary" variant="body1">
                  <Link to="/sign-in" variant="h6">
                    Return to sign in
                  </Link>
                </Typography>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

ForgotPassword.propTypes = {
  className: PropTypes.string,
};

export default ForgotPassword;
