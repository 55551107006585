import React, { useState, useEffect, useContext, useRef } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  TextField,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";
import { ApptReqContext } from "../../../ApptReqContext";
import DateRangeIcon from "@material-ui/icons/DateRange";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    margin: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(4, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  linkExpired: {
    paddingTop: theme.spacing(4),
  },
  prompt: {
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const WorkersComp = (props) => {
  const classes = useStyles();

  const { oQues, wwConfig, setPatientInfo } = useContext(ApptReqContext);

  const schema = {
    wc_injury_date: {
      presence: { allowEmpty: false, message: "is required" },
    },
    wc_injury_state: {
      presence: { allowEmpty: false, message: "is required" },
    },
    wc_employer_name: {
      presence: {
        allowEmpty: false,
        message: "is required",
      },
      length: {
        minimum: 2,
      },
    },
    wc_employer_contact: {
      presence: {
        allowEmpty: false,
        message: "is required",
      },
      length: {
        minimum: 2,
      },
    },
    wc_employer_address: {
      presence: {
        allowEmpty: false,
        message: "is required",
      },
      length: {
        minimum: 2,
      },
    },
    wc_employer_phone: {
      presence: {
        allowEmpty: false,
        message: "is required in the format 123-456-7890",
      },
      length: {
        minimum: 12,
      },
      format: {
        pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
        flags: "i",
        message: "is required in the format 123-456-7890",
      },
    },
  };

  const focusedElement = useRef(null);

  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      wc_injury_date: null,
      wc_injury_state: "",
      wc_body_foot: false,
      wc_body_ankle: false,
      wc_body_leg: false,
      wc_body_knee: false,
      wc_body_thigh: false,
      wc_body_hip: false,
      wc_body_back: false,
      wc_body_shoulder: false,
      wc_body_arm: false,
      wc_body_elbow: false,
      wc_body_wrist: false,
      wc_body_hand: false,
      wc_body_neck: false,
      wc_body_head: false,
      wc_employer_name: "",
      wc_employer_contact: "",
      wc_employer_phone: "",
      wc_employer_address: "",
      wc_job_title: "",
      wc_employer_five_more: "0",
      wc_injury_how: "",
      wc_insurance: "",
      wc_claimno: "",
      wc_adjuster_name: "",
      wc_adjuster_phone: "",
      wc_adjuster_email: "",
      wc_bill_address: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  });

  const formatPrimaryPhone = (phone) => {
    let formattedPhone = "+1" + phone.replace(/-/g, "");
    return formattedPhone;
  };

  const formatPrimaryPhoneLocal = (phone) => {
    var shortPhone = phone.substring(2);

    var x = shortPhone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = shortPhone = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    return x;
  };

  useEffect(() => {
    if (typeof oQues !== "undefined") {
      setFormState((formState) => ({
        ...formState,
        values: {
          wc_injury_date: !focusedElement.current
            ? oQues?.question?.[10]?.choices[1]?.answer
            : formState.values.wc_injury_date,
          wc_injury_state:
            !focusedElement.current &&
            oQues?.question?.[10]?.choices[2]?.answer !== ""
              ? oQues?.question?.[10]?.choices[2]?.answer
              : formState.values.wc_injury_state,
          wc_body_foot: formState.touched.hasOwnProperty("wc_body_foot")
            ? formState.values.wc_body_foot
            : oQues?.question?.[10]?.choices[3]?.answer === "0"
            ? false
            : true,
          wc_body_ankle: formState.touched.hasOwnProperty("wc_body_ankle")
            ? formState.values.wc_body_ankle
            : oQues?.question?.[10]?.choices[4]?.answer === "0"
            ? false
            : true,
          wc_body_leg: formState.touched.hasOwnProperty("wc_body_leg")
            ? formState.values.wc_body_leg
            : oQues?.question?.[10]?.choices[5]?.answer === "0"
            ? false
            : true,
          wc_body_knee: formState.touched.hasOwnProperty("wc_body_knee")
            ? formState.values.wc_body_knee
            : oQues?.question?.[10]?.choices[6]?.answer === "0"
            ? false
            : true,
          wc_body_thigh: formState.touched.hasOwnProperty("wc_body_thigh")
            ? formState.values.wc_body_thigh
            : oQues?.question?.[10]?.choices[7]?.answer === "0"
            ? false
            : true,
          wc_body_hip: formState.touched.hasOwnProperty("wc_body_hip")
            ? formState.values.wc_body_hip
            : oQues?.question?.[10]?.choices[8]?.answer === "0"
            ? false
            : true,
          wc_body_back: formState.touched.hasOwnProperty("wc_body_back")
            ? formState.values.wc_body_back
            : oQues?.question?.[10]?.choices[9]?.answer === "0"
            ? false
            : true,
          wc_body_shoulder: formState.touched.hasOwnProperty("wc_body_shoulder")
            ? formState.values.wc_body_shoulder
            : oQues?.question?.[10]?.choices[10]?.answer === "0"
            ? false
            : true,
          wc_body_arm: formState.touched.hasOwnProperty("wc_body_arm")
            ? formState.values.wc_body_arm
            : oQues?.question?.[10]?.choices[11]?.answer === "0"
            ? false
            : true,
          wc_body_elbow: formState.touched.hasOwnProperty("wc_body_elbow")
            ? formState.values.wc_body_elbow
            : oQues?.question?.[10]?.choices[12]?.answer === "0"
            ? false
            : true,
          wc_body_wrist: formState.touched.hasOwnProperty("wc_body_wrist")
            ? formState.values.wc_body_wrist
            : oQues?.question?.[10]?.choices[13]?.answer === "0"
            ? false
            : true,
          wc_body_hand: formState.touched.hasOwnProperty("wc_body_hand")
            ? formState.values.wc_body_hand
            : oQues?.question?.[10]?.choices[14]?.answer === "0"
            ? false
            : true,
          wc_body_neck: formState.touched.hasOwnProperty("wc_body_neck")
            ? formState.values.wc_body_neck
            : oQues?.question?.[10]?.choices[15]?.answer === "0"
            ? false
            : true,
          wc_body_head: formState.touched.hasOwnProperty("wc_body_head")
            ? formState.values.wc_body_head
            : oQues?.question?.[10]?.choices[16]?.answer === "0"
            ? false
            : true,
          wc_employer_name: !focusedElement.current
            ? oQues?.question?.[10]?.choices[17]?.answer
            : formState.values.wc_employer_name,
          wc_employer_contact: !focusedElement.current
            ? oQues?.question?.[10]?.choices[18]?.answer
            : formState.values.wc_employer_contact,
          wc_employer_phone:
            !focusedElement.current &&
            oQues?.question?.[10]?.choices[18]?.answer
              ? formatPrimaryPhoneLocal(oQues.question?.[10].choices[19].answer)
              : formState.values.wc_employer_phone || "",
          wc_employer_address: !focusedElement.current
            ? oQues?.question?.[10]?.choices[20]?.answer
            : formState.values.wc_employer_address,
          wc_job_title: !focusedElement.current
            ? oQues?.question?.[10]?.choices[21]?.answer
            : formState.values.wc_job_title,
          wc_employer_five_more: !focusedElement.current
            ? oQues?.question?.[10]?.choices[22]?.answer
            : formState.values.wc_employer_five_more,
          wc_injury_how: !focusedElement.current
            ? oQues?.question?.[10]?.choices[23]?.answer
            : formState.values.wc_injury_how,
          wc_insurance: !focusedElement.current
            ? oQues?.question?.[10]?.choices[24]?.answer
            : formState.values.wc_insurance,
          wc_claimno: !focusedElement.current
            ? oQues?.question?.[10]?.choices[25]?.answer
            : formState.values.wc_claimno,
          wc_adjuster_name: !focusedElement.current
            ? oQues?.question?.[10]?.choices[26]?.answer
            : formState.values.wc_adjuster_name,
          wc_adjuster_phone:
            !focusedElement.current &&
            oQues?.question?.[10]?.choices[27]?.answer
              ? formatPrimaryPhoneLocal(oQues.question?.[10].choices[27].answer)
              : formState.values.wc_adjuster_phone || "",
          wc_adjuster_email: !focusedElement.current
            ? oQues?.question?.[10]?.choices[28]?.answer
            : formState.values.wc_adjuster_email,
          wc_bill_address: !focusedElement.current
            ? oQues?.question?.[10]?.choices?.[29]?.answer
            : formState.values.wc_bill_address,
          CanText:
            !focusedElement.current &&
            oQues?.question?.[10]?.choices[9]?.answer === "0"
              ? false
              : true,
        },
      }));
      // setPatientInfo((formState) => ({
      //   ...formState,
      //   values: {
      //     FirstName: !focusedElement.current
      //       ? oQues?.question?.[10]?.choices[1]?.answer
      //       : formState.values.FirstName,
      //     PatientFirstName: !focusedElement.current
      //       ? oQues?.question?.[10]?.choices[2]?.answer
      //       : formState.values.PatientFirstName,
      //     LastName: !focusedElement.current
      //       ? oQues?.question?.[10]?.choices[3]?.answer
      //       : formState.values.LastName,
      //     StreetAddress: !focusedElement.current
      //       ? oQues?.question?.[10]?.choices[4]?.answer
      //       : formState.values.StreetAddress,
      //     ZipCode: !focusedElement.current
      //       ? oQues?.question?.[10]?.choices[5]?.answer
      //       : formState.values.ZipCode,
      //     PrimaryPhone:
      //       !focusedElement.current && oQues?.question?.[10]?.choices[7]?.answer
      //         ? formatPrimaryPhoneLocal(oQues.question?.[10].choices[7].answer)
      //         : formState.values.PrimaryPhone || "",
      //     EmailAddress: !focusedElement.current
      //       ? oQues?.question?.[10]?.choices[10]?.answer
      //       : formState.values.EmailAddress,
      //     SSN: !focusedElement.current
      //       ? oQues?.question?.[10]?.choices[12]?.answer
      //       : formState.values.SSN,
      //   },
      // }));
    }
  }, [oQues]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    /** determine if Continue button is disabled */
    if (errors) {
      props.isConditionComplete(false);
    } else {
      props.isConditionComplete(true);
    }

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values, props]);

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    setPatientInfo((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    setPatientInfo((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
    }));
  };

  const handleDateChange = (date) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        wc_injury_date: date,
      },
      touched: {
        ...formState.touched,
        wc_injury_date: true,
      },
    }));
    props.saveWorkCompData(oQues.rky, "wc_injury_date", date, -1, "");
  };

  const handleDateBlur = (date) => {
    // props.saveWorkCompData(oQues.rky, "BirthDate", date, -1, "");

    // props.saveWorkCompData(oQues.rky, "BirthDate", formState.values.BirthDate, -1, "");
    // setFormState((formState) => ({
    //   ...formState,
    //   values: {
    //     ...formState.values,
    //     BirthDate: date.target.value,
    //   },
    // }));
    props.saveWorkCompData(
      oQues.rky,
      "wc_injury_date",
      formState.values.wc_injury_date,
      -1,
      ""
    );
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    focusedElement.current = document.activeElement.name;
  });

  return (
    <div className={classes.contentBody}>
      <Card className={classes.cardBg} variant="outlined">
        <form>
          <CardContent>
            <Typography className={classes.title} align="center" variant="h1">
              {oQues && oQues.location}
            </Typography>
            <Typography className={classes.prompt} align="center" variant="h4">
              Workers Compensation Details
            </Typography>
            <Grid className={classes.grid} spacing={2} container>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                  item
                  md={wwConfig && wwConfig[5].isVisible ? 6 : 12}
                  xs={12}
                >
                  <DatePicker
                    fullWidth
                    error={hasError("wc_injury_date")}
                    helperText={
                      hasError("wc_injury_date")
                        ? formState.errors.wc_injury_date[0]
                        : null
                    }
                    disableToolbar
                    clearable
                    required={wwConfig[4].isRequired}
                    variant="dialog"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="wc_injury_date"
                    label="Injury Date"
                    disableFuture={true}
                    minDate="01/01/1900"
                    openTo="year"
                    value={formState.values.wc_injury_date}
                    // InputLabelProps={{ shrink: true }}
                    InputProps={{
                      className: classes.input,
                      endAdornment: (
                        <InputAdornment position="end">
                          <DateRangeIcon />
                        </InputAdornment>
                      ),
                    }}
                    // onChange={() => undefined}
                    onChange={handleDateChange}
                    // onAccept={handleDateChangeAccepted}
                    onBlur={handleDateBlur}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError("wc_job_title")}
                  fullWidth
                  helperText={
                    hasError("wc_job_title")
                      ? formState.errors.wc_job_title[0]
                      : null
                  }
                  label="Job Title"
                  name="wc_job_title"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.wc_job_title || ""}
                  variant="outlined"
                  inputProps={{ maxLength: 25 }}
                  InputProps={{
                    className: classes.input,
                  }}
                  onBlur={() =>
                    props.saveWorkCompData(
                      oQues.rky,
                      "wc_job_title",
                      formState.values.wc_job_title,
                      -1,
                      ""
                    )
                  }
                />
              </Grid>

              <Grid item md={9} xs={12}>
                <TextField
                  error={hasError("wc_bill_address")}
                  fullWidth
                  helperText={
                    hasError("wc_bill_address")
                      ? formState.errors.wc_bill_address[0]
                      : null
                  }
                  label="Insurance Provider billing Address, City, State, Zip"
                  name="wc_bill_address"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.wc_bill_address || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onBlur={() => {
                    props.saveWorkCompData(
                      oQues.rky,
                      "wc_bill_address",
                      formState.values.wc_bill_address,
                      -1,
                      ""
                    );
                  }}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="Injury State"
                  name="wc_injury_state"
                  onChange={(e) => {
                    handleChange(e);
                    props.saveWorkCompData(
                      oQues.rky,
                      "wc_injury_state",
                      e.target.value,
                      -1,
                      ""
                    );
                  }}
                  required
                  select
                  type="text"
                  value={formState.values.wc_injury_state}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Please Select</em>
                  </MenuItem>
                  <MenuItem value="AL">Alabama</MenuItem>
                  <MenuItem value="AK">Alaska</MenuItem>
                  <MenuItem value="AZ">Arizona</MenuItem>
                  <MenuItem value="AR">Arkansas</MenuItem>
                  <MenuItem value="CA">California</MenuItem>
                  <MenuItem value="CO">Colorado</MenuItem>
                  <MenuItem value="CT">Connecticut</MenuItem>
                  <MenuItem value="DE">Delaware</MenuItem>
                  <MenuItem value="DC">District Of Columbia</MenuItem>
                  <MenuItem value="FL">Florida</MenuItem>
                  <MenuItem value="GA">Georgia</MenuItem>
                  <MenuItem value="HI">Hawaii</MenuItem>
                  <MenuItem value="ID">Idaho</MenuItem>
                  <MenuItem value="IL">Illinois</MenuItem>
                  <MenuItem value="IN">Indiana</MenuItem>
                  <MenuItem value="IA">Iowa</MenuItem>
                  <MenuItem value="KS">Kansas</MenuItem>
                  <MenuItem value="KY">Kentucky</MenuItem>
                  <MenuItem value="LA">Louisiana</MenuItem>
                  <MenuItem value="ME">Maine</MenuItem>
                  <MenuItem value="MD">Maryland</MenuItem>
                  <MenuItem value="MA">Massachusetts</MenuItem>
                  <MenuItem value="MI">Michigan</MenuItem>
                  <MenuItem value="MN">Minnesota</MenuItem>
                  <MenuItem value="MS">Mississippi</MenuItem>
                  <MenuItem value="MO">Missouri</MenuItem>
                  <MenuItem value="MT">Montana</MenuItem>
                  <MenuItem value="NE">Nebraska</MenuItem>
                  <MenuItem value="NV">Nevada</MenuItem>
                  <MenuItem value="NH">New Hampshire</MenuItem>
                  <MenuItem value="NJ">New Jersey</MenuItem>
                  <MenuItem value="NM">New Mexico</MenuItem>
                  <MenuItem value="NY">New York</MenuItem>
                  <MenuItem value="NC">North Carolina</MenuItem>
                  <MenuItem value="ND">North Dakota</MenuItem>
                  <MenuItem value="OH">Ohio</MenuItem>
                  <MenuItem value="OK">Oklahoma</MenuItem>
                  <MenuItem value="OR">Oregon</MenuItem>
                  <MenuItem value="PA">Pennsylvania</MenuItem>
                  <MenuItem value="RI">Rhode Island</MenuItem>
                  <MenuItem value="SC">South Carolina</MenuItem>
                  <MenuItem value="SD">South Dakota</MenuItem>
                  <MenuItem value="TN">Tennessee</MenuItem>
                  <MenuItem value="TX">Texas</MenuItem>
                  <MenuItem value="UT">Utah</MenuItem>
                  <MenuItem value="VT">Vermont</MenuItem>
                  <MenuItem value="VA">Virginia</MenuItem>
                  <MenuItem value="WA">Washington</MenuItem>
                  <MenuItem value="WV">West Virginia</MenuItem>
                  <MenuItem value="WI">Wisconsin</MenuItem>
                  <MenuItem value="WY">Wyoming</MenuItem>
                </TextField>
              </Grid>

              {formState.values.wc_injury_state === "MS" && (
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Does the company have five or more employees?"
                    name="wc_employer_five_more"
                    onChange={(e) => {
                      handleChange(e);
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_employer_five_more",
                        e.target.value,
                        -1,
                        ""
                      );
                    }}
                    select
                    type="text"
                    value={formState.values.wc_employer_five_more}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                  >
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </TextField>
                </Grid>
              )}

              <Typography
                className={classes.prompt}
                align="center"
                variant="h4"
                saveWorkCompData
              >
                Select All Body Parts That Were Injured
              </Typography>

              <Grid className={classes.grid} spacing={2} container>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_foot}
                        name="wc_body_foot"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_foot",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Foot"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_ankle}
                        name="wc_body_ankle"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_ankle",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Ankle"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_leg}
                        name="wc_body_leg"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_leg",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Leg"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_knee}
                        name="wc_body_knee"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_knee",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Knee"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_thigh}
                        name="wc_body_thigh"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_thigh",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Thigh"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_hip}
                        name="wc_body_hip"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_hip",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Hip"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_back}
                        name="wc_body_back"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_back",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Back"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_shoulder}
                        name="wc_body_shoulder"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_shoulder",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Shoulder"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_arm}
                        name="wc_body_arm"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_arm",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Arm"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_elbow}
                        name="wc_body_elbow"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_elbow",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Elbow"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_wrist}
                        name="wc_body_wrist"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_wrist",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Wrist"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_hand}
                        name="wc_body_hand"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_hand",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Hand"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_neck}
                        name="wc_body_neck"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_neck",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Neck"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.wc_body_head}
                        name="wc_body_head"
                        color="primary"
                        onChange={(e) => {
                          props.saveWorkCompData(
                            oQues.rky,
                            "wc_body_head",
                            e.target.checked === false ? "0" : "1",
                            -1,
                            ""
                          );
                          handleChange(e);
                        }}
                      />
                    }
                    label="Head"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>

              <Typography
                className={classes.prompt}
                align="center"
                variant="h4"
                saveWorkCompData
              >
                Employer Information
              </Typography>

              <Grid className={classes.grid} spacing={2} container>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={hasError("wc_employer_name")}
                    required
                    fullWidth
                    helperText={
                      hasError("wc_employer_name")
                        ? formState.errors.wc_employer_name[0]
                        : null
                    }
                    label="Employer Name"
                    name="wc_employer_name"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.wc_employer_name || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_employer_name",
                        formState.values.wc_employer_name,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={hasError("wc_employer_contact")}
                    required
                    fullWidth
                    helperText={
                      hasError("wc_employer_contact")
                        ? formState.errors.wc_employer_contact[0]
                        : null
                    }
                    label="Employer Contact"
                    name="wc_employer_contact"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.wc_employer_contact || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_employer_contact",
                        formState.values.wc_employer_contact,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={hasError("wc_employer_phone")}
                    fullWidth
                    required
                    helperText={
                      hasError("wc_employer_phone")
                        ? formState.errors.wc_employer_phone[0]
                        : null
                    }
                    label="Employer Phone"
                    name="wc_employer_phone"
                    onChange={handlePhoneChange}
                    type="text"
                    value={formState.values.wc_employer_phone || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_employer_phone",
                        formatPrimaryPhone(formState.values.wc_employer_phone),
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={hasError("wc_employer_address")}
                    fullWidth
                    required={wwConfig[6].isRequired}
                    helperText={
                      hasError("wc_employer_address")
                        ? formState.errors.wc_employer_address[0]
                        : null
                    }
                    label="Employer Address, City, State, Zip"
                    name="wc_employer_address"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.wc_employer_address || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() => {
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_employer_address",
                        formState.values.wc_employer_address,
                        -1,
                        ""
                      );
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    error={hasError("wc_injury_how")}
                    required={wwConfig[11].isRequired}
                    fullWidth
                    helperText={
                      hasError("wc_injury_how")
                        ? formState.errors.wc_injury_how[0]
                        : null
                    }
                    label="How Did Your Injury Occur?"
                    name="wc_injury_how"
                    onChange={handleChange}
                    type="text"
                    multiline
                    rows={4}
                    value={formState.values.wc_injury_how || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_injury_how",
                        formState.values.wc_injury_how,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>
              </Grid>

              <Typography
                className={classes.prompt}
                align="center"
                variant="h4"
                saveWorkCompData
              >
                Insurance Information
              </Typography>

              <Grid className={classes.grid} spacing={2} container>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={hasError("wc_insurance")}
                    required={wwConfig[9].isRequired}
                    fullWidth
                    helperText={
                      hasError("wc_insurance")
                        ? formState.errors.wc_insurance[0]
                        : null
                    }
                    label="Insurance Company"
                    name="wc_insurance"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.wc_insurance || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_insurance",
                        formState.values.wc_insurance,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={hasError("wc_claimno")}
                    required={wwConfig[10].isRequired}
                    fullWidth
                    helperText={
                      hasError("wc_claimno")
                        ? formState.errors.wc_claimno[0]
                        : null
                    }
                    label="Insurance Claim Number"
                    name="wc_claimno"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.wc_claimno || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_claimno",
                        formState.values.wc_claimno,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={hasError("wc_adjuster_name")}
                    required={wwConfig[11].isRequired}
                    fullWidth
                    helperText={
                      hasError("wc_adjuster_name")
                        ? formState.errors.wc_adjuster_name[0]
                        : null
                    }
                    label="Insurance Adjuster Name"
                    name="wc_adjuster_name"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.wc_adjuster_name || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_adjuster_name",
                        formState.values.wc_adjuster_name,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    error={hasError("wc_adjuster_phone")}
                    fullWidth
                    helperText={
                      hasError("wc_adjuster_phone")
                        ? formState.errors.wc_adjuster_phone[0]
                        : null
                    }
                    label="Insurance Adjuster Phone"
                    name="wc_adjuster_phone"
                    onChange={handlePhoneChange}
                    type="text"
                    value={formState.values.wc_adjuster_phone || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_adjuster_phone",
                        formatPrimaryPhone(formState.values.wc_adjuster_phone),
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    error={hasError("wc_adjuster_email")}
                    required={wwConfig[8].isRequired}
                    fullWidth
                    helperText={
                      hasError("wc_adjuster_email")
                        ? formState.errors.wc_adjuster_email[0]
                        : null
                    }
                    label="Insurance Adjuster Email Address"
                    name="wc_adjuster_email"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.wc_adjuster_email || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.saveWorkCompData(
                        oQues.rky,
                        "wc_adjuster_email",
                        formState.values.wc_adjuster_email,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>

                {message && (
                  <div className={classes.resMessage}>
                    <Alert severity={severity}>{message}</Alert>
                  </div>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

WorkersComp.propTypes = {
  className: PropTypes.string,
};

export default WorkersComp;
