import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

export const styles = StyleSheet.create({
  // font: { fontFamily: "Oswald" },
  font: {
    fontSize: "9px",
    padding: "30px",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: "none",
    textAlign: "center",
    padding: "5px",
  },
  bold: {
    fontWeight: 900,
  },
  row1: {
    width: "7%",
  },
  row2: {
    width: "12%",
    textAlign: "center",
  },
  row3: {
    width: "12%",
    textAlign: "left",
  },
  row4: {
    width: "10%",
    textAlign: "center",
  },
  row5: {
    width: "10%",
    textAlign: "center",
  },
  row6: {
    width: "10%",
    textAlign: "center",
  },
  row7: {
    width: "10%",
    textAlign: "center",
  },
  row8: {
    width: "10%",
    textAlign: "center",
  },
  row9: {
    width: "8%",
    textAlign: "center",
  },
  row10: {
    width: "11%",
    textAlign: "center",
  },
});

const ReportApptDetail = ({ data, title, startDate, endDate }) => {
  const member = data[0];
  if (member) {
    return (
      <Document>
        <Page size="A4" style={styles.font}>
          <View>
            <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
              {title}
            </Text>
            <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
              Results for dates{" "}
              {startDate &&
                new Date(startDate.replace(/-/g, "/")).toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}{" "}
              -{" "}
              {endDate &&
                new Date(endDate.replace(/-/g, "/")).toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.row1}>ID</Text>
              <Text style={styles.row2}>Last Name</Text>
              <Text style={styles.row3}>First Name</Text>
              <Text style={styles.row4}>Status</Text>
              <Text style={styles.row5}>Status Reason</Text>
              <Text style={styles.row6}>Request Date</Text>
              <Text style={styles.row7}>First Update</Text>
              <Text style={styles.row8}>Scheduled</Text>
              <Text style={styles.row9}>Referring MD</Text>
              <Text style={styles.row10}>Partner</Text>
            </View>
            {data.map((item, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.row1}>
                  {item?.apptrequestid && item.apptrequestid}
                </Text>
                <Text style={styles.row2}>
                  {item?.lastname && item.lastname}
                </Text>
                <Text style={styles.row3}>
                  {item?.patientfirstname && item.patientfirstname}
                </Text>
                <Text style={styles.row4}>
                  {item?.apptreqstatename && item.apptreqstatename}
                </Text>
                <Text style={styles.row5}>
                  {item?.appt_req_state_reason && item.appt_req_state_reason}
                </Text>
                <Text style={styles.row6}>
                  {item?.date_submitted &&
                    new Date(item.date_submitted).toLocaleString("en-us", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                </Text>
                <Text style={styles.row7}>
                  {item?.appt_reqstate_first_update &&
                    new Date(item.appt_reqstate_first_update).toLocaleString(
                      "en-us",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      }
                    )}
                </Text>
                <Text style={styles.row8}>
                  {item?.date_appt_scheduled &&
                    new Date(item.date_appt_scheduled).toLocaleString("en-us", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                </Text>
                <Text style={styles.row9}>
                  {item?.referring_md && item.referring_md}
                </Text>
                <Text style={styles.row10}>
                  {item?.partnername && item.partnername}
                </Text>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  } else
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>This Pdf could not be generated</Text>
          </View>
        </Page>
      </Document>
    );
};

export default ReportApptDetail;
