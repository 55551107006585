import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../../common/apiUrl";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  List,
  MenuItem,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { UserContext } from "../../../UserContext";
import jwt_decode from "jwt-decode";

// const options = [
//   "Appointment Scheduled",
//   "Referred Out",
//   "No Appointment Scheduled - Insufficient Information",
//   "No Appointment Scheduled - Refused Appointment",
//   "No Appointment Scheduled - Provider Denied Appointment",
//   "No Appointment Scheduled - Referred to Walk-in clinic",
//   "No Appointment Scheduled - Patient chose another practice",
// ];

function ConfirmationDialog(props) {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { locationId } = jwt_decode(user.accessToken);

  const { onClose, value: valueProp, open, providerId, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [apptDate, setApptDate] = useState(null);
  const radioGroupRef = React.useRef(null);
  const [providers, setProviders] = useState([
    { providerid: 0, providername: "No Providers Found" },
  ]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { provider: "" },
    touched: {},
    errors: {},
    isLoading: false,
  });

  React.useEffect(() => {
    let unmounted = false;
    if (!open && !unmounted) {
      setValue(valueProp);
    }

    return () => {
      unmounted = true;
    };
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    setApptDate(() => null);
    onClose();
  };

  const handleOk = () => {
    let selectedProvider = providers.find(
      (item) => item.providerid === formState.values.provider
    );

    let providerText = formState.values.provider
      ? " with " +
        selectedProvider.providername +
        " id-" +
        formState.values.provider
      : " ";
    onClose(
      value === "1|Appointment Scheduled"
        ? value + " on " + apptDate + providerText
        : value
    );
    setApptDate(() => null);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // const handleDateChange = (event) => {
  //   setApptDate(event.target.value);
  // };

  const handleDateChange = (date) => {
    setApptDate(() => date);
  };

  const handleProviderChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    let unmounted = false;

    if (locationId) {
      const getProviders = async () => {
        // setIsLoading(true);

        try {
          const result = await axios.post(apiUrl + "/appt/providers", {
            locationId: locationId,
          });

          if (result && !unmounted) {
            setProviders(() => result.data.data);
          }

          // setIsLoading(false);
        } catch (error) {
          console.error(error);
          // setIsLoading(false);
        }
      };

      getProviders();
    }

    return () => {
      unmounted = true;
    };
  }, [locationId]);

  return (
    <Dialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <span style={{ fontWeight: 900 }}>Archive Reason</span>
      </DialogTitle>

      <DialogContent dividers>
        {value && value === "1|Appointment Scheduled" && (
          <>
            <Grid item md={12} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  fullWidth
                  inputVariant="outlined"
                  ampm={true}
                  id="apptDate"
                  label="Enter Appointment Date"
                  value={apptDate || null}
                  onChange={handleDateChange}
                  onError={console.log}
                  disablePast
                  format="MM/dd/yyyy h:mm a"
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                style={{ marginTop: "15px" }}
                label="Select Provider"
                name="provider"
                onChange={handleProviderChange}
                required
                select
                type="text"
                value={formState.values.provider}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  className: classes.input,
                }}
              >
                {providers ? (
                  providers.map((provider) => (
                    <MenuItem
                      key={provider.providerid + "a"}
                      value={provider.providerid}
                    >
                      {provider.providername}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={"a"} value={null}>
                    {"Please Select"}
                  </MenuItem>
                )}
              </TextField>
            </Grid>
          </>
        )}
        <RadioGroup
          ref={radioGroupRef}
          aria-label="archive"
          name="selectedValue"
          value={value}
          onChange={handleChange}
        >
          {props?.archivereasons &&
            props.archivereasons.map((reason) => (
              <FormControlLabel
                value={`${reason.archivereasonid.toString()}|${
                  reason.archive_reason
                }`}
                key={reason.archivereasonid}
                control={<Radio />}
                label={reason.archive_reason}
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          color="secondary"
          variant="text"
        >
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
  input: {
    background: "white",
  },
}));

const SetArchived = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const { user } = useContext(UserContext);
  const { userId } = jwt_decode(user.accessToken);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (newValue) => {
    setOpen(false);

    if (newValue) {
      const apptId = props.apptRequestId;

      /** get reasonid from start of newValue */
      let reasonId = newValue.substring(0, newValue.indexOf("|"));

      /** remove reasonid from newValue */
      newValue = newValue.substring(newValue.indexOf("|") + 1);

      let apptDate;
      let providerId = newValue.split("id-")[1];

      if (providerId) {
        newValue = newValue.split("id-")[0];
        apptDate = newValue.slice(25).split(" w")[0];
      }

      try {
        await axios.put(apiUrl + "/appt/status", {
          apptReqId: apptId,
          requestStatus: 3,
          requestStatusReason: newValue,
          partnerId: props.apptPartnerId,
          apptDate: apptDate,
          providerId: providerId,
          reasonId: reasonId,
        });

        await axios.post(apiUrl + "/chat", {
          apptRequestId: apptId,
          userId: userId ?? null,
          message: `Marked as Archived by ${user.username} - ${newValue}`,
          isPatientMsg: false,
        });
      } catch (error) {
        console.error(error);
      }

      props.updateCurrent(); // update request detail

      //setValue(newValue);
    }
  };

  return (
    <>
      <Button
        style={{ marginTop: "10px", width: "100%" }}
        color="primary"
        endIcon={<ArchiveIcon />}
        size="large"
        variant="contained"
        onClick={handleClickOpen}
      >
        Archive
      </Button>
      <List component="div" role="list">
        <ConfirmationDialog
          classes={{
            paper: classes.paper,
          }}
          id="archive-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          archivereasons={props.archivereasons}
        />
      </List>
    </>
  );
};
export default SetArchived;
