import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { UserContext } from "../../UserContext";
import apiUrl from "../../common/apiUrl.js";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#4770b7",
    height: "100%",
    width: "100%",
  },
  grid: {
    spacing: 0,
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(7),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(4, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const SignIn = () => {
  const classes = useStyles();

  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const { setUser } = useContext(UserContext);
  const handleSignIn = async (event) => {
    event.preventDefault();
    const email = formState.values.email;
    const password = formState.values.password;

    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      credentials: "include",
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    };

    try {
      const response = await fetch(apiUrl + "/auth/login", config);
      const data = await response.json();

      if (data) {
        setUser({
          username: data.username,
          isAuthenticated: data.isAuthenticated,
          customerStatus: data.customerStatus,
          accessToken: data.accessToken,
          tokenExpiresOn: data.tokenExpiresOn,
        });

        if (data.severity !== "success") {
          setSeverity(data.severity);
          setMessage(data.message);
        }
      }
    } catch (error) {
      setSeverity("error");
      setMessage(error.message);
      return error;
    }
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid item xl={3} lg={6} sm={6} xs={10}>
          <Card className={classes.cardBg} variant="outlined">
            <CardContent>
              <div className={classes.logo}>
                <img alt="Logo" src="/images/logo.png" width="250px" />
              </div>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError("email")}
                  fullWidth
                  helperText={
                    hasError("email") ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
                <TextField
                  className={classes.textField}
                  error={hasError("password")}
                  fullWidth
                  helperText={
                    hasError("password") ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
                {message && (
                  <div className={classes.resMessage}>
                    <Alert severity={severity}>{message}</Alert>
                  </div>
                )}
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign in!
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Forgot password?{" "}
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    variant="h6"
                  >
                    Reset Password
                  </Link>
                </Typography>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SignIn);
