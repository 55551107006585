import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useParams, useHistory } from "react-router-dom";
import { TextField, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";

// const schema = {
//   primaryPhone: {
//     presence: {
//       allowEmpty: false,
//       message: "is required in the format 123-456-7890",
//     },
//     length: {
//       minimum: 12,
//     },
//     format: {
//       pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
//       flags: "i",
//       message: "is required in the format 123-456-7890",
//     },
//   },
//   zipCode: {
//     presence: { allowEmpty: false, message: "is required" },
//     length: {
//       minimum: 5,
//       maximum: 5,
//     },
//     format: {
//       pattern: "[0-9]+",
//       flags: "i",
//       message: "can only contain 0-9",
//     },
//   },
// };

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
}));

const Challenge = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { location } = useParams();

  const requiredFields = props.challengeData;

  const message = props.message;
  const severity = props.severity;

  const [open, setOpen] = useState(true);
  // const [disabled, setDisabled] = useState(true);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      primaryPhone: "",
      birthDate: "01/01/1900",
      zipCode: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  });

  // useEffect(() => {
  //   const errors = validate(formState.values, schema);

  //   if (errors) {
  //     setDisabled(true);
  //   } else {
  //     setDisabled(false);
  //   }

  //   setFormState((formState) => ({
  //     ...formState,
  //     isValid: errors ? false : true,
  //     errors: errors || {},
  //   }));
  // }, [formState.values, props]);

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleDateChange = (date) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        birthDate: date,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleClose = () => {
    setOpen(false);
    history.replace(`/request-appointment/${location}`);
    history.go(0);
  };

  const getFormattedDate = (rawDate) => {
    const formattedRawDate = new Date(rawDate);
    const updatedDate = formattedRawDate.toLocaleDateString("en-US");

    return updatedDate;
  };

  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="form-dialog-title"
        onClose={() => {}}
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">
          <span style={{ fontSize: "1.5rem", fontWeight: 700 }}>
            Resume Request
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details below associated with your appointment
            request to continue, or start a new request by clicking "New
            Request".
          </DialogContentText>
          {message && (
            <div className={classes.resMessage}>
              <Alert severity={severity}>{message}</Alert>
            </div>
          )}
          <Grid className={classes.grid} spacing={2} container>
            {requiredFields && requiredFields.mustchallenge && (
              <Grid item xs={10}>
                <TextField
                  // error={hasError("primaryPhone")}
                  fullWidth
                  required
                  helperText={
                    hasError("primaryPhone")
                      ? formState.errors.primaryPhone[0]
                      : null
                  }
                  label="Primary Phone"
                  name="primaryPhone"
                  onChange={handlePhoneChange}
                  type="text"
                  value={formState.values.primaryPhone || ""}
                  variant="outlined"
                  inputProps={{ maxLength: 12 }}
                />
              </Grid>
            )}

            {requiredFields && requiredFields.challengebirthday && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={10}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="dialog"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="birthDate"
                    openTo="year"
                    label="Patient Date of Birth"
                    maxDate={Date.now()}
                    minDate="01/01/1900"
                    value={formState.values.birthDate || ""}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            )}

            {requiredFields && requiredFields.challengezipcode && (
              <Grid item xs={10}>
                <TextField
                  // error={hasError("zipCode")}
                  required
                  fullWidth
                  helperText={
                    hasError("zipCode") ? formState.errors.zipCode[0] : null
                  }
                  label="Patient Zip Code"
                  name="zipCode"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.zipCode || ""}
                  variant="outlined"
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ marginTop: "1.5rem" }}>
          <Button
            onClick={handleClose}
            color="inherit"
            size="large"
            variant="text"
          >
            New Request
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            disabled={
              formState.values.primaryPhone === "" &&
              formState.values.zipCode === ""
            }
            onClick={() => {
              props.handleChallenge(
                formState.values.primaryPhone,
                getFormattedDate(formState.values.birthDate),
                formState.values.zipCode
              );
              setOpen(() => false);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Challenge;
