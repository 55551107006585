import React, { useContext } from "react";
import { UserContext } from "../../../UserContext";
import jwt_decode from "jwt-decode";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@material-ui/core";
import AdbIcon from "@material-ui/icons/Adb";
import HealingIcon from "@material-ui/icons/Healing";
import "./ChatMessage.css";
import ReactEmoji from "react-emoji";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  messageArea: {
    overflowY: "auto",
    background: theme.palette.background.default,
  },
  messageInput: {
    marginTop: "40px",
  },
  systemMessage: {
    border: "0.5px dashed gray",
    borderRadius: "15px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    float: "right",
    background: "white",
  },
  systemMessageNote: {
    marginTop: 0,
    padding: "2px",
    marginRight: "70px",
    display: "inline-block",
    float: "right",
  },
  systemMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "right",
  },
  outboundMessage: {
    borderRadius: "15px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    float: "right",
    background: theme.palette.primary.main,
  },
  outboundMessageNote: {
    marginTop: 0,
    padding: "2px",
    marginRight: "70px",
    display: "inline-block",
    float: "right",
  },
  outboundMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "right",
  },
  inboundMessage: {
    borderRadius: "15px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    background: theme.palette.secondary.main,
  },
  inboundPatientMessage: {
    borderRadius: "15px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    background: theme.palette.primary.light,
  },
  inboundMessageNote: {
    marginTop: 0,
    padding: "2px",
    marginLeft: "70px",
    display: "inline-block",
  },
  inboundMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "left",
  },
  cardActions: {
    marginTop: 0,
    paddingTop: 0,
    marginRight: "20px",
    marginLeft: "20px",
    marginBottom: "20px",
  },
  cardHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Message = ({
  message: { text, user, msgDate, from, msgUserId, room, isRead, isNote },
  name,
  firstName,
}) => {
  const contextUser = useContext(UserContext);
  const { userId } = jwt_decode(contextUser.user.accessToken);
  // console.log({ text, user, msgDate, from, msgUserId, room, isRead, isNote });

  const classes = useStyles();
  let isSentByAgent = false;
  let isSentBySystem = false;

  // console.log(text, user, msgDate, from, room, isRead, isNote);

  switch (from) {
    case "agent":
      isSentByAgent = true;
      break;
    case "System":
      isSentBySystem = true;
      break;
    default:
      break;
  }

  const getFormattedDate = (rawDate) => {
    const formattedRawDate = new Date(rawDate);
    const updatedDate = formattedRawDate.toLocaleDateString("en-US");
    const updatedTime = formattedRawDate.toLocaleTimeString("en-US");

    return `${updatedTime} on ${updatedDate}`;
  };

  return isSentByAgent ? (
    <Grid container>
      <Grid item xs={12}>
        <ListItemIcon
          className={
            msgUserId === userId && !isNote
              ? classes.systemMessageAvatar
              : classes.inboundMessageAvatar
          }
        >
          <Avatar>{firstName?.charAt(0)}</Avatar>
        </ListItemIcon>
        <ListItemText
          disableTypography
          style={{ marginRight: "70px" }}
          primary={
            <Typography
              className={
                msgUserId === userId && !isNote
                  ? classes.outboundMessage
                  : classes.inboundMessage
              }
              type="body1"
              style={{ color: "#FFFFFF" }}
            >
              {ReactEmoji.emojify(text)}
            </Typography>
          }
          align={msgUserId === userId ? "right" : "left"}
        ></ListItemText>
      </Grid>
      <Grid item xs={12}>
        <ListItemText
          align="right"
          secondary={`${firstName} - ${getFormattedDate(msgDate)}`}
          className={
            msgUserId === userId && !isNote
              ? classes.outboundMessageNote
              : classes.inboundMessageNote
          }
        ></ListItemText>
      </Grid>
    </Grid>
  ) : isSentBySystem ? (
    <Grid container>
      <Grid item xs={12}>
        <ListItemIcon className={classes.systemMessageAvatar}>
          <Avatar>
            <AdbIcon />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          align="right"
          primary={ReactEmoji.emojify(text)}
          className={classes.systemMessage}
        ></ListItemText>
      </Grid>
      <Grid item xs={12}>
        <ListItemText
          align="right"
          secondary={`${
            isNote ? "Note" : firstName ?? "System"
          } - ${getFormattedDate(msgDate)}`}
          className={classes.systemMessageNote}
        ></ListItemText>
      </Grid>
    </Grid>
  ) : (
    <Grid display="flex" container>
      <Grid item xs={12}>
        <ListItemIcon className={classes.inboundMessageAvatar}>
          <Avatar>
            <HealingIcon />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          disableTypography
          align="left"
          type="body1"
          primary={
            <Typography
              className={classes.inboundMessage}
              type="body1"
              style={{ color: "#FFFFFF" }}
            >
              {ReactEmoji.emojify(text)}
            </Typography>
          }
          style={{ marginLeft: "70px" }}
        ></ListItemText>
      </Grid>
      <Grid item xs={12}>
        <ListItemText
          align="left"
          secondary={`Patient - ${getFormattedDate(msgDate)}`}
          className={classes.inboundMessageNote}
        ></ListItemText>
      </Grid>
    </Grid>
  );
};

export default Message;
