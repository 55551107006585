import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RouteWithLayout, AuthenticatedRoute } from "./components";
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Login as LoginLayout,
  ApptRequest as ApptRequestLayout,
} from "./layouts";

import {
  Dashboard as DashboardView,
  Agent as AgentView,
  NotFound as NotFoundView,
  SignIn as SignInView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  Settings as SettingsView,
  Support as SupportView,
  AppointmentRequest as AppointmentRequestView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <AuthenticatedRoute
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <AuthenticatedRoute
        component={AgentView}
        exact
        layout={MainLayout}
        path="/agent/:appointmentId?"
      />
      <AuthenticatedRoute
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <AuthenticatedRoute
        component={SupportView}
        exact
        layout={MainLayout}
        path="/support"
      />
      <RouteWithLayout
        component={AppointmentRequestView}
        layout={ApptRequestLayout}
        path="/request-appointment/:location/:token?"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={LoginLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ForgotPasswordView}
        exact
        layout={LoginLayout}
        path="/forgot-password"
      />
      <RouteWithLayout
        component={ResetPasswordView}
        exact
        layout={LoginLayout}
        path="/reset-password/:token"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
