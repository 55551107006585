import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  makeStyles,
  FormControlLabel,
  Typography,
  Checkbox,
} from "@material-ui/core";
import validate from "validate.js";
import Select from "react-select";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const schema = {
  firstName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
  },
  lastName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
  },
  sites: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const useStyles = makeStyles((theme) => ({
  cardHeaderTitle: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 600,
  },
  cardSubHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const EditProviderForm = (props) => {
  const classes = useStyles();

  const initialFormState = {
    isValid: false,
    values: {
      providerId: null,
      firstName: "",
      lastName: "",
      hideInWidget: false,
      siteIds: null,
      sites: null,
      siteOptions: null,
      serviceLineIds: null,
      serviceLines: null,
    },
    touched: {},
    errors: {},
    isLoading: false,
  };

  const [provider, setProvider] = useState(props.currentProvider);
  const [sites, setSites] = useState(null);
  const [serviceLines, setServiceLines] = useState(null);

  useEffect(() => {
    const tmpCurrentProvider = props.currentProvider;
    if (tmpCurrentProvider?.values?.lastName) {
      tmpCurrentProvider.values.firstName =
        tmpCurrentProvider.values.firstName.replace(
          ` ${tmpCurrentProvider.values.lastName}`,
          ""
        );
    }
    setProvider(tmpCurrentProvider);
  }, [props]);

  useEffect(() => {
    const errors = validate(provider.values, schema);

    setProvider((user) => ({
      ...user,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [provider.values]);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const resultSites = await axios.post(
          apiUrl + "/settings/provider/sites",
          {
            locationId: props.locationId,
          }
        );

        const resultServiceLines = await axios.post(
          apiUrl + "/settings/provider/servicelines"
        );

        if (
          !unmounted &&
          resultServiceLines?.data?.data &&
          resultSites?.data?.data
        ) {
          const allSiteOptions = resultSites.data.data.map((site) => {
            return {
              label: site.sitename,
              value: site.siteid,
            };
          });

          const currentSelectedSites = allSiteOptions.filter((site) =>
            props.currentProvider.values.siteIds.includes(site.value)
          );

          const allServiceLineOptions = resultServiceLines.data.data.map(
            (svcLine) => {
              return {
                label: `${svcLine.servicelinename} (${svcLine.servicebodyparts})`,
                value: svcLine.servicelineid,
              };
            }
          );

          const currentServiceLines = allServiceLineOptions.filter((svcLine) =>
            props.currentProvider.values.serviceLineIds.includes(svcLine.value)
          );

          setSites(allSiteOptions);
          setServiceLines(allServiceLineOptions);

          if (currentSelectedSites) {
            setProvider((provider) => ({
              ...provider,
              values: {
                ...provider.values,
                sites: currentSelectedSites,
              },
            }));
          }

          if (currentServiceLines) {
            setProvider((provider) => ({
              ...provider,
              values: {
                ...provider.values,
                serviceLines: currentServiceLines,
              },
            }));
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();

    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, [
    props.locationId,
    props.currentProvider.values.siteIds,
    props.currentProvider.values.serviceLineIds,
  ]);

  const handleInputChange = (event) => {
    setProvider((user) => ({
      ...user,
      values: {
        ...provider.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...provider.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSiteChange = (arr) => {
    setProvider((provider) => ({
      ...provider,
      values: {
        ...provider.values,
        sites: arr,
      },
      touched: {
        ...provider.touched,
        sites: true,
      },
    }));
  };

  const handleServiceLineChange = (arr) => {
    setProvider((provider) => ({
      ...provider,
      values: {
        ...provider.values,
        serviceLines: arr,
      },
      touched: {
        ...provider.touched,
        serviceLines: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.updateProvider(provider.values.providerId, provider);
    setProvider(initialFormState);
  };

  const hasError = (field) =>
    provider.touched[field] && provider.errors[field] ? true : false;

  const selectSiteStyles = {
    control: (base) => ({
      ...base,
      fontFamily: "Source Sans Pro",
      borderColor:
        provider.errors?.sites && provider.touched?.sites
          ? "#e53935"
          : "#cccccc",
      "&:hover": {
        border: "1px solid #000000",
      },
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "Source Sans Pro",
      zIndex: 9999,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const selectServiceLineStyles = {
    control: (base) => ({
      ...base,
      fontFamily: "Source Sans Pro",
      borderColor: "#cccccc",
      "&:hover": {
        border: "1px solid #000000",
      },
    }),
    menu: (base) => ({
      ...base,
      fontFamily: "Source Sans Pro",
      zIndex: 9999,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Card>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          subheader="Update provider details below"
          title="Edit Provider"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("firstName")}
                fullWidth
                helperText={
                  hasError("firstName") ? provider.errors.firstName[0] : null
                }
                label="First Name and Prefix"
                margin="dense"
                name="firstName"
                onChange={handleInputChange}
                required
                type="text"
                value={provider.values.firstName || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("lastName")}
                fullWidth
                helperText={
                  hasError("lastName") ? provider.errors.lastName[0] : null
                }
                label="Last Name and Suffix"
                margin="dense"
                name="lastName"
                onChange={handleInputChange}
                required
                type="text"
                value={provider.values.lastName || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                placeholder={<div>Select Provider Locations*</div>}
                isMulti
                name="sites"
                options={sites}
                value={provider.values.sites}
                classNamePrefix="select"
                onChange={(value) => handleSiteChange(value || [])}
                menuPosition="fixed"
                styles={selectSiteStyles}
              />
              <Typography
                style={{
                  fontSize: "0.688rem",
                  color: "#e53935",
                  marginLeft: "14px",
                  marginTop: "4px",
                  letterSpacing: "0.33px",
                  lineHeight: "1.66",
                  fontWeight: "400",
                }}
              >
                {hasError("sites") ? provider.errors.sites[0] : null}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                placeholder={<div>Select Service Lines</div>}
                isMulti
                name="serviceLinesSelect"
                options={serviceLines}
                value={provider.values.serviceLines}
                classNamePrefix="select"
                onChange={(value) => handleServiceLineChange(value || [])}
                menuPosition="fixed"
                styles={selectServiceLineStyles}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={provider.values.hideInWidget}
                    onChange={handleInputChange}
                    name="hideInWidget"
                    color="primary"
                  />
                }
                label="Hide this provider from patients during appointment requests?"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!provider.isValid}
            type="submit"
            variant="contained"
          >
            Update Provider
          </Button>
          <Button
            color="primary"
            onClick={() => props.setEditing(false)}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default EditProviderForm;
