import React, { useState, useEffect, useContext, Fragment } from "react";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
import AddReferralForm from "./AddReferralForm";
import EditUserForm from "./EditReferralForm";
import ReferralTable from "./ReferralTable";
import { makeStyles } from "@material-ui/styles";
import { Snackbar, AppBar, Toolbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const useStyles = makeStyles(() => ({
  userTable: {
    paddingTop: "2rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ReferralManager = () => {
  const classes = useStyles();

  const initialFormState = {
    partnerId: null,
    partnerName: "",
    partnerAbbr: "",
    city: "",
    stateAbbr: "",
    partnerUrl: "",
  };

  const [referralPartners, setReferralPartners] = useState();
  const [partnerid, setPartnerid] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [showAddReferralPartner, setShowAddReferralPartner] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user } = useContext(UserContext);
  const { userId, locationId, customerId, organizationId, partnerId, role } =
    jwt_decode(user.accessToken);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/referral/list", {
          customerId: customerId,
        });

        setReferralPartners(() => result.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, [customerId, refresh]);

  const addReferralPartner = async (nUser) => {
    //e.preventDefault();

    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/referral", {
        partnerName: nUser.partnerName,
        partnerAbbr: nUser.partnerAbbr,
        partnerUrl: nUser.partnerUrl,
        city: nUser.city,
        stateAbbr: nUser.stateAbbr,
        createdBy: userId,
        userCustomerId: customerId,
        locationId: locationId,
        firstName: nUser.first_name,
        lastName: nUser.last_name,
        mobilePhone: nUser.mobile_phone,
        email: nUser.email,
      });

      // TODO: get updated list of referral partners
      // if (result.data) {
      //   setReferralPartners([...referralPartners, result.data]);
      // }

      if (result.data) {
        setReferralPartners(() => result.data);
      }

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const resendInvite = async (partnerUserId, email, firstName) => {
    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/referral/resend", {
        email: email,
        firstName: firstName,
        partnerUserId: partnerUserId,
        updatedBy: userId,
      });

      if (result.data) {
        setRefresh(!refresh);
      }

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
    }
  };

  const updateReferralPartner = async (id, uUser) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.put(apiUrl + "/referral", {
        partnerId: uUser.values.partnerId,
        partnerName: uUser.values.partnerName,
        partnerAbbr: uUser.values.partnerAbbr,
        partnerUrl: uUser.values.partnerUrl,
        city: uUser.values.city,
        stateAbbr: uUser.values.stateAbbr,
        updatedBy: userId,
      });

      if (result.data) {
        setReferralPartners(
          referralPartners.map((referralPartner) =>
            referralPartner.partnerid === partnerid
              ? uUser.values
              : referralPartner
          )
        );
      }
      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const deleteReferralPartner = async (id) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/referral/disable", {
        partnerId: id,
      });

      if (result.data) {
        setReferralPartners(
          referralPartners.filter(
            (referralPartner) => referralPartner.partnerid !== id
          )
        );
      }

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const editRow = (user) => {
    setEditing(true);

    setCurrentUser({
      isValid: true,
      values: {
        partnerId: user.partnerid,
        partnerName: user.partnername,
        partnerAbbr: user.partnerabbr,
        city: user.city,
        stateAbbr: user.stateabbr,
        partnerUrl: user.url,
        createdBy: user.is_active,
      },
      touched: {},
      errors: {},
      isLoading: false,
    });
  };

  const handleShowAddReferralPartner = () => {
    setShowAddReferralPartner(
      (showAddReferralPartner) => !showAddReferralPartner
    );
  };

  return (
    <>
      {role && role <= 2 && (
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Button
              onClick={handleShowAddReferralPartner}
              color="primary"
              variant="outlined"
            >
              Create Referral Partner
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <div className="container">
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openSnack}
        >
          <Alert onClose={handleClose} severity="success">
            Referral partner updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openErrorSnack}
        >
          <Alert onClose={handleClose} severity="error">
            Referral partner update failed. Please try again.
          </Alert>
        </Snackbar>
        <div className="flex-row">
          <div className="flex-large">
            {editing ? (
              <Fragment>
                <EditUserForm
                  currentUser={currentUser}
                  editing={editing}
                  setEditing={setEditing}
                  updateReferralPartner={updateReferralPartner}
                />
              </Fragment>
            ) : (
              <Fragment>
                {showAddReferralPartner && (
                  <AddReferralForm
                    showAddReferralPartner={() =>
                      handleShowAddReferralPartner()
                    }
                    addReferralPartner={addReferralPartner}
                    loading={isLoading}
                  />
                )}
              </Fragment>
            )}
          </div>
          <div className={classes.userTable}>
            {!editing && (
              <ReferralTable
                currentUserId={userId}
                deleteReferralPartner={deleteReferralPartner}
                editRow={editRow}
                referralPartners={referralPartners}
                role={role}
                resendInvite={resendInvite}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralManager;
