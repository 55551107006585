import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../../common/apiUrl";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import PendingIcon from "@material-ui/icons/HourglassFull";
import { UserContext } from "../../../UserContext";
import jwt_decode from "jwt-decode";

// const options = [
//   "Appointment Scheduled",
//   "Referred Out",
//   "No Appointment Scheduled - Insufficient Information",
//   "No Appointment Scheduled - Refused Appointment",
//   "No Appointment Scheduled - Provider Denied Appointment",
//   "No Appointment Scheduled - Referred to Walk-in clinic",
//   "No Appointment Scheduled - Patient chose another practice",
// ];

function ConfirmationDialog(props) {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { locationId } = jwt_decode(user.accessToken);

  const { onClose, value: valueProp, open, providerId, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [apptDate, setApptDate] = useState(null);
  const radioGroupRef = React.useRef(null);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { provider: "" },
    touched: {},
    errors: {},
    isLoading: false,
  });

  React.useEffect(() => {
    let unmounted = false;
    if (!open && !unmounted) {
      setValue(valueProp);
    }

    return () => {
      unmounted = true;
    };
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    setApptDate(() => null);
    onClose();
  };

  const handleOk = () => {
    onClose(value);
    setApptDate(() => null);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        <span style={{ fontWeight: 900 }}>Pending Reason</span>
      </DialogTitle>

      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="pending"
          name="selectedValue"
          value={value}
          onChange={handleChange}
        >
          {props?.pendingreasons &&
            props.pendingreasons.map((reason) => (
              <FormControlLabel
                value={`${reason.pendingreasonid.toString()}|${
                  reason.pending_reason
                }`}
                key={reason.pendingreasonid}
                control={<Radio />}
                label={reason.pending_reason}
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          color="secondary"
          variant="text"
        >
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
  input: {
    background: "white",
  },
}));

const SetPendingStatic = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const { user } = useContext(UserContext);
  const { userId } = jwt_decode(user.accessToken);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (newValue) => {
    setOpen(false);

    if (newValue) {
      const apptId = props.apptRequestId;

      /** get reasonid from start of newValue */
      let reasonId = newValue.substring(0, newValue.indexOf("|"));

      /** remove reasonid from newValue */
      newValue = newValue.substring(newValue.indexOf("|") + 1);

      try {
        await axios.put(apiUrl + "/appt/status", {
          apptReqId: apptId,
          requestStatus: 2,
          requestStatusReason: newValue,
          apptDate: null,
          reasonId: reasonId,
        });

        await axios.post(apiUrl + "/chat", {
          apptRequestId: apptId,
          userId: userId ?? null,
          message: `Marked as Pending by ${user.username} - ${newValue}`,
          isPatientMsg: false,
        });
      } catch (error) {
        console.error(error);
      }

      props.updateCurrent(); // update request detail

      //setValue(newValue);
    }
  };

  return (
    <>
      <Button
        style={{ marginTop: "10px", width: "100%" }}
        color="primary"
        endIcon={<PendingIcon />}
        size="large"
        variant="contained"
        onClick={handleClickOpen}
      >
        Pending
      </Button>
      <List component="div" role="list">
        <ConfirmationDialog
          classes={{
            paper: classes.paper,
          }}
          id="pending-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          pendingreasons={props.pendingreasons}
        />
      </List>
    </>
  );
};
export default SetPendingStatic;
