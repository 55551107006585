import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ChatIcon from "@material-ui/icons/Chat";
// import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "1rem",
    height: "90px",
    [theme.breakpoints.down("xs")]: {
      height: "55px",
      marginTop: "0px",
      padding: "0px",
      position: "fixed",
      bottom: "0px",
      left: "0px",
      right: "0px",
      width: "100%",
    },
  },
  // Root styles for `BottomNavigationAction` component
  actionItem: {
    // These styles are applied to the root element when
    // when selected. This changes the color of both the
    // icon and label text.
    // color: "#fff",
    "&$selected": {
      color: "#ffffff",
      borderColor: theme.palette.primary.main,
      borderTop: "2px solid",
      fontWeight: 700,
      backgroundColor: theme.palette.primary.main,
    },
  },
  // NOTE: You need to include a `.selected` class in your
  // styles rules for the "&$selected" selector to work.
  selected: {},
  // label: {
  //   color: "white",
  // },
}));

export default function ChatBottomNavigation(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.navSelected || 0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        props.displayComponent(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
          label: classes.label,
        }}
        label="Appt Request"
        icon={<ScheduleIcon />}
      />
      <BottomNavigationAction
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
          label: classes.label,
        }}
        label="Secure Chat"
        icon={<ChatIcon />}
      />
      {/* <BottomNavigationAction
        classes={{
          root: classes.actionItem,
          selected: classes.selected,
          label: classes.label,
        }}
        label="Instructions"
        icon={<HelpOutlineIcon />}
      /> */}
    </BottomNavigation>
  );
}
