import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import * as React from "react";

export const styles = StyleSheet.create({
  // font: { fontFamily: "Oswald" },
  font: {
    fontSize: "9px",
    padding: "30px",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: {
    borderTop: "none",
    textAlign: "center",
    padding: "5px",
  },
  bold: {
    fontWeight: 900,
  },
  row1: {
    width: "15%",
  },
  row2: {
    width: "20%",
    textAlign: "center",
  },
  row3: {
    width: "20%",
    textAlign: "left",
  },
  row4: {
    width: "5%",
    textAlign: "center",
  },
  row5: {
    width: "5%",
    textAlign: "center",
  },
  row6: {
    width: "5%",
    textAlign: "center",
  },
  row7: {
    width: "5%",
    textAlign: "center",
  },
  row8: {
    width: "5%",
    textAlign: "center",
  },
  row9: {
    width: "5%",
    textAlign: "center",
  },
  row10: {
    width: "5%",
    textAlign: "center",
  },
  row11: {
    width: "5%",
    textAlign: "center",
  },
  row12: {
    width: "5%",
    textAlign: "center",
  },
  row13: {
    width: "5%",
    textAlign: "center",
  },
});

const ReportReferralsByProvider = ({ data, title, startDate, endDate }) => {
  const member = data[0];
  if (member) {
    return (
      <Document>
        <Page size="A4" style={styles.font}>
          <View>
            <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
              {title}
            </Text>
            <Text style={{ fontSize: "12px", fontWeight: "bold" }}>
              Results for dates{" "}
              {startDate &&
                new Date(startDate.replace(/-/g, "/")).toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}{" "}
              -{" "}
              {endDate &&
                new Date(endDate.replace(/-/g, "/")).toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.row1}>Partner</Text>
              <Text style={styles.row2}>Referring MD</Text>
              <Text style={styles.row3}>Provider</Text>
              <Text style={styles.row4}>Requests</Text>
              <Text style={styles.row5}>Appointments</Text>
              <Text style={styles.row6}>Appt %</Text>
              <Text style={styles.row7}>No Response</Text>
              <Text style={styles.row8}>Refused</Text>
              <Text style={styles.row9}>Insufficient Info</Text>
              <Text style={styles.row10}>Walk-in</Text>
              <Text style={styles.row11}>Canceled</Text>
              <Text style={styles.row12}>Cancel %</Text>
            </View>
            {data.map((item, i) => (
              <View key={i} style={styles.row} wrap={false}>
                <Text style={styles.row1}>
                  {item?.partnername && item.partnername}
                </Text>
                <Text style={styles.row2}>
                  {item?.referring_md && item.referring_md}
                </Text>
                <Text style={styles.row3}>
                  {item?.providername && item.providername}
                </Text>
                <Text style={styles.row4}>
                  {item?.nrequests && item.nrequests}
                </Text>
                <Text style={styles.row5}>
                  {item?.qappointments && item.qappointments}
                </Text>
                <Text style={styles.row6}>{item?.apptpct && item.apptpct}</Text>
                <Text style={styles.row7}>
                  {item?.qnoresponse && item.qnoresponse}
                </Text>
                <Text style={styles.row8}>
                  {item?.qrefused && item.qrefused}
                </Text>
                <Text style={styles.row9}>
                  {item?.qinsufficient && item.qinsufficient}
                </Text>
                <Text style={styles.row10}>
                  {item?.qotherpracitce && item.qotherpracitce}
                </Text>
                <Text style={styles.row11}>
                  {item?.qwalkin && item.qwalkin}
                </Text>
                <Text style={styles.row12}>
                  {item?.qcanceled && item.qcanceled}
                </Text>
                <Text style={styles.row13}>
                  {item?.qcancelpct && item.qcancelpct}
                </Text>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  } else
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>This Pdf could not be generated</Text>
          </View>
        </Page>
      </Document>
    );
};

export default ReportReferralsByProvider;
