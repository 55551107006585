import React, { useState } from "react";
import { Grid, Tabs, Tab, makeStyles, AppBar } from "@material-ui/core";
import {
  ProviderManager,
  PendingReasonManager,
  ArchiveReasonManager,
  UserManager,
  ReferralManager,
  AccountManager,
} from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  userMenuButton: {
    marginRight: theme.spacing(2),
  },
}));

const Settings = () => {
  const classes = useStyles();

  // tabs
  const tabNameToIndex = {
    0: "Providers",
    1: "Pending",
    2: "Archive",
    3: "Users",
    4: "Referral Partners",
    5: "Account",
  };

  const indexToTabName = {
    providers: 0,
    pending: 1,
    archive: 2,
    users: 3,
    referral: 4,
    account: 5,
  };

  const [selectedTab, setSelectedTab] = useState(indexToTabName["providers"]);
  const [selected, setSelected] = useState(null);

  const updateSelected = (event) => {
    setSelected(event);
  };

  const handleChange = (event, newValue) => {
    // history.push(`/home/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          style={{ paddingBottom: "2rem" }}
        >
          <Tab label="Providers" />
          <Tab label="Pending Reasons" />
          <Tab label="Archive Reasons" />
          <Tab label="Users" />
          <Tab label="Referral Partners" />
          <Tab label="Account" />
        </Tabs>
      </AppBar>
      {selectedTab === 0 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <ProviderManager />
          </Grid>
        </Grid>
      )}
      {selectedTab === 1 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <PendingReasonManager />
          </Grid>
        </Grid>
      )}
      {selectedTab === 2 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <ArchiveReasonManager />
          </Grid>
        </Grid>
      )}
      {selectedTab === 3 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <UserManager />
          </Grid>
        </Grid>
      )}
      {selectedTab === 4 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <ReferralManager />
          </Grid>
        </Grid>
      )}
      {selectedTab === 5 && (
        <Grid container spacing={4}>
          <Grid item lg={10} md={12} xl={10} xs={12}>
            <AccountManager />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Settings;
