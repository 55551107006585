import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  header: {
    color: "#FFFFFF",
    textAlign: "left",
    padding: "1rem",
    fontSize: "1rem",
    fontWeight: 600,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const UserTable = (props) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {props && props.role <= 2 && (
              <TableCell className={classes.header}>Edit / Disable</TableCell>
            )}
            <TableCell className={classes.header}>First</TableCell>
            <TableCell className={classes.header}>Last</TableCell>
            <TableCell className={classes.header}>Email</TableCell>
            <TableCell className={classes.header}>Agent</TableCell>
            <TableCell className={classes.header}>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users && props.users.length > 0 ? (
            props.users.map((user, index) => (
              <TableRow key={index} className={classes.tableRow}>
                {props && props.role <= 2 && (
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        props.editRow(user);
                      }}
                      type="submit"
                    >
                      <EditIcon />
                    </IconButton>
                    {props.currentUserId !== user.id ? (
                      <IconButton
                        color="inherit"
                        onClick={() => props.deleteUser(user.id)}
                        type="submit"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </TableCell>
                )}

                <TableCell>
                  <input type="hidden" value={user.id} />
                  {user.first_name}
                </TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <div>{user.is_agent === true ? "Yes" : "No"}</div>
                </TableCell>
                <TableCell>{user.role === "2" ? "Admin" : "User"}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>Click above to add users!</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
