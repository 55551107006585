import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import apiUrl from "../../common/apiUrl.js";
import { SocketContext } from "../../SocketContext";
import { ChatContext } from "../../ChatContext";
import { BadgeContext } from "../../BadgeContext";
import { UserContext } from "../../UserContext";
import jwt_decode from "jwt-decode";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { TicketQueue, TicketDetail } from "./components";
import Chat from "./components/Chat";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const Agent = () => {
  const classes = useStyles();

  const socket = useContext(SocketContext);
  const { messages, setMessages } = useContext(ChatContext);
  const { badges, setBadges } = useContext(BadgeContext);
  const [prevRoom, setPrevRoom] = useState(null);
  const [apptReqs, setApptReqs] = useState();
  const [apptRecord, setApptRecord] = useState(null);
  const [archiveReasons, setArchiveReasons] = useState(null);
  const [pendingReasons, setPendingReasons] = useState(null);

  /** get appointmentId if passed via URL and select in UI */
  const { appointmentId } = useParams();
  let history = useHistory();
  const trimmedApptId = appointmentId ? appointmentId.substring(0, 20) : null;

  const { user } = useContext(UserContext);
  const { userId, locationId } = jwt_decode(user.accessToken);

  const lastRefresh = useRef(null);
  const currentRequest = useRef(null);

  const updateCurrentRequest = () => {
    currentRequest.current = null;
  };

  let apptReqList = useRef(null);
  let chatUserList = useRef([]);
  let hasUnreadMsgs = useRef(null);

  // retrieve initial appt req list
  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/appt/list", {
          userId: userId,
        });

        // fetch archive reasons
        const archiveReasonList = await axios.post(apiUrl + "/appt/reasons", {
          locationId: locationId,
        });

        // fetch pending reasons
        const pendingReasonList = await axios.post(apiUrl + "/appt/pending", {
          locationId: locationId,
        });

        /** TODO: switch back to previous method once query is updated */
        if (
          !unmounted &&
          result &&
          result?.data?.data?.[0]?.spgetrequestlist10?.requests
        ) {
          // let reqsNotNull =
          //   result?.data?.data?.[0]?.spgetrequestlist7?.requests &&
          //   result.data.data[0].spgetrequestlist7.requests.filter(
          //     (req) => req.totalscoremax !== null
          //   );

          apptReqList.current = [
            ...result?.data?.data?.[0]?.spgetrequestlist10?.requests,
          ];
          // apptReqList.current = [...reqsNotNull];
          setApptReqs([...result.data.data[0].spgetrequestlist10.requests]);
          // setApptReqs([...reqsNotNull]);
          if (chatUserList.current) {
            updateChatUsers(chatUserList.current);
          }
          setArchiveReasons([...archiveReasonList.data.data]);
          setPendingReasons([...pendingReasonList.data.data]);

          /** check if linking directly to appt request */
          if (trimmedApptId && result) {
            const apptReqTarget =
              result.data.data[0].spgetrequestlist7.requests.find(
                (req) => req.apptrequestid === parseInt(trimmedApptId)
              );

            if (apptReqTarget) {
              // handleTicketSelect(trimmedApptId);
              setPrevRoom(() => parseInt(trimmedApptId));

              hasUnreadMsgs.current =
                !!apptReqTarget?.unreadchats || !!apptReqTarget?.addBadge
                  ? apptReqTarget.apptrequestid
                  : null;

              currentRequest.current = apptReqTarget;
              setApptRecord(() => apptReqTarget);
              history.push({
                pathname: `/agent`,
              });
            }
          }
        } else {
          setApptReqs(null);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, locationId]);

  const getApptReq = (payload) => {
    try {
      let newReq = payload.requests[0];

      let tempApptReqs = apptReqList.current;

      const existingReqId = tempApptReqs?.findIndex(
        (req) => req.apptrequestid === newReq?.apptrequestid
      );

      if (existingReqId !== -1) {
        // test to see if totalscore is equal to new score
        if (
          newReq.totalscoremax !== tempApptReqs[existingReqId].totalscoremax
        ) {
          tempApptReqs.splice(existingReqId, 1, newReq);
          apptReqList.current = [...tempApptReqs];
          setApptReqs([...tempApptReqs]);
        } else {
          tempApptReqs.splice(existingReqId, 1);

          apptReqList.current = [...tempApptReqs];
          setApptReqs((prevApptReqs) => [
            ...prevApptReqs,
            prevApptReqs.splice(existingReqId, 1, newReq),
          ]);
        }
      } else {
        tempApptReqs.push(newReq);
        apptReqList.current = [...tempApptReqs];
        setApptReqs([...tempApptReqs]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateReqState = async (
    apptReqId,
    apptReqState,
    apptreqstateUpdatedOn
  ) => {
    try {
      let tempApptReqs = apptReqList.current;

      const existingReqId = tempApptReqs?.findIndex(
        (req) => req.apptrequestid === apptReqId
      );

      // update apptreqstate and apptreqstate_updated_on and re-sort
      tempApptReqs[existingReqId].apptreqstate = apptReqState;
      tempApptReqs[existingReqId].apptreqstate_updated_on =
        apptreqstateUpdatedOn;

      setApptRecord(null);
      currentRequest.current = null;

      apptReqList.current = [...tempApptReqs];
      setApptReqs([...tempApptReqs]);
    } catch (error) {
      console.error(error);
    }
  };

  /** update list if a user joins a chat */
  const updateChatUsers = (chatUsers) => {
    /**
     * users can join multiple chats at the same time
     * dedupe using Map to keep the last in the list
     * to display the current chat they are viewing to other users
     */
    const dedupedChatUserList = [
      ...new Map(
        chatUsers.map((chatUser) => [chatUser["userId"], chatUser])
      ).values(),
    ];

    // remove current user from the list
    const index = dedupedChatUserList.findIndex(
      (chatUsr) => chatUsr.userId === userId
    );
    if (index !== -1) {
      dedupedChatUserList.splice(index, 1);
    }

    chatUserList.current = [...dedupedChatUserList];
    chatUsers = chatUserList.current;

    try {
      let tempApptReqs = apptReqList.current;

      /** check to see if user is in any other chatrooms and remove them */
      if (tempApptReqs && !!tempApptReqs.length) {
        for (let i = 0; i < tempApptReqs.length; i++) {
          if (tempApptReqs[i]?.chatUsers) {
            for (let j = 0; j < tempApptReqs[i].chatUsers.length; j++) {
              for (let k = 0; k < chatUsers.length; k++) {
                if (
                  tempApptReqs[i].chatUsers[j]?.id &&
                  tempApptReqs[i].chatUsers[j].id === chatUsers[k].userId
                ) {
                  /** slice with the same effect as splice without mutation */
                  // tempApptReqs[i].chatUsers
                  //   .slice(0, j)
                  //   .concat(tempApptReqs[i].chatUsers.slice(j + 1));
                  tempApptReqs[i].chatUsers.splice(j, 1);
                }
              }
            }
          }
        }

        for (let i = 0; i < chatUsers.length; i++) {
          /** get index of chatroom */
          const chatRoom = tempApptReqs?.findIndex(
            (req) => req.apptrequestid === chatUsers[i]?.room
          );

          /** add user to chatroom */
          if (chatRoom !== -1) {
            // update chatUsers on the chat room
            tempApptReqs[chatRoom].chatUsers = tempApptReqs[chatRoom]?.chatUsers
              ? [
                  ...tempApptReqs[chatRoom].chatUsers,
                  { name: chatUsers[i]?.name, id: chatUsers[i]?.userId },
                ]
              : [{ name: chatUsers[i]?.name, id: chatUsers[i]?.userId }];
          }
        }

        apptReqList.current = [...tempApptReqs];
        setApptReqs([...tempApptReqs]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** remove chat user avatar */
  const removeChatUser = (chatUserId) => {
    try {
      if (chatUserId) {
        let tempApptReqs = apptReqList.current;
        for (let i = 0; i < tempApptReqs.length; i++) {
          if (tempApptReqs[i]?.chatUsers) {
            for (let j = 0; j < tempApptReqs[i].chatUsers.length; j++) {
              if (
                tempApptReqs[i].chatUsers[j]?.id &&
                tempApptReqs[i].chatUsers[j].id === chatUserId
              ) {
                tempApptReqs[i].chatUsers.splice(j, 1);
              }
            }
          }
        }
        apptReqList.current = [...tempApptReqs];
        setApptReqs([...tempApptReqs]);
      }
    } catch (error) {
      console.error(
        `Chat avatar was not removed for ChatUserId: ${chatUserId} with error: ${error}`
      );
    }
  };

  // listen for new appt reqs and update realtime
  useEffect(() => {
    let unmounted = false;
    socket.emit("listening", { userId, locationId }, (error) => {
      if (error) {
        console.error(error);
      }
    });

    socket.on("refresh", async (data) => {
      const payloadObj = await JSON.parse(data?.message.payload);

      const last = JSON.stringify(lastRefresh.current);

      const next = JSON.stringify(payloadObj);

      if (last !== next) {
        // update with latest notification data
        lastRefresh.current = data;

        if (data?.message.channel === "updated_totalscoremax2") {
          if (!unmounted) {
            // getApptReq(userId, data?.message?.apptrequestid);
            getApptReq(payloadObj);
          }

          return;
        }

        if (data?.message.channel === "updated_apptreqstate2") {
          if (!unmounted) {
            updateReqState(
              payloadObj?.apptrequestid,
              payloadObj?.apptreqstate,
              payloadObj?.apptreqstate_updated_on
            );
          }
          return;
        }
      }
    });

    /** list current chat user avatars */
    socket.on("chatusers", async (data) => {
      const chatUser = await data?.message;
      updateChatUsers(chatUser);
    });

    /** remove chat user avatar on disconnect */
    socket.on("removeChatUser", async (data) => {
      const chatUserIdToDisconnect = await data?.message;
      removeChatUser(chatUserIdToDisconnect);
    });

    /** add red dot badge when unread messages are in a chat room */
    socket.on("addbadge", async (data) => {
      if (data?.message) {
        updateNotifyUserBadges(data.message);
      }
    });

    return () => {
      unmounted = true;
      socket.removeAllListeners("listening");
      socket.removeAllListeners("refresh");
      socket.removeAllListeners("chatusers");
      socket.removeAllListeners("removeChatUser");
      socket.removeAllListeners("addbadge");
      socket.removeAllListeners();
    };
  }, [locationId, userId, socket]);

  /**
   *  check for unread chat messages on inbound message
   *  list when a user clicks on a chat
   */
  useEffect(() => {
    if (apptReqList.current) {
      let updatedApptReqs = apptReqList.current;

      // get unreads from messages
      const badgeList = messages.filter(
        (msg) => msg.isRead === false && msg.from === "patient"
      );

      // get unreads from existing unreadchats
      for (let j = 0; j < updatedApptReqs.length; j++) {
        if (updatedApptReqs[j]?.unreadchats) {
          badgeList.push({
            room: updatedApptReqs[j].unreadchats[0].apptrequestid,
          });
        }
      }

      // dedupe badge list
      const uniqueBadges = [...new Set(badgeList.map((msg) => msg.room))];

      setBadges((badges) => ({
        ...badges,
        roomBadges: uniqueBadges,
      }));

      for (let j = 0; j < updatedApptReqs.length; j++) {
        let checkForUnread = updatedApptReqs.some(() =>
          uniqueBadges.includes(updatedApptReqs[j].apptrequestid)
        );
        if (checkForUnread) {
          updatedApptReqs[j].addBadge = true;
        } else {
          updatedApptReqs[j].addBadge = false;
        }
      }

      apptReqList.current = [...updatedApptReqs];
      setApptReqs([...updatedApptReqs]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleTicketSelect = (event) => {
    // remove messages from message list to prevent duplicates
    if (prevRoom !== event) {
      const updatedMessages = messages.filter(
        (message) => message.room !== prevRoom
      );
      setMessages([...updatedMessages]);
    }

    // store new prevRoom to prevent duplicates
    setPrevRoom(() => event);

    const ticket = apptReqs.find((req) => req.apptrequestid === event);

    hasUnreadMsgs.current =
      !!ticket?.unreadchats || !!ticket?.addBadge ? ticket.apptrequestid : null;

    setApptRecord(() => ticket);
    currentRequest.current = ticket;
  };

  const updateNotifyUserBadges = (apptId) => {
    let currentReqs = apptReqList.current;

    if (currentReqs) {
      for (let i = 0; i < currentReqs.length; i++) {
        if (apptId && currentReqs[i].apptrequestid === apptId) {
          currentReqs[i].addBadge = true;
        }
      }

      apptReqList.current = currentReqs;
      setApptReqs([...currentReqs]);
    }
  };

  const unBadge = (apptId) => {
    let unbadgeApptReqs = apptReqList.current;

    const apptReqToUnbadgeIndex = unbadgeApptReqs.findIndex(
      (req) => req.apptrequestid === apptId?.apptId
    );

    if (apptReqToUnbadgeIndex !== -1) {
      unbadgeApptReqs[apptReqToUnbadgeIndex].addBadge = false;
      unbadgeApptReqs[apptReqToUnbadgeIndex].unreadchats = null;
    } else {
      return;
    }

    apptReqList.current = [...unbadgeApptReqs];
    setApptReqs([...unbadgeApptReqs]);
    currentRequest.current = unbadgeApptReqs[apptReqToUnbadgeIndex];
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item lg={4} sm={4} xl={4} xs={12}>
          <div style={{ maxHeight: "88vh" }}>
            <TicketQueue
              tickets={apptReqs}
              onClick={(apptId) => handleTicketSelect(apptId)}
              badges={badges}
            />
          </div>
        </Grid>
        <Grid item lg={4} sm={4} xl={4} xs={12}>
          {!apptRecord && currentRequest.current === null ? (
            <Chat chatSessionId={null} />
          ) : (
            <>
              <Chat
                chatSessionId={currentRequest.current.apptrequestid}
                canText={currentRequest.current.cantext}
                hasUnreadMsgs={hasUnreadMsgs.current}
                unBadge={unBadge}
                updateCurrent={updateCurrentRequest}
              />
            </>
          )}
        </Grid>
        <Grid item lg={4} sm={4} xl={4} xs={12}>
          {!apptRecord && currentRequest.current === null ? (
            <TicketDetail ticket={null} />
          ) : (
            <TicketDetail
              ticket={currentRequest.current}
              onClick={updateCurrentRequest}
              archiveReasons={archiveReasons}
              pendingReasons={pendingReasons}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Agent;
