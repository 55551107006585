import React, { useState, useContext } from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import PDFLink from "../../../common/PDFLink";
import PickDateRange from "../../../common/PickDateRange";
import { UserContext } from "../../../UserContext";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: "flex-end",
  },
}));

const ReportList = ({ className, ...rest }) => {
  const classes = useStyles();
  const [data, setData] = useState([
    {
      id: 1,
      reportName: "Requests by Partner and Provider",
      type: "List",
      description:
        "List of requests based on date range by partner and provider",
      startDate: null,
      endDate: null,
    },
    {
      id: 2,
      reportName: "Referral Source",
      type: "List",
      description: "List of referral sources with totals by date range",
      startDate: null,
      endDate: null,
    },
    {
      id: 3,
      reportName: "Appointment Detail",
      type: "List",
      description: "Detailed list of appointment statuses",
      startDate: null,
      endDate: null,
    },
    {
      id: 4,
      reportName: "Provider Appointments",
      type: "List",
      description: "List of appointments by provider",
      startDate: null,
      endDate: null,
    },
    {
      id: 5,
      reportName: "Referrals by Provider",
      type: "List",
      description: "Lists ",
      startDate: null,
      endDate: null,
    },
    {
      id: 6,
      reportName: "Scheduling Team Detail",
      type: "List",
      description: "List of activity by scheduling team members",
      startDate: null,
      endDate: null,
    },
  ]);
  const [reports] = useState(data);

  const { user } = useContext(UserContext);
  const { locationId } = jwt_decode(user.accessToken);

  const selectPeriod = (event) => {
    let start = event[0].startDate.toLocaleString("en-ca").split(",")[0];
    let end = event[0].endDate.toLocaleString("en-ca").split(",")[0];

    let reportIndex = data.findIndex((rpt) => rpt.id === event[0].reportId);

    if (reportIndex !== -1) {
      let newData = [...data];
      newData[reportIndex].startDate = start;
      newData[reportIndex].endDate = end;

      setData((data) => newData);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Reports" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800} height={400} style={{ overflowY: "scroll" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Report Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell style={{ width: "20%", textAlign: "center" }}>
                  Action
                </TableCell>
                <TableCell style={{ width: "20%" }}>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report) => (
                <TableRow hover key={report.id}>
                  <TableCell>{report.reportName}</TableCell>
                  <TableCell>{report.type}</TableCell>
                  <TableCell>{report.description}</TableCell>

                  <TableCell>
                    <PickDateRange
                      selectPeriod={(startDate, endDate) =>
                        selectPeriod(startDate, endDate)
                      }
                      reportId={report.id}
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <PDFLink
                      title={report.reportName}
                      reportId={report.id}
                      locationId={locationId}
                      startDate={report.startDate}
                      endDate={report.endDate}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

ReportList.propTypes = {
  className: PropTypes.string,
};

export default ReportList;
