import React, { useState, useEffect, useContext, Fragment } from "react";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
import AddUserForm from "./AddUserForm";
import EditUserForm from "./EditUserForm";
import UserTable from "./UserTable";
import { makeStyles } from "@material-ui/styles";
import { Snackbar, AppBar, Toolbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const useStyles = makeStyles(() => ({
  userTable: {
    paddingTop: "2rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UserManager = () => {
  const classes = useStyles();

  const initialFormState = {
    id: null,
    first_name: "",
    last_name: "",
    mobile_phone: "",
    email: "",
    password: "",
    role: "",
    isAgent: "",
    isActive: "",
  };

  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user } = useContext(UserContext);

  const { userId, locationId, customerId, organizationId, role } = jwt_decode(
    user.accessToken
  );

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/user/list", {
          customerId: customerId,
        });
        setUsers(result.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, [customerId, refresh]);

  const addUser = async (nUser) => {
    //e.preventDefault();

    // console.log(JSON.stringify(nUser, null, 2));
    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/user", {
        userId: userId,
        firstName: nUser.first_name,
        lastName: nUser.last_name,
        mobilePhone: nUser.mobile_phone,
        email: nUser.email,
        role: nUser.role,
        createdBy: userId,
        userLocationId: locationId,
        userCustomerId: customerId,
        organizationId: organizationId,
        isAgent: nUser.isAgent,
        is_referral_partner: false,
      });

      setUsers([...users, result.data]);

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const updateUser = async (id, uUser) => {
    // console.log(uUser, null, 2);
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.put(apiUrl + "/user", {
        userId: id,
        first_name: uUser.values.first_name,
        last_name: uUser.values.last_name,
        mobile_phone: uUser.values.mobile_phone,
        email: uUser.values.email,
        role: uUser.values.role,
        updatedBy: uUser.values.createdBy,
        userLocationId: locationId,
        userCustomerId: customerId,
        organizationId: organizationId,
        isAgent: uUser.values.isAgent,
        isActive: uUser.values.isActive,
      });

      if (result.data) {
        setUsers(users.map((user) => (user.id === id ? uUser.values : user)));
      }
      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const deleteUser = async (id) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/user/disable", {
        userId: id,
      });

      if (result.data) {
        setUsers(users.filter((user) => user.id !== id));
      }

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const formatPrimaryPhoneLocal = (phone) => {
    var shortPhone = phone.substring(2);

    var x = shortPhone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = shortPhone = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    return x;
  };

  const editRow = (user) => {
    setEditing(true);

    setCurrentUser({
      isValid: true,
      values: {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        mobile_phone: formatPrimaryPhoneLocal(user.mobile_phone),
        email: user.email,
        role: user.role,
        isAgent: user.is_agent,
        isActive: user.is_active,
      },
      touched: {},
      errors: {},
      isLoading: false,
    });
  };

  const handleShowAddUser = () => {
    setShowAddUser((showAddUser) => !showAddUser);
  };

  return (
    <>
      {role && role <= 2 && (
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Button
              onClick={handleShowAddUser}
              color="primary"
              variant="outlined"
            >
              Create User
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <div className="container">
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openSnack}
        >
          <Alert onClose={handleClose} severity="success">
            User updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openErrorSnack}
        >
          <Alert onClose={handleClose} severity="error">
            User update failed. Please try again.
          </Alert>
        </Snackbar>
        <div className="flex-row">
          <div className="flex-large">
            {editing ? (
              <Fragment>
                <EditUserForm
                  currentUser={currentUser}
                  editing={editing}
                  setEditing={setEditing}
                  updateUser={updateUser}
                  userId={userId}
                />
              </Fragment>
            ) : (
              <Fragment>
                {showAddUser && (
                  <AddUserForm
                    showAddUserForm={() => handleShowAddUser()}
                    addUser={addUser}
                    loading={isLoading}
                  />
                )}
              </Fragment>
            )}
          </div>
          <div className={classes.userTable}>
            {!editing && (
              <UserTable
                currentUserId={userId}
                deleteUser={deleteUser}
                editRow={editRow}
                users={users}
                role={role}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManager;
