import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  Switch,
  FormControlLabel,
  Typography,
  CardContent,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardContainerHeaderTitle: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 600,
  },
  cardContainerSubHeaderTitle: {
    color: "#FFFFFF",
  },
  cardContainerHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    width: 325,
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(
    (value) => b.indexOf(value) !== -1 && !value.isPermanentField
  );
}

function union(a, b) {
  // return [...a, ...not(b, a)];
  return [
    a.filter((value) => b.indexOf(value) !== -1 && !b.isPermanentField),
    ...not(
      b.filter((perm) => !perm.isPermanentField),
      a
    ),
  ];
}

export default function LocationList(props) {
  const classes = useStyles();

  const [wwConfigOriginal, setWwConfigOriginal] = useState(null);
  const [wwConfig, setWwConfig] = useState(null);
  const [isEmailEnabled, setIsEmailEnabled] = useState(false);

  // const initialState = {
  //   wwFields: [
  //     {
  //       id: 1,
  //       fieldVarName: "firstName",
  //       fieldName: "First Name",
  //       isVisible: true,
  //       isRequired: true,
  //       isPermanentField: true,
  //     },
  //     {
  //       id: 2,
  //       fieldVarName: "PrimaryPhone",
  //       fieldName: "Call Back Number",
  //       isVisible: true,
  //       isRequired: true,
  //       isPermanentField: true,
  //     },
  //     {
  //       id: 3,
  //       fieldVarName: "PatientFirstName",
  //       fieldName: "Patient First Name",
  //       isVisible: true,
  //       isRequired: true,
  //       isPermanentField: true,
  //     },
  //     {
  //       id: 4,
  //       fieldVarName: "lastName",
  //       fieldName: "Patient Last Name",
  //       isVisible: true,
  //       isRequired: true,
  //       isPermanentField: true,
  //     },
  //     {
  //       id: 5,
  //       fieldVarName: "BirthDate",
  //       fieldName: "Patient Date of Birth",
  //       isVisible: true,
  //       isRequired: true,
  //       isPermanentField: false,
  //     },
  //     {
  //       id: 6,
  //       fieldVarName: "ZipCode",
  //       fieldName: "Patient Zip Code",
  //       isVisible: true,
  //       isRequired: true,
  //       isPermanentField: false,
  //     },
  //     {
  //       id: 7,
  //       fieldVarName: "StreetAddress",
  //       fieldName: "Patient Street Address",
  //       isVisible: true,
  //       isRequired: true,
  //       isPermanentField: false,
  //     },
  //     {
  //       id: 8,
  //       fieldVarName: "SSN",
  //       fieldName: "Patient Social Security Number",
  //       isVisible: true,
  //       isRequired: false,
  //       isPermanentField: false,
  //     },
  //     {
  //       id: 9,
  //       fieldVarName: "EmailAddress",
  //       fieldName: "Patient Email Address",
  //       isVisible: true,
  //       isRequired: false,
  //       isPermanentField: false,
  //     },
  //     {
  //       id: 10,
  //       fieldVarName: "InsuranceCompany",
  //       fieldName: "Insurance Company",
  //       isVisible: false,
  //       isRequired: false,
  //       isPermanentField: false,
  //     },
  //     {
  //       id: 11,
  //       fieldVarName: "InsurancePolicyNumber",
  //       fieldName: "Insurance Policy Number",
  //       isVisible: false,
  //       isRequired: false,
  //       isPermanentField: false,
  //     },
  //     {
  //       id: 12,
  //       fieldVarName: "NameOfInsured",
  //       fieldName: "Name Of Insured",
  //       isVisible: false,
  //       isRequired: false,
  //       isPermanentField: false,
  //     },
  //   ],
  // };

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  /** set left and right columns */
  const setLeftAndRight = (config) => {
    setLeft(config.filter((field) => field.isVisible));
    setRight(config.filter((field) => !field.isVisible));
  };

  /** retrieve initial config */
  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/settings/account/ww", {
          locationId: props.locationId,
        });
        if (!unmounted) {
          setWwConfigOriginal(result?.data?.data?.[0]?.config);
          setWwConfig(result?.data?.data?.[0]?.config?.wwFields);
          setLeftAndRight(result?.data?.data?.[0]?.config?.wwFields);
          setIsEmailEnabled(result?.data?.data?.[0]?.config?.isEmailEnabled);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      unmounted = true;
    };
  }, [props.locationId]);

  const handleSave = async (leftArr, rightArr) => {
    try {
      let newWwConfig = leftArr
        .concat(rightArr)
        .sort((a, b) => (a.id > b.id ? 1 : -1));

      const result = await axios.put(apiUrl + "/settings/account/ww", {
        locationId: props.locationId,
        config: { wwFields: newWwConfig, isEmailEnabled: isEmailEnabled },
        updatedBy: props.userId,
      });

      if (result) {
        setWwConfig(newWwConfig);
      }

      /** update original to handle reset */
      setWwConfigOriginal((wwConfigOriginal) => ({
        ...wwConfigOriginal,
        wwFields: newWwConfig,
        isEmailEnabled: isEmailEnabled,
      }));

      props.setOpenSnack(true);
    } catch (error) {
      console.error(error);
      props.setOpenErrorSnack(true);
    }
  };

  /** update switch */
  const handleEnableEmail = async () => {
    setIsEmailEnabled(!isEmailEnabled);
  };

  /** reset to previous config */
  const handleReset = () => {
    setLeftAndRight(wwConfigOriginal.wwFields);
    setIsEmailEnabled(wwConfigOriginal.isEmailEnabled);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(
        not(
          checked.filter((perm) => !perm.isPermanentField),
          items
        )
      );
    } else {
      setChecked(
        union(
          checked.filter((perm) => !perm.isPermanentField),
          items
        )
      );
    }
  };

  const handleCheckedRight = () => {
    let updateLeftVisibility = leftChecked.map((r) => {
      return { ...r, isVisible: false };
    });

    setRight(
      right.concat(
        updateLeftVisibility.filter((item) => !item.isPermanentField)
      )
    );

    setLeft(not(left, leftChecked));
    setChecked(
      not(
        checked.filter((perm) => !perm.isPermanentField),
        leftChecked
      )
    );
  };

  const handleCheckedLeft = () => {
    let updateRightVisibility = rightChecked.map((r) => {
      return { ...r, isVisible: true };
    });

    setLeft(left.concat(updateRightVisibility));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleIsRequired = (value) => {
    value.isRequired = !value.isRequired;
    let valueIndex = value.isVisible
      ? right.findIndex((val) => val.id === value.id)
      : left.findIndex((val) => val.id === value.id);

    if (valueIndex !== -1 && value.isVisible) {
      setRight((right[valueIndex].isRequired = !value.isRequired));
    } else if (valueIndex !== -1 && !value.isRequired) {
      setLeft((left[valueIndex].isRequired = !value.isRequired));
    }

    const refreshChecklist = [...checked];
    setChecked(refreshChecklist);
  };

  const customList = (title, items) => (
    <Card key={`title`}>
      <CardHeader
        className={classes.cardHeader}
        style={{ color: "#FFFFFF" }}
        avatar={
          <Checkbox
            style={{ color: "#FFFFFF" }}
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 3}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={
          <Typography type="body1" style={{ color: "#FFFFFF" }}>
            {title}
          </Typography>
        }
        subheader={
          <Typography
            type="body2"
            style={{ color: "#FFFFFF" }}
          >{`${numberOfChecked(items)}/${items.length} selected`}</Typography>
        }
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id + value.fieldName}
              role="listitem"
              style={
                index % 2
                  ? { backgroundColor: "#eeeeee" }
                  : { backgroundColor: "#ffffff" }
              }
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  onChange={handleToggle(value)}
                  disabled={value.isPermanentField}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={
                  value.isPermanentField
                    ? `${value.fieldName} - (Permanent Field)`
                    : `${value.fieldName}`
                }
              />
              {value.isVisible && !value.isPermanentField && (
                <FormControlLabel
                  style={{ fontSize: "9px" }}
                  label={<Typography variant="body2">Required?</Typography>}
                  labelPlacement="top"
                  control={
                    <Switch
                      size="small"
                      checked={value.isRequired}
                      onChange={() => handleIsRequired(value)}
                      name="isRequired"
                      color="secondary"
                    />
                  }
                />
              )}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Card>
      <CardHeader
        className={classes.cardContainerHeaderBackground}
        disableTypography
        title={
          <Typography className={classes.cardContainerHeaderTitle} type="body1">
            Manage Patient Appointment Request Questions
          </Typography>
        }
        subheader={
          <Typography
            className={classes.cardContainerSubHeaderTitle}
            type="body1"
          >
            Select the fields that patients see during an appointment request,
            and set whether they are required.
          </Typography>
        }
      />
      <CardContent>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList("Fields Visible To Patients", left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList("Field Options", right)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Tooltip
                title={`Email notifications ${
                  isEmailEnabled ? `are enabled.` : `are disabled.`
                }`}
                placement="right-start"
              >
                <FormControlLabel
                  style={{ fontSize: "9px", marginTop: "1rem" }}
                  label={
                    <Typography variant="body2">
                      Send email notifications to all users that are marked as
                      Agents when a new appointment request is made?
                    </Typography>
                  }
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isEmailEnabled}
                      onChange={() => handleEnableEmail(isEmailEnabled)}
                      name="isRequired"
                      color="secondary"
                    />
                  }
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(left, right)}
        >
          Save
        </Button>
        <Button size="small" color="primary" onClick={handleReset}>
          Reset
        </Button>
      </CardActions>
    </Card>
  );
}
