import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../UserContext";
import { SocketContext } from "../../../SocketContext";
import { ChatContext } from "../../../ChatContext";
import jwt_decode from "jwt-decode";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  List,
  Avatar,
  colors,
  IconButton,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LockIcon from "@material-ui/icons/Lock";
// import LockOpenIcon from "@material-ui/icons/LockOpen";
import ChatMessages from "./ChatMessages";
import SetPendingNote from "./SetPendingNote";

const useStyles = makeStyles((theme) => ({
  chatContainer: {
    width: "100%",
  },
  chatSection: {
    width: "100%",
    minHeight: "1rem",
  },
  messageArea: {
    overflowY: "auto",
    background: theme.palette.background.default,
  },
  messageInput: {
    marginTop: "40px",
  },
  systemMessage: {
    border: "0.5px dashed gray",
    borderRadius: "5px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    float: "right",
    background: "white",
  },
  systemMessageNote: {
    marginTop: 0,
    padding: "2px",
    marginRight: "70px",
    display: "inline-block",
    float: "right",
  },
  systemMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "right",
  },
  outboundMessage: {
    borderRadius: "5px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    float: "right",
    background: theme.palette.primary.main,
  },
  outboundMessageNote: {
    marginTop: 0,
    padding: "2px",
    marginRight: "70px",
    display: "inline-block",
    float: "right",
  },
  outboundMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "right",
  },
  inboundMessage: {
    borderRadius: "5px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    background: theme.palette.secondary.main,
  },
  inboundMessageNote: {
    marginTop: 0,
    padding: "2px",
    marginLeft: "70px",
    display: "inline-block",
  },
  inboundMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "left",
  },
  cardActions: {
    marginTop: 0,
    paddingTop: 0,
    marginRight: "20px",
    marginLeft: "20px",
    marginBottom: "20px",
  },
  cardHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

// let socket = null;

const Chat = ({
  chatSessionId,
  canText,
  hasUnreadMsgs,
  unBadge,
  updateCurrent,
}) => {
  const classes = useStyles();

  const socket = useContext(SocketContext);
  const { user } = useContext(UserContext);
  const currentUser = user.username;
  const { userId } = jwt_decode(user.accessToken);

  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");

  const { messages, setMessages } = useContext(ChatContext);

  useEffect(() => {
    const name = currentUser;
    const room = chatSessionId;

    if (name && room) {
      setRoom(room);
      setName(name);

      socket.emit("join", { name, room, userId, hasUnreadMsgs }, (error) => {
        if (error) {
          alert(error);
        }
      });
    }

    /** reconnect if socket is disconnected */
    socket.on("disconnect", () => {
      // connect fires when connected, also after reconnected
      socket.on("connect", () => {
        // console.log("connect", socket.connected);
        // automatically join the room
        if (name && room) {
          setRoom(room);
          setName(name);

          socket.emit("join", { name, room, userId }, (error) => {
            if (error) {
              alert(error);
            }
          });
        }
      });
    });

    return () => {
      const updatedMessages = messages.filter(
        (message) => message.room !== room
      );
      setMessages([...updatedMessages]);
    };
  }, [chatSessionId, socket, userId, currentUser]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages((messages) => [...messages, message]);
      // console.log(
      //   `logged inbound message from chat ${JSON.stringify(message)}`
      // );
    });

    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });

    /** remove badge if notified */
    socket.on("unbadge", (apptId) => {
      if (apptId) {
        unBadge(apptId);
      }
    });

    return () => {
      socket.removeAllListeners("message");
      socket.removeAllListeners("roomData");
      socket.removeAllListeners("unbadge");
    };
  }, [socket, setMessages, unBadge]);

  const sendMessage = (event) => {
    event.preventDefault();

    const isPatientMsg = false;

    if (message) {
      socket.emit("sendMessage", {
        message,
        chatSessionId,
        userId,
        isPatientMsg,
      });
      setMessage("");
    }
  };

  return (
    <>
      {chatSessionId ? (
        <Card className={classes.chatContainer}>
          <CardHeader
            // classes={{
            //   title: classes.cardHeaderTitle,
            //   subheader: classes.cardHeaderTitle,
            // }}
            className={classes.cardHeaderBackground}
            avatar={
              <Avatar
                aria-label="recipe"
                style={{ backgroundColor: colors.green[600] }}
              >
                <LockIcon />
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            disableTypography
            title={
              <Typography
                className={classes.cardHeaderTitle}
                type="body1"
                style={{ color: "#FFFFFF" }}
              >
                {`Secure Chat Session ID: ${room}`}
              </Typography>
            }
            subheader={
              users
                ? users.map((user, i) => (
                    <Typography
                      className={classes.cardHeaderTitle}
                      type="body1"
                      style={{ color: "#FFFFFF" }}
                      key={i}
                    >
                      {user.name === currentUser && user.userId > 6
                        ? ""
                        : user.userId > user.name + " (Patient)"}
                    </Typography>
                  ))
                : null
            }
          />
          <CardContent className={classes.messageArea}>
            <Box height={"59.25vh"} position="relative">
              <List className={classes.messageArea}>
                <ChatMessages
                  messages={messages}
                  name={name}
                  chatSessionId={chatSessionId}
                />
              </List>
            </Box>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <TextField
                    className={classes.messageInput}
                    fullWidth
                    onChange={({ target: { value } }) => setMessage(value)}
                    inputProps={{
                      maxLength: 1000,
                    }}
                    label="Type your message here"
                    onKeyPress={(event) =>
                      event.key === "Enter" ? sendMessage(event) : null
                    }
                    margin="dense"
                    multiline
                    name="message"
                    required
                    value={message}
                    variant="standard"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SetPendingNote
                    apptRequestId={chatSessionId}
                    updateCurrent={updateCurrent}
                    userId={userId}
                  />
                  <Button
                    style={{ marginTop: "10px" }}
                    color="primary"
                    endIcon={<SendIcon />}
                    size="large"
                    variant="outlined"
                    onClick={(e) => sendMessage(e)}
                  >
                    Send
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      ) : (
        ""
      )}
    </>
  );
};

export default Chat;
