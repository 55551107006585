import React, { useState, useEffect, useContext, Fragment } from "react";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
import AddProviderForm from "./AddProviderForm";
import EditProviderForm from "./EditProviderForm";
import ProviderTable from "./ProviderTable";
import { makeStyles } from "@material-ui/styles";
import { Snackbar, AppBar, Toolbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const useStyles = makeStyles(() => ({
  userTable: {
    paddingTop: "2rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProviderManager = () => {
  const classes = useStyles();

  const initialFormState = {
    id: null,
    first_name: "",
    last_name: "",
    mobile_phone: "",
    email: "",
    password: "",
    role: "",
    isAgent: "",
  };

  const [providers, setProviders] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [showAddProvider, setShowAddProvider] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user } = useContext(UserContext);
  const { userId, locationId, role } = jwt_decode(user.accessToken);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/settings/provider/list", {
          locationId: locationId,
        });
        if (!unmounted) {
          setProviders(result.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, [locationId, refresh]);

  const addProvider = async (newProvider) => {
    //e.preventDefault();

    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/settings/provider", {
        firstName: newProvider.firstName,
        lastName: newProvider.lastName,
        hideInWidget: newProvider.hideInWidget,
        sites: newProvider.sites,
        serviceLines: newProvider.serviceLines,
        createdBy: userId,
        locationId: locationId,
      });

      setProviders([...providers, result.data]);

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const updateProvider = async (id, updateProvider) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.put(apiUrl + "/settings/provider", {
        providerId: updateProvider.values.providerId,
        firstName: updateProvider.values.firstName,
        lastName: updateProvider.values.lastName,
        hideInWidget: updateProvider.values.hideInWidget,
        sites: updateProvider.values.sites
          ? updateProvider.values.sites.map((site) => site.value)
          : null,
        serviceLines: updateProvider.values.serviceLines
          ? updateProvider.values.serviceLines.map((svcLine) => svcLine.value)
          : null,
        updatedBy: userId,
        locationId: locationId,
      });

      if (result.data && updateProvider) {
        setProviders(
          providers.map((updateProvider) =>
            updateProvider.providerId === id
              ? updateProvider.values
              : updateProvider
          )
        );
      }
      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const deleteUser = async (id) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/settings/provider/archive", {
        providerId: id,
      });

      if (result.data) {
        setProviders(
          providers.filter((provider) => provider.providerid !== id)
        );
      }

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const editRow = (editProvider) => {
    setEditing(true);

    setCurrentProvider({
      isValid: true,
      values: {
        providerId: editProvider.providerid,
        firstName: editProvider.providername,
        lastName: editProvider.providerlastname,
        hideInWidget: editProvider.hide_in_widget,
        siteIds: [...new Set(editProvider.siteids)],
        sites: null,
        currentSites: editProvider.sites,
        serviceLineIds: [...new Set(editProvider.servicelineids)],
        serviceLines: null,
        currentServiceLines: editProvider.servicelines,
        locationId: locationId,
      },
      touched: {},
      errors: {},
      isLoading: false,
    });
  };

  const handleShowAddProviderForm = () => {
    setShowAddProvider((showAddProvider) => !showAddProvider);
  };

  return (
    <>
      {role && role <= 2 && (
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Button
              onClick={handleShowAddProviderForm}
              color="primary"
              variant="outlined"
            >
              Create Provider
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <div className="container">
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openSnack}
        >
          <Alert onClose={handleClose} severity="success">
            Provider updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openErrorSnack}
        >
          <Alert onClose={handleClose} severity="error">
            Adding provider failed. Please try again.
          </Alert>
        </Snackbar>
        <div className="flex-row">
          <div className="flex-large">
            {editing ? (
              <Fragment>
                <EditProviderForm
                  currentProvider={currentProvider}
                  editing={editing}
                  setEditing={setEditing}
                  updateProvider={updateProvider}
                  locationId={locationId}
                />
              </Fragment>
            ) : (
              <Fragment>
                {showAddProvider && (
                  <AddProviderForm
                    showAddProviderForm={() => handleShowAddProviderForm()}
                    addProvider={addProvider}
                    loading={isLoading}
                    locationId={locationId}
                  />
                )}
              </Fragment>
            )}
          </div>
          <div className={classes.userTable}>
            {!editing && (
              <ProviderTable
                currentUserId={userId}
                deleteUser={deleteUser}
                editRow={editRow}
                providers={providers}
                role={role}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderManager;
