import React, { useState, useEffect, useContext, Fragment } from "react";
import { UserContext } from "../../../../UserContext";
import jwt_decode from "jwt-decode";
import AddArchiveReasonForm from "./AddArchiveReasonForm";
import EditArchiveReasonForm from "./EditArchiveReasonForm";
import ArchiveReasonTable from "./ArchiveReasonTable";
import { makeStyles } from "@material-ui/styles";
import { Snackbar, AppBar, Toolbar, Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import apiUrl from "../../../../common/apiUrl";

const useStyles = makeStyles(() => ({
  ArchiveReasonTable: {
    paddingTop: "2rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ArchiveReasonManager = () => {
  const classes = useStyles();

  const initialFormState = {
    archiveReasonId: null,
    archive_reason: "",
  };

  const [archiveReasons, setArchiveReasons] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentArchiveReason, setCurrentArchiveReason] =
    useState(initialFormState);
  const [editing, setEditing] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [openErrorSnack, setOpenErrorSnack] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenErrorSnack(false);
  };

  const { user } = useContext(UserContext);
  const { userId, locationId, role } = jwt_decode(user.accessToken);

  useEffect(() => {
    let unmounted = false;
    const fetchData = async () => {
      try {
        const result = await axios.post(
          apiUrl + "/settings/archivereason/list",
          {
            locationId: locationId,
          }
        );
        if (!unmounted) {
          setArchiveReasons(result.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
      // eslint-disable-next-line
      unmounted = true;
    };
  }, [locationId, refresh]);

  const addArchiveReason = async (archiveReason) => {
    //e.preventDefault();

    setIsLoading(true);

    try {
      const result = await axios.post(apiUrl + "/settings/archivereason", {
        reason: archiveReason.archiveReason,
        createdBy: userId,
        locationId: locationId,
      });

      if (result?.data) {
        setArchiveReasons([...archiveReasons, result.data]);
      }

      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
    }
  };

  const updateArchiveReason = async (id, archiveReason) => {
    setEditing(false);

    setIsLoading(true);

    try {
      const result = await axios.put(apiUrl + "/settings/archivereason", {
        archiveReasonId: id,
        archiveReason: archiveReason.values.archiveReason,
        isActive: archiveReason.values.isActive,
        updatedBy: userId,
      });

      /** if update is sucessful, update list with local values */
      if (result?.data) {
        setArchiveReasons(
          archiveReasons.map((archiveReason) =>
            archiveReason.id === id ? archiveReason.values : archiveReason
          )
        );
      }
      setIsLoading(false);
      setOpenSnack(true);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
      // return error
    }
  };

  const disableArchiveReason = async (id) => {
    setEditing(false);

    setIsLoading(true);

    let archiveReasonIndex = archiveReasons.findIndex(
      (r) => r.archivereasonid === id
    );

    try {
      if (archiveReasonIndex !== -1) {
        const result = await axios.post(
          apiUrl + "/settings/archivereason/archive",
          {
            archiveReasonId: id,
            archiveReason: archiveReasons[archiveReasonIndex].archive_reason,
            isActive: false,
            updatedBy: userId,
          }
        );

        if (result.data) {
          setArchiveReasons(
            archiveReasons.filter((archiveReason) => archiveReason.id !== id)
          );
        }

        setIsLoading(false);
        setOpenSnack(true);
        setRefresh(!refresh);
      } else {
        throw new Error(
          "There was an error deleting archive reason. Please try again."
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpenErrorSnack(true);
      setRefresh(!refresh);
    }
  };

  /** row selected from table */
  const editRow = (editReason) => {
    setEditing(true);

    setCurrentArchiveReason({
      isValid: true,
      values: {
        archiveReasonId: editReason.archivereasonid,
        archiveReason: editReason.archive_reason,
        isActive: editReason.is_active,
      },
      touched: {},
      errors: {},
      isLoading: false,
    });
  };

  const handleShowAddArchiveReason = () => {
    setShowAddUser((showAddUser) => !showAddUser);
  };

  return (
    <>
      {role && role <= 2 && (
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Button
              onClick={handleShowAddArchiveReason}
              color="primary"
              variant="outlined"
            >
              Create Archive Reason
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <div className="container">
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openSnack}
        >
          <Alert onClose={handleClose} severity="success">
            Archive reason updated successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          autoHideDuration={6000}
          onClose={handleClose}
          open={openErrorSnack}
        >
          <Alert onClose={handleClose} severity="error">
            Archive reason update failed. Please try again.
          </Alert>
        </Snackbar>
        <div className="flex-row">
          <div className="flex-large">
            {editing ? (
              <Fragment>
                <EditArchiveReasonForm
                  currentArchiveReason={currentArchiveReason}
                  editing={editing}
                  setEditing={setEditing}
                  updateArchiveReason={updateArchiveReason}
                />
              </Fragment>
            ) : (
              <Fragment>
                {showAddUser && (
                  <AddArchiveReasonForm
                    showAddArchiveReasonForm={() =>
                      handleShowAddArchiveReason()
                    }
                    addArchiveReason={addArchiveReason}
                    loading={isLoading}
                  />
                )}
              </Fragment>
            )}
          </div>
          <div className={classes.ArchiveReasonTable}>
            {!editing && (
              <ArchiveReasonTable
                currentArchiveReasonId={userId}
                disableArchiveReason={disableArchiveReason}
                editRow={editRow}
                archiveReasons={archiveReasons}
                role={role}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchiveReasonManager;
