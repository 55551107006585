import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  header: {
    color: "#FFFFFF",
    textAlign: "left",
    padding: "1rem",
    fontSize: "1rem",
    fontWeight: 600,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const PendingReasonTable = (props) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {props && props.role <= 2 && (
              <TableCell className={classes.header}>Edit / Archive</TableCell>
            )}
            <TableCell className={classes.header}>Reason</TableCell>
            <TableCell className={classes.header}>Type</TableCell>
            <TableCell className={classes.header}>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.pendingReasons && props.pendingReasons.length > 0 ? (
            props.pendingReasons.map((pendingReason, index) => (
              <TableRow key={index} className={classes.tableRow}>
                {props && props.role <= 2 && (
                  <TableCell>
                    {pendingReason.pendingreasonid > 9 ? (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            props.editRow(pendingReason);
                          }}
                          type="submit"
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          color="inherit"
                          onClick={() =>
                            props.disablePendingReason(
                              pendingReason.pendingreasonid
                            )
                          }
                          type="submit"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </>
                    ) : (
                      ""
                    )}
                  </TableCell>
                )}

                <TableCell>{pendingReason.pending_reason}</TableCell>
                <TableCell>
                  {pendingReason.pendingreasonid < 10 ? "System" : "Custom"}
                </TableCell>
                <TableCell>
                  {pendingReason.is_active === true ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                Click above to add pending reasons!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PendingReasonTable;
