import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  header: {
    color: "#FFFFFF",
    textAlign: "left",
    padding: "1rem",
    fontSize: "1rem",
    fontWeight: 600,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

const ProviderTable = (props) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {props && props.role <= 2 && (
              <TableCell className={classes.header}>Edit / Archive</TableCell>
            )}
            <TableCell className={classes.header}>Provider</TableCell>
            <TableCell className={classes.header}>Locations</TableCell>
            <TableCell className={classes.header}>Service Lines</TableCell>
            <TableCell className={classes.header}>
              Can Patient Request?
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.providers && props.providers.length > 0 ? (
            props.providers.map((provider, index) => (
              <TableRow key={index} className={classes.tableRow}>
                {props && props.role <= 2 && (
                  <TableCell style={{ width: "12%" }}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        props.editRow(provider);
                      }}
                      type="submit"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="inherit"
                      onClick={() => props.deleteUser(provider.providerid)}
                      type="submit"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                )}
                <TableCell style={{ width: "15%" }}>
                  {provider?.providername}
                </TableCell>
                <TableCell>
                  {provider?.sites &&
                    !!provider.sites.length &&
                    provider.sites.map((site, index) =>
                      index === provider.sites.length - 1
                        ? `${site}`
                        : `${site}, `
                    )}
                </TableCell>
                <TableCell>
                  {provider?.servicelines &&
                    !!provider.servicelines.length &&
                    provider.servicelines.map((svcLine, index) =>
                      index === provider.servicelines.length - 1
                        ? `${svcLine}`
                        : `${svcLine}, `
                    )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {!provider.hide_in_widget ? (
                    <Tooltip title="Patient's can request this provider!">
                      <CheckCircleOutlineIcon className={classes.success} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Patient's can't request this provider">
                      <ErrorOutlineIcon className={classes.warning} />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>Click above to add locations!</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProviderTable;
