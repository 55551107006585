import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../UserContext";
import jwt_decode from "jwt-decode";
import apiUrl from "../../../common/apiUrl";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import Loading from "./Loading";
import { Doughnut } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import BlockIcon from "@material-ui/icons/Block";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import PickDateRange from "../../../common/PickDateRange";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

const RequestsByPeriod = ({ className, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { user } = useContext(UserContext);
  const { locationId } = jwt_decode(user.accessToken);

  const initialFormState = {
    period: {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
    },
  };

  const [period, setPeriod] = useState(initialFormState);
  const [requestsByPeriodValues, setRequestsByPeriodValues] = useState();
  const [requestsByPeriodLabels, setRequestsByPeriodLabels] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // retrieve dashboard data
  useEffect(() => {
    let unmounted = false;
    setIsLoading(() => true);
    const fetchData = async () => {
      try {
        const result = await axios.post(apiUrl + "/dashboard/period", {
          locationId: locationId,
          period: period.period,
        });

        if (result && !unmounted) {
          var chartObj = result.data.data[0];

          Object.entries(chartObj).map(([key, el]) => {
            return el === null ? (chartObj[key] = 0) : (chartObj[key] = el);
          });

          setRequestsByPeriodValues(() => Object.values(chartObj));
          setRequestsByPeriodLabels(() => Object.keys(result.data.data[0]));
          setIsLoading(() => false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    return () => {
      unmounted = true;
    };
  }, [locationId, period.period]);

  const data = {
    datasets: [
      {
        data: requestsByPeriodValues,
        backgroundColor: [
          theme.palette.primary.main,
          colors.orange[600],
          theme.palette.ndGreen.main,
          colors.cyan[600],
          colors.yellow[600],
          colors.red[600],
          colors.amber[600],
          colors.indigo[600],
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: requestsByPeriodLabels,
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  const activePending = [
    {
      title: "Total Requests",
      value:
        requestsByPeriodValues &&
        parseInt(requestsByPeriodValues[0], 10) +
          parseInt(requestsByPeriodValues[1], 10) +
          parseInt(requestsByPeriodValues[2], 10) +
          parseInt(requestsByPeriodValues[3], 10) +
          parseInt(requestsByPeriodValues[4], 10) +
          parseInt(requestsByPeriodValues[5], 10) +
          parseInt(requestsByPeriodValues[6], 10) +
          parseInt(requestsByPeriodValues[7], 10),
      icon: DonutLargeIcon,
      color: theme.palette.primary.main,
    },
    {
      title: "Pending Requests",
      value: requestsByPeriodValues && requestsByPeriodValues[1],
      icon: HourglassEmptyIcon,
      color: colors.orange[600],
    },
    {
      title: "Scheduled Appt",
      value: requestsByPeriodValues && requestsByPeriodValues[2],
      icon: CheckCircleOutlineIcon,
      color: theme.palette.ndGreen.main,
    },
    {
      title: "Referred Out",
      value: requestsByPeriodValues && requestsByPeriodValues[3],
      icon: CallMissedOutgoingIcon,
      color: colors.cyan[600],
    },
  ];

  const statuses = [
    {
      title: "Insufficient Info",
      value: requestsByPeriodValues && requestsByPeriodValues[4],
      icon: HelpOutlineIcon,
      color: colors.yellow[600],
    },
    {
      title: "Refused Appt",
      value: requestsByPeriodValues && requestsByPeriodValues[5],
      icon: BlockIcon,
      color: colors.red[600],
    },
    {
      title: "Referred to Walk-in",
      value: requestsByPeriodValues && requestsByPeriodValues[6],
      icon: DirectionsWalkIcon,
      color: colors.amber[600],
    },
    {
      title: "Patient Chose Another Practice",
      value: requestsByPeriodValues && requestsByPeriodValues[7],
      icon: ExitToAppIcon,
      color: colors.indigo[600],
    },
  ];

  const selectPeriod = (event) => {
    setPeriod(() => ({
      period: {
        startDate: event[0].startDate,
        endDate: event[0].endDate,
      },
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardHeader
            action={
              <PickDateRange
                selectPeriod={(startDate, endDate) =>
                  selectPeriod(startDate, endDate)
                }
              />
            }
            title="Requests by Status"
          />
          <Divider />
          <CardContent>
            <Box height={300} position="relative">
              <Doughnut data={data} options={options} />
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              {activePending.map(({ color, icon: Icon, title, value }) => (
                <Box key={title} p={1} textAlign="center">
                  <Icon color="action" />
                  <Typography color="textPrimary" variant="body1">
                    {title}
                  </Typography>
                  <Typography style={{ color }} variant="h2">
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              {statuses.map(({ color, icon: Icon, title, value }) => (
                <Box key={title} p={1} textAlign="center">
                  <Icon color="action" />
                  <Typography color="textPrimary" variant="body1">
                    {title}
                  </Typography>
                  <Typography style={{ color }} variant="h2">
                    {value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};

RequestsByPeriod.propTypes = {
  className: PropTypes.string,
};

export default RequestsByPeriod;
