import { createContext, useState, useMemo } from "react";

// export const ApptReqContext = createContext();

export const ApptReqContext = createContext();

export const ApptReqProvider = ({ children }) => {
  const [oQues, setOQues] = useState();
  //const oQuesValue = useMemo(() => ({ oQues, setOQues }), [oQues, setOQues]);

  const [wwConfig, setWwConfig] = useState();
  //   const wwConfigValue = useMemo(
  //     () => ({ wwConfig, setWwConfig }),
  //     [wwConfig, setWwConfig]
  //   );

  const [emailSentThisSession, setEmailSentThisSession] = useState(false);

  const [patientInfo, setPatientInfo] = useState({
    values: {
      FirstName: "",
      PatientFirstName: "",
      LastName: "",
      StreetAddress: "",
      ZipCode: "",
      PrimaryPhone: "",
      EmailAddress: "",
      SSN: "",
    },
  });

  return (
    // <ApptReqContext.Provider value={{ oQuesValue, wwConfigValue }}>
    <ApptReqContext.Provider
      value={{
        oQues,
        setOQues,
        wwConfig,
        setWwConfig,
        emailSentThisSession,
        setEmailSentThisSession,
        patientInfo,
        setPatientInfo,
      }}
    >
      {children}
    </ApptReqContext.Provider>
  );
};

// export default ApptReqContext;
