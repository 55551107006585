import React, { useState } from "react";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateRangeIcon from "@material-ui/icons/DateRange";

const PickDateRange = (props) => {
  let reportId = props.reportId ? props.reportId : null;
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: "selection",
      reportId: reportId,
    },
  ]);

  const handleChange = (e) => {
    setState(() => [e.selection]);
    // props.selectPeriod(e);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.selectPeriod(state);
  };

  return (
    <>
      <Button
        style={{ marginTop: "10px", width: "100%" }}
        color="primary"
        endIcon={<DateRangeIcon />}
        size="large"
        onClick={handleClickOpen}
      >
        Select Period
      </Button>
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 900 }}>Select Reporting Period</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a specific date or date range for your report
          </DialogContentText>
          <DateRangePicker
            onChange={(e) => handleChange(e)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            size="large"
            variant="text"
          >
            Show Report
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PickDateRange;
