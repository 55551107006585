import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const PatientSearch = (props) => {
  const [open, setOpen] = useState(false);

  let suggestion = [];
  const [value, setValue] = useState("");
  const [list, setList] = useState();

  const setInputValue = (e, x) => {
    setValue(() => x);
  };

  const reqList = props.reqList;

  const autocomplete = (event) => {
    setValue(() => event.target.value);
    reqList.forEach((x) => {
      if (
        x.patientfirstname
          .substr(0, event.target.value.length)
          .toUpperCase() === event.target.value.toUpperCase() ||
        x.patientfirstname
          .substr(0, event.target.value.length)
          .toUpperCase() === event.target.value.toUpperCase() ||
        x.lastname.substr(0, event.target.value.length).toUpperCase() ===
          event.target.value.toUpperCase() ||
        x.birthdateage.substr(0, event.target.value.length).toUpperCase() ===
          event.target.value.toUpperCase() ||
        x.apptrequestid
          .toString()
          .substr(0, event.target.value.length)
          .toUpperCase() === event.target.value.toUpperCase()
      ) {
        suggestion.push(
          `${x.patientfirstname} ${x.lastname} - ${x.birthdateage}, ID: ${x.apptrequestid}`
        );

        setList(() =>
          suggestion.map((x, index) => {
            return (
              <p
                style={{ cursor: "pointer", color: "#949494" }}
                key={index}
                onClick={(e) => setInputValue(e, x)}
              >
                {x}
              </p>
            );
          })
        );
      }

      if (event.target.value.length === 0) {
        setList(() => "");
      }
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setValue(() => "");
    setList(() => "");
    setOpen(false);
  };

  const handleSearch = (apptId) => {
    setValue(() => "");
    setList(() => "");
    setOpen(false);

    const id = apptId.split("ID: ").pop();

    props.updateSelected(parseInt(id)); // update selected request and navigate to tab
    props.handleTicketSelect(parseInt(id));

    let tabId = reqList.filter((req) => req.apptrequestid === parseInt(id));
    tabId = parseInt(tabId[0].apptreqstate - 1);

    props.updateSelectedTab(tabId);
  };

  return (
    <>
      <IconButton
        edge="start"
        color="primary"
        aria-label="search"
        onClick={handleClickOpen}
      >
        <SearchIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 900 }}> Patient Search</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            1. Enter the patient's first name, last name, or date of birth
            (MM/DD/YYYY) to search for a patient in the Active, Pending, or
            Archive list. <br />
            2. Select the suggestion below to autocomplete the search field.{" "}
            <br />
            3. Click "Search" once the patient's name including the ID is listed
            in the search field.
          </DialogContentText>
          <TextField
            autoFocus
            onChange={(e) => autocomplete(e)}
            value={value}
            margin="dense"
            id="statusReason"
            label="Search:"
            type="text"
            fullWidth
          />
          <div>{list}</div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            size="large"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => handleSearch(value)}
            disabled={
              value === null || value === undefined || value.length < 20
            }
          >
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PatientSearch;
