import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import validate from "validate.js";

const schema = {
  partnerName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
  },
  partnerAbbr: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  stateAbbr: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  first_name: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  last_name: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  mobile_phone: {
    presence: {
      allowEmpty: false,
      message: "is required in the format 123-456-7890",
    },
    length: {
      minimum: 12,
    },
    format: {
      pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
      flags: "i",
      message: "is required in the format 123-456-7890",
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  cardHeaderTitle: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 600,
  },
  cardSubHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const AddReferralForm = (props) => {
  const classes = useStyles();

  const initialFormState = {
    isValid: false,
    values: {
      partnerName: "",
      partnerAbbr: "",
      partnerUrl: "",
      city: "",
      stateAbbr: "",
      first_name: "",
      last_name: "",
      mobile_phone: "",
      email: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  };

  const [referralPartner, setReferralPartner] = useState(initialFormState);

  const [states] = useState([
    {
      State: "Alabama",
      Abbrev: "Ala.",
      Code: "AL",
    },
    {
      State: "Alaska",
      Abbrev: "Alaska",
      Code: "AK",
    },
    {
      State: "Arizona",
      Abbrev: "Ariz.",
      Code: "AZ",
    },
    {
      State: "Arkansas",
      Abbrev: "Ark.",
      Code: "AR",
    },
    {
      State: "California",
      Abbrev: "Calif.",
      Code: "CA",
    },
    {
      State: "Colorado",
      Abbrev: "Colo.",
      Code: "CO",
    },
    {
      State: "Connecticut",
      Abbrev: "Conn.",
      Code: "CT",
    },
    {
      State: "Delaware",
      Abbrev: "Del.",
      Code: "DE",
    },
    {
      State: "District of Columbia",
      Abbrev: "D.C.",
      Code: "DC",
    },
    {
      State: "Florida",
      Abbrev: "Fla.",
      Code: "FL",
    },
    {
      State: "Georgia",
      Abbrev: "Ga.",
      Code: "GA",
    },
    {
      State: "Hawaii",
      Abbrev: "Hawaii",
      Code: "HI",
    },
    {
      State: "Idaho",
      Abbrev: "Idaho",
      Code: "ID",
    },
    {
      State: "Illinois",
      Abbrev: "Ill.",
      Code: "IL",
    },
    {
      State: "Indiana",
      Abbrev: "Ind.",
      Code: "IN",
    },
    {
      State: "Iowa",
      Abbrev: "Iowa",
      Code: "IA",
    },
    {
      State: "Kansas",
      Abbrev: "Kans.",
      Code: "KS",
    },
    {
      State: "Kentucky",
      Abbrev: "Ky.",
      Code: "KY",
    },
    {
      State: "Louisiana",
      Abbrev: "La.",
      Code: "LA",
    },
    {
      State: "Maine",
      Abbrev: "Maine",
      Code: "ME",
    },
    {
      State: "Maryland",
      Abbrev: "Md.",
      Code: "MD",
    },
    {
      State: "Massachusetts",
      Abbrev: "Mass.",
      Code: "MA",
    },
    {
      State: "Michigan",
      Abbrev: "Mich.",
      Code: "MI",
    },
    {
      State: "Minnesota",
      Abbrev: "Minn.",
      Code: "MN",
    },
    {
      State: "Mississippi",
      Abbrev: "Miss.",
      Code: "MS",
    },
    {
      State: "Missouri",
      Abbrev: "Mo.",
      Code: "MO",
    },
    {
      State: "Montana",
      Abbrev: "Mont.",
      Code: "MT",
    },
    {
      State: "Nebraska",
      Abbrev: "Nebr.",
      Code: "NE",
    },
    {
      State: "Nevada",
      Abbrev: "Nev.",
      Code: "NV",
    },
    {
      State: "New Hampshire",
      Abbrev: "N.H.",
      Code: "NH",
    },
    {
      State: "New Jersey",
      Abbrev: "N.J.",
      Code: "NJ",
    },
    {
      State: "New Mexico",
      Abbrev: "N.M.",
      Code: "NM",
    },
    {
      State: "New York",
      Abbrev: "N.Y.",
      Code: "NY",
    },
    {
      State: "North Carolina",
      Abbrev: "N.C.",
      Code: "NC",
    },
    {
      State: "North Dakota",
      Abbrev: "N.D.",
      Code: "ND",
    },
    {
      State: "Ohio",
      Abbrev: "Ohio",
      Code: "OH",
    },
    {
      State: "Oklahoma",
      Abbrev: "Okla.",
      Code: "OK",
    },
    {
      State: "Oregon",
      Abbrev: "Ore.",
      Code: "OR",
    },
    {
      State: "Pennsylvania",
      Abbrev: "Pa.",
      Code: "PA",
    },
    {
      State: "Rhode Island",
      Abbrev: "R.I.",
      Code: "RI",
    },
    {
      State: "South Carolina",
      Abbrev: "S.C.",
      Code: "SC",
    },
    {
      State: "South Dakota",
      Abbrev: "S.D.",
      Code: "SD",
    },
    {
      State: "Tennessee",
      Abbrev: "Tenn.",
      Code: "TN",
    },
    {
      State: "Texas",
      Abbrev: "Tex.",
      Code: "TX",
    },
    {
      State: "Utah",
      Abbrev: "Utah",
      Code: "UT",
    },
    {
      State: "Vermont",
      Abbrev: "Vt.",
      Code: "VT",
    },
    {
      State: "Virginia",
      Abbrev: "Va.",
      Code: "VA",
    },
    {
      State: "Washington",
      Abbrev: "Wash.",
      Code: "WA",
    },
    {
      State: "West Virginia",
      Abbrev: "W.Va.",
      Code: "WV",
    },
    {
      State: "Wisconsin",
      Abbrev: "Wis.",
      Code: "WI",
    },
    {
      State: "Wyoming",
      Abbrev: "Wyo.",
      Code: "WY",
    },
  ]);

  useEffect(() => {
    const errors = validate(referralPartner.values, schema);

    setReferralPartner((referralPartner) => ({
      ...referralPartner,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [referralPartner.values]);

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setReferralPartner((referralPartner) => ({
      ...referralPartner,
      values: {
        ...referralPartner.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...referralPartner.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleInputChange = (event) => {
    event.persist();

    setReferralPartner((referralPartner) => ({
      ...referralPartner,
      values: {
        ...referralPartner.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...referralPartner.touched,
        [event.target.name]: true,
      },
    }));
  };

  // const handlePhoneChange = (event) => {
  //   event.persist();

  //   var x = event.target.value
  //     .replace(/\D/g, "")
  //     .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //   x = event.target.value = !x[2]
  //     ? x[1]
  //     : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

  //   setReferralPartner((referralPartner) => ({
  //     ...referralPartner,
  //     values: {
  //       ...referralPartner.values,
  //       [event.target.name]:
  //         event.target.type === "checkbox" ? event.target.checked : x,
  //     },
  //     touched: {
  //       ...referralPartner.touched,
  //       [event.target.name]: true,
  //     },
  //   }));
  // };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.addReferralPartner(referralPartner.values);
    setReferralPartner(initialFormState);
  };

  const hasError = (field) =>
    referralPartner.touched[field] && referralPartner.errors[field]
      ? true
      : false;

  return (
    <Card>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          disableTypography
          title={
            <Typography className={classes.cardHeaderTitle} type="body1">
              Create A New Referral Partner
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubHeaderTitle} type="body1">
              Only admins can manage referral partners
            </Typography>
          }
          className={classes.cardHeaderBackground}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError("partnerName")}
                fullWidth
                helperText={
                  hasError("partnerName")
                    ? referralPartner.errors.partnerName[0]
                    : null
                }
                label="Referral Partner Name"
                margin="dense"
                name="partnerName"
                onChange={handleInputChange}
                required
                type="text"
                value={referralPartner.values.partnerName || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("partnerAbbr")}
                fullWidth
                helperText={
                  hasError("partnerAbbr")
                    ? referralPartner.errors.partnerAbbr[0]
                    : null
                }
                label="Referral Partner Abbreviation"
                margin="dense"
                name="partnerAbbr"
                onChange={handleInputChange}
                required
                type="text"
                value={referralPartner.values.partnerAbbr || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("partnerUrl")}
                fullWidth
                helperText={
                  hasError("partnerUrl")
                    ? referralPartner.errors.partnerUrl[0]
                    : null
                }
                label="Partner Website"
                margin="dense"
                name="partnerUrl"
                onChange={handleInputChange}
                required
                type="text"
                value={referralPartner.values.partnerUrl || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("city")}
                fullWidth
                helperText={
                  hasError("city") ? referralPartner.errors.city[0] : null
                }
                label="City"
                margin="dense"
                name="city"
                onChange={handleInputChange}
                required
                type="text"
                value={referralPartner.values.city || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="State"
                margin="dense"
                name="stateAbbr"
                onChange={handleInputChange}
                required
                select
                type="text"
                value={referralPartner.values.stateAbbr || ""}
                variant="outlined"
              >
                {states.map((stateAbbr) => (
                  <MenuItem key={stateAbbr.Code} value={stateAbbr.Code}>
                    {stateAbbr.State}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                className={classes.prompt}
                align="center"
                variant="h4"
              >
                Partner Admin User
              </Typography>
              <Typography
                className={classes.prompt}
                align="center"
                variant="subtitle1"
              >
                This user will receive an invite to create other users
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("first_name")}
                fullWidth
                helperText={
                  hasError("first_name")
                    ? referralPartner.errors.first_name[0]
                    : null
                }
                label="First Name"
                margin="dense"
                name="first_name"
                onChange={handleInputChange}
                required
                type="text"
                value={referralPartner.values.first_name || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("last_name")}
                fullWidth
                helperText={
                  hasError("last_name")
                    ? referralPartner.errors.last_name[0]
                    : null
                }
                label="Last Name"
                margin="dense"
                name="last_name"
                onChange={handleInputChange}
                required
                type="text"
                value={referralPartner.values.last_name || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("mobile_phone")}
                fullWidth
                helperText={
                  hasError("mobile_phone")
                    ? referralPartner.errors.mobile_phone[0]
                    : null
                }
                inputProps={{ maxLength: 12 }}
                label="Mobile Phone"
                margin="dense"
                name="mobile_phone"
                onChange={handlePhoneChange}
                required
                type="text"
                value={referralPartner.values.mobile_phone || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("email")}
                fullWidth
                helperText={
                  hasError("email") ? referralPartner.errors.email[0] : null
                }
                label="Email"
                margin="dense"
                name="email"
                onChange={handleInputChange}
                required
                type="text"
                value={referralPartner.values.email.toLowerCase() || ""}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!referralPartner.isValid}
            type="submit"
            variant="contained"
          >
            Save Referral Partner
          </Button>
          <Button
            color="primary"
            onClick={() => props.showAddReferralPartner()}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AddReferralForm;
