import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },
  header: {
    color: "#FFFFFF",
    textAlign: "left",
    padding: "1rem",
    fontSize: "1rem",
    fontWeight: 600,
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

const ReferralTable = (props) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {props && props.role <= 2 && (
              <TableCell className={classes.header}>Edit / Archive</TableCell>
            )}
            <TableCell className={classes.header}>Partner</TableCell>
            <TableCell className={classes.header}>Abbr</TableCell>
            <TableCell className={classes.header}>City</TableCell>
            <TableCell className={classes.header}>State</TableCell>
            <TableCell className={classes.header}>Admin</TableCell>
            <TableCell className={classes.header}>Email</TableCell>
            <TableCell className={classes.header}>Password Created</TableCell>
            <TableCell className={classes.header}>Invite Sent</TableCell>
            <TableCell className={classes.header}>Resend Invite</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.referralPartners && props.referralPartners.length > 0 ? (
            props.referralPartners.map((referralPartner, index) => (
              <TableRow key={index} className={classes.tableRow}>
                {props && props.role <= 2 && (
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        props.editRow(referralPartner);
                      }}
                      type="submit"
                    >
                      <EditIcon />
                    </IconButton>
                    {props.currentUserId !== referralPartner.partnerid ? (
                      <IconButton
                        color="inherit"
                        onClick={() =>
                          props.deleteReferralPartner(referralPartner.partnerid)
                        }
                        type="submit"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </TableCell>
                )}

                <TableCell>
                  <input type="hidden" value={referralPartner.partnerid} />
                  {referralPartner.partnername}
                </TableCell>
                <TableCell>{referralPartner.partnerabbr}</TableCell>
                <TableCell>{referralPartner.city}</TableCell>
                <TableCell>{referralPartner.stateabbr}</TableCell>
                <TableCell>
                  {`${referralPartner.first_name} ${referralPartner.last_name}`}
                </TableCell>
                <TableCell>{referralPartner.email}</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {referralPartner.pw ? (
                    <Tooltip title="Account ready!">
                      <CheckCircleOutlineIcon className={classes.success} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Password not set">
                      <ErrorOutlineIcon className={classes.warning} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {new Date(referralPartner.created_at).toLocaleDateString(
                    "EN-US"
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {!referralPartner.pw && (
                    <Tooltip title="Resend Invite">
                      <IconButton
                        aria-label="resend-invite"
                        color="primary"
                        onClick={() => {
                          props.resendInvite(
                            referralPartner.id,
                            referralPartner.email,
                            referralPartner.first_name
                          );
                        }}
                        type="submit"
                      >
                        <SendIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                Click above to add referral partners!
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferralTable;
