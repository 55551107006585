import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../../SocketContext";
import { ChatContext } from "../../../ChatContext";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Typography,
  Grid,
  List,
  Avatar,
  colors,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  InputAdornment,
} from "@material-ui/core";

import SendIcon from "@material-ui/icons/Send";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LockIcon from "@material-ui/icons/Lock";
import ChatMessages from "./PatientChatMessages";
import { ApptReqContext } from "../../../ApptReqContext";

const useStyles = makeStyles((theme) => ({
  messageArea: {
    overflowY: "auto",
    background: theme.palette.background.paper,
  },
  messageInput: {
    margin: 0,
    padding: 0,
    fontSize: "100%",
  },
  cardActions: {
    background: theme.palette.background.default,
  },
  cardHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  sendButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

// let socket = null;

const PatientChat = ({ canText }) => {
  const classes = useStyles();

  const { oQues } = useContext(ApptReqContext);

  const socket = useContext(SocketContext);
  const currentUser = oQues.question[8].choices[2].answer || "Patient";
  const userId = oQues.rky;

  const [name, setName] = useState("");
  const [room, setRoom] = useState();
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");
  const [chatSessionId, setChatSessionId] = useState();

  const { messages, setMessages } = useContext(ChatContext);

  useEffect(() => {
    const sessionId = oQues.rqid;
    setChatSessionId(() => sessionId);
  }, [oQues]);

  useEffect(() => {
    const name = currentUser;
    const room = parseInt(chatSessionId);

    if (name && room) {
      setRoom(room);
      setName(name);

      socket.emit("join", { name, room, userId }, (error) => {
        if (error) {
          alert(error);
        }
      });
    }

    /** reconnect if socket is disconnected */
    socket.on("disconnect", () => {
      // connect fires when connected, also after reconnected
      socket.on("connect", () => {
        // console.log("connect", socket.connected);
        // automatically join the room
        if (name && room) {
          setRoom(room);
          setName(name);

          socket.emit("join", { name, room, userId }, (error) => {
            if (error) {
              alert(error);
            }
          });
        }
      });
    });

    return () => {
      // socket.removeAllListeners();
      const updatedMessages = messages.filter(
        (message) => parseInt(message.room) !== room
      );
      setMessages(() => updatedMessages);
    };
    // eslint-disable-next-line
  }, [chatSessionId, socket, userId, currentUser]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages((messages) => [...messages, message]);
    });

    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [socket, setMessages]);

  const sendMessage = (event) => {
    event.preventDefault();

    const isPatientMsg = true;

    if (message) {
      socket.emit("sendMessage", {
        message,
        chatSessionId,
        userId,
        isPatientMsg,
      });
      setMessage("");
    }
  };

  return (
    <Card elevation={0}>
      <CardHeader
        // classes={{
        //   title: classes.cardHeaderTitle,
        //   subheader: classes.cardHeaderTitle,
        // }}
        className={classes.cardHeaderBackground}
        avatar={
          <Avatar
            aria-label="recipe"
            style={{ backgroundColor: colors.green[600] }}
          >
            <LockIcon />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        disableTypography
        title={
          <Typography
            className={classes.cardHeaderTitle}
            type="body1"
            style={{ color: "#FFFFFF" }}
          >
            {oQues && oQues.location}
          </Typography>
        }
        subheader={
          users
            ? users.map(({ name }, i) => (
                <Typography
                  className={classes.cardHeaderTitle}
                  type="body1"
                  style={{ color: "#FFFFFF" }}
                  key={i}
                >
                  {name === currentUser ? "" : name + " (Scheduling Team)"}
                </Typography>
              ))
            : null
        }
      />
      <CardContent className={classes.messageArea}>
        <Box height={"50vh"} position="relative">
          <div>
            <List className={classes.messageArea}>
              <ChatMessages
                messages={messages}
                name={name}
                chatSessionId={chatSessionId}
              />
            </List>
          </div>
        </Box>
      </CardContent>
      <CardActions className={classes.cardActions} disableSpacing>
        <Grid container spacing={0}>
          <Grid item md={12} xs={12}>
            <Box display="flex" justifyContent="flex-start">
              <TextField
                className={classes.messageInput}
                fullWidth
                onChange={({ target: { value } }) => setMessage(value)}
                inputProps={{
                  maxLength: 1000,
                }}
                label="Type your message here"
                onKeyPress={(event) =>
                  event.key === "Enter" ? sendMessage(event) : null
                }
                disabled={
                  messages &&
                  !messages.find((msg) => {
                    return msg.from === "agent";
                  })
                }
                margin="dense"
                multiline
                name="message"
                required
                value={message}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => sendMessage(e)}
                        color="primary"
                        border={0}
                        className={classes.sendButton}
                        disabled={
                          messages &&
                          !messages.find((msg) => {
                            return msg.from === "agent";
                          })
                        }
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default PatientChat;
