import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import validate from "validate.js";

const schema = {
  archiveReason: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  cardHeaderTitle: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 600,
  },
  cardSubHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const AddArchiveReason = (props) => {
  const classes = useStyles();

  const initialFormState = {
    isValid: false,
    values: {
      archiveReason: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  };

  const [archiveReason, setArchiveReason] = useState(initialFormState);

  useEffect(() => {
    const errors = validate(archiveReason.values, schema);

    setArchiveReason((archiveReason) => ({
      ...archiveReason,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [archiveReason.values]);

  const handleInputChange = (event) => {
    event.persist();
    console.log(archiveReason);

    setArchiveReason((archiveReason) => ({
      ...archiveReason,
      values: {
        ...archiveReason.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...archiveReason.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.addArchiveReason(archiveReason.values);
    setArchiveReason(initialFormState);
  };

  const hasError = (field) =>
    archiveReason.touched[field] && archiveReason.errors[field] ? true : false;

  return (
    <Card>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          disableTypography
          title={
            <Typography className={classes.cardHeaderTitle} type="body1">
              Create A New Archive Reason
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubHeaderTitle} type="body1">
              Only admins can manage archive reasons
            </Typography>
          }
          className={classes.cardHeaderBackground}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError("archiveReason")}
                fullWidth
                helperText={
                  hasError("archiveReason")
                    ? archiveReason.errors.archiveReason[0]
                    : null
                }
                label="Enter Archive Reason"
                margin="dense"
                name="archiveReason"
                onChange={handleInputChange}
                required
                type="text"
                value={archiveReason.values.archiveReason || ""}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!archiveReason.isValid}
            type="submit"
            variant="contained"
          >
            Create Archive Reason
          </Button>
          <Button
            color="primary"
            onClick={() => props.showAddArchiveReasonForm()}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AddArchiveReason;
