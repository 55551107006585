import React, { useContext } from "react";
import { ApptReqContext } from "../../../ApptReqContext";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, ListItemText } from "@material-ui/core";

import "./PatientChatMessage.css";
import ReactEmoji from "react-emoji";

const useStyles = makeStyles((theme) => ({
  messageArea: {
    overflowY: "auto",
    background: theme.palette.background.default,
  },
  messageInput: {
    marginTop: "40px",
  },
  systemMessage: {
    border: "0.5px dashed gray",
    borderRadius: "15px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    float: "right",
    background: "white",
  },
  systemMessageNote: {
    marginTop: 0,
    padding: "2px",
    display: "inline-block",
    float: "right",
  },
  systemMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "right",
  },
  outboundMessage: {
    borderRadius: "15px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    float: "right",
    background: theme.palette.primary.main,
  },
  outboundMessageNote: {
    marginTop: 0,
    padding: "2px",
    display: "inline-block",
    float: "right",
  },
  outboundMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "right",
  },
  inboundMessage: {
    borderRadius: "15px",
    margin: "3px",
    padding: "15px",
    display: "inline-block",
    background: theme.palette.secondary.main,
  },
  inboundMessageNote: {
    marginTop: 0,
    padding: "2px",
    display: "inline-block",
  },
  inboundMessageAvatar: {
    marginTop: 0,
    padding: "10px 0 0 10px",
    display: "inline-block",
    float: "left",
  },
  cardActions: {
    marginTop: 0,
    paddingTop: 0,
    marginRight: "20px",
    marginLeft: "20px",
    marginBottom: "20px",
  },
  cardHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Message = ({
  message: { text, user, msgDate, from, room, isRead, msgUserId, isNote },
  name,
}) => {
  const { oQues } = useContext(ApptReqContext);

  const userRky = oQues.rky;

  const classes = useStyles();
  let isSentByAgent = false;
  let isSentBySystem = false;

  switch (from) {
    case "agent":
      isSentByAgent = true;
      break;
    case "System":
      isSentBySystem = true;
      break;
    default:
      break;
  }

  const getFormattedDate = (rawDate) => {
    const formattedRawDate = new Date(rawDate);
    const updatedDate = formattedRawDate.toLocaleDateString("en-US");
    const updatedTime = formattedRawDate.toLocaleTimeString("en-US");

    return `${updatedTime} on ${updatedDate}`;
  };

  return (
    !isNote &&
    (isSentByAgent && !isNote ? (
      <Grid display="flex" container>
        <Grid item xs={12}>
          {/* <ListItemIcon
          className={
            userRky === msgUserId
              ? classes.systemMessageAvatar
              : classes.inboundMessageAvatar
          }
        >
          <Avatar>
            <LocalHospitalIcon />
          </Avatar>
        </ListItemIcon> */}
          <ListItemText
            // style={{ marginLeft: "60px" }}
            disableTypography
            primary={
              <Typography
                className={
                  userRky === msgUserId
                    ? classes.outboundMessage
                    : classes.inboundMessage
                }
                type="body1"
                style={{ color: "#FFFFFF" }}
              >
                {ReactEmoji.emojify(text)}
              </Typography>
            }
            align={userRky === msgUserId ? "right" : "left"}
          ></ListItemText>
        </Grid>
        <Grid item xs={12}>
          <ListItemText
            align="right"
            secondary={`Scheduler - ${getFormattedDate(msgDate)}`}
            className={
              userRky === msgUserId
                ? classes.outboundMessageNote
                : classes.inboundMessageNote
            }
          ></ListItemText>
        </Grid>
      </Grid>
    ) : isSentBySystem && !isNote && user === "SystemOne" ? (
      text.substring(0, 20) !== "Marked as Archived -" &&
      text.substring(0, 19) !== "Marked as Pending -" &&
      from !== "agent" ? (
        <Grid container display="flex">
          <Grid item xs={12}>
            {/* <ListItemIcon className={classes.systemMessageAvatar}>
          <Avatar>
            <AdbIcon />
          </Avatar>
        </ListItemIcon> */}
            <ListItemText
              align="right"
              // style={{ marginRight: "20px" }}
              primary={ReactEmoji.emojify(text)}
              className={classes.systemMessage}
            ></ListItemText>
          </Grid>
          <Grid item xs={12}>
            <ListItemText
              align="right"
              secondary={`${from} - ${getFormattedDate(msgDate)}`}
              className={classes.systemMessageNote}
            ></ListItemText>
          </Grid>
        </Grid>
      ) : (
        <></>
      )
    ) : (
      !isNote && (
        <Grid display="flex" container>
          <Grid item xs={12}>
            {/* <ListItemIcon className={classes.outboundMessageAvatar}>
        <Avatar>ME</Avatar>
      </ListItemIcon> */}
            <ListItemText
              // style={{ marginRight: "70px" }}
              disableTypography
              align="left"
              type="body1"
              primary={
                <Typography
                  className={classes.outboundMessage}
                  type="body1"
                  style={{ color: "#FFFFFF" }}
                >
                  {ReactEmoji.emojify(text)}
                </Typography>
              }
            ></ListItemText>
          </Grid>
          <Grid item xs={12}>
            <ListItemText
              align="left"
              // style={{ marginRight: "70px" }}
              secondary={`Me - ${getFormattedDate(msgDate)}`}
              className={classes.outboundMessageNote}
            ></ListItemText>
          </Grid>
        </Grid>
      )
    ))
  );
};

export default Message;
