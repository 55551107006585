import React, { useState, useEffect, useContext } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Button,
  Grid,
  Typography,
  colors,
  Container,
  Paper,
} from "@material-ui/core";
import { ApptReqContext } from "../../../ApptReqContext";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  yourName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
      message: "is required in the format 123-456-7890",
    },
    length: {
      minimum: 12,
    },
    format: {
      pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
      flags: "i",
      message: "is required in the format 123-456-7890",
    },
  },
  city: {
    presence: true,
    length: {
      minimum: 2,
      maximum: 25,
    },
    format: {
      pattern: "[a-z ]+",
      flags: "i",
      message: "can only contain a-z",
    },
  },
  zip: {
    presence: true,
    length: {
      minimum: 5,
      maximum: 5,
    },
    format: {
      pattern: "[0-9]+",
      flags: "i",
      message: "can only contain 0-9",
    },
  },
  // birthDate: {
  //   datetime: {
  //     dateOnly: true,
  //     message: "must be in format MM/DD/YYYY",
  //   },
  // },
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    margin: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(1, 0),
  },
  listButton: {
    margin: theme.spacing(0, 0),
  },
  resMessage: {
    marginTop: theme.spacing(1),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  prompt: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  selectedOption: {
    color: colors.green[600],
  },
  dayChoiceHeading: {
    flexGrow: 1,
    textAlign: "center",
    fontWeight: 700,
    fontSize: "1.25rem",
    padding: theme.spacing(0),
  },
}));

const PatientPreferences = (props) => {
  const classes = useStyles();

  const { oQues } = useContext(ApptReqContext);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isLoading: false,
  });

  const [preferredProvider, setPreferredProvider] = useState();
  const [selectedProvider, setSelectedProvider] = useState();
  const [preferredLoc, setPreferredLoc] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [preferredTime, setPreferredTime] = useState();
  const [updatePreferredTimes, setUpdatePreferredTimes] = useState(false);
  const [showPreferredTimes, setShowPreferredTimes] = useState(false);

  useEffect(() => {
    let unmounted = false;

    const configProviderRequest = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        ky: oQues.rky,
        sl: 0,
      }),
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ivr.nextdrvisit.com/appt/providersjson",
          configProviderRequest
        );

        const json = await response.json();
        const obj = JSON.parse(json);

        Object.entries(obj.doc).map(([key, doc]) => {
          if (doc.prid === obj.prid) {
            if (!unmounted) {
              setSelectedProvider(() => doc.name);
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      unmounted = true;
    };
  }, [oQues.rky]);

  const handleProviderRequest = async (rky) => {
    const configProviderRequest = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        ky: rky,
        sl: 0,
      }),
    };

    try {
      const response = await fetch(
        "https://ivr.nextdrvisit.com/appt/providersjson",
        configProviderRequest
      );

      const json = await response.json();
      const obj = JSON.parse(json);

      setPreferredProvider(() => obj);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let unmounted = false;

    const configPreferredLocation = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        ky: oQues.rky,
        rq: -1,
      }),
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ivr.nextdrvisit.com/appt/sitesjson",
          configPreferredLocation
        );

        const json = await response.json();
        const obj = JSON.parse(json);

        Object.entries(obj.loc).map(([key, loc]) => {
          if (loc.siid === obj.siid) {
            if (!unmounted) {
              setSelectedLocation(() => loc.name);
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      unmounted = true;
    };
  }, [oQues.rky]);

  const handlePreferredLocation = async (rky) => {
    const configProviderRequest = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        ky: rky,
        rq: -1,
      }),
    };

    try {
      const response = await fetch(
        "https://ivr.nextdrvisit.com/appt/sitesjson",
        configProviderRequest
      );

      const json = await response.json();
      const obj = JSON.parse(json);

      setPreferredLoc(() => obj);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let unmounted = false;

    const configPreferredTimes = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        ky: oQues.rky,
        rq: -1,
      }),
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ivr.nextdrvisit.com/appt/schedtodjson",
          configPreferredTimes
        );

        const json = await response.json();
        const obj = await JSON.parse(json);

        // setPreferredTime((prevState) => ({
        //   ...prevState,
        //   obj,
        // }));
        if (!unmounted) {
          setPreferredTime(obj);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      unmounted = true;
    };
  }, [updatePreferredTimes, oQues.rky]);

  /** display preferred time options when user selects */
  const handlePreferredTime = async (rky) => {
    const configTimeRequest = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
      },
      body: JSON.stringify({
        ky: rky,
        rq: -1,
      }),
    };

    try {
      const response = await fetch(
        "https://ivr.nextdrvisit.com/appt/schedtodjson",
        configTimeRequest
      );

      const json = await response.json();

      const obj = await JSON.parse(json);

      setPreferredTime(obj);
    } catch (error) {
      console.error(error);
    }
  };

  /** update time choices when user clicks */
  const displayTimeChoicesDay = (dayId) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          cursor: "pointer",
          padding: "0.35rem",
          margin: 0,
          borderRadius: "3px",
          border: "1px solid black",
          color: "#ffffff",
          width: "100%",
          backgroundColor:
            preferredTime.tod[dayId].value === 1 ? "green" : "red",
        }}
        onClick={() => {
          props.onClick(
            oQues.rky,
            preferredTime.tod[dayId].name,
            preferredTime.tod[dayId].value === 1 ? "0" : "1",
            -1,
            ""
          );
          setUpdatePreferredTimes(
            (updatePreferredTimes) => !updatePreferredTimes
          );
          // setPreferredTime({
          //   ...preferredTime,
          //   [preferredTime.tod[dayId].name]:
          //     preferredTime.tod[dayId].value === 1 ? "0" : "1",
          // });
        }}
      >
        {preferredTime.tod[dayId].value === 1 ? (
          <CheckCircleOutlineIcon />
        ) : (
          <NotInterestedIcon />
        )}
      </div>
    );
  };

  const displayTimeChoices = (day, first, second, third, fourth) => {
    const weekDays = {
      0: "Monday",
      1: "Tuesday",
      2: "Wednesday",
      3: "Thursday",
      4: "Friday",
      5: "Saturday",
    };

    return (
      <div>
        <div
          style={{
            paddingRight: "3rem",
            fontWeight: 700,
            fontSize: "1rem",
          }}
        >
          {weekDays[day]}
        </div>
        <div style={{ display: "flex" }}>
          {displayTimeChoicesDay(first)}
          {displayTimeChoicesDay(second)}
          {displayTimeChoicesDay(third)}
          {displayTimeChoicesDay(fourth)}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.contentBody}>
      <Card className={classes.cardBg} variant="outlined">
        <CardContent>
          <Typography className={classes.title} align="center" variant="h1">
            {oQues && oQues.location}
          </Typography>
          <Typography
            className={classes.linkExpired}
            variant="h4"
            align="center"
          >
            Choose your appointment preferences below, or leave them blank to
            let our staff choose for you.
          </Typography>
          <Grid className={classes.grid} spacing={1} container>
            <Button
              className={classes.submitButton}
              color="primary"
              disabled={formState.isLoading}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              onClick={() => handleProviderRequest(oQues.rky)}
            >
              Choose Preferred Provider <br />
              <span style={{ textTransform: "capitalize" }}>
                {selectedProvider && `: ${selectedProvider}`}
              </span>
            </Button>
            {preferredProvider &&
              Object.entries(preferredProvider.doc).map(([key, doc]) => (
                <Grid key={key} item md={12} xs={12}>
                  <Button
                    className={classes.listButton}
                    onClick={() => {
                      props.onClick(
                        oQues.rky,
                        "reqproviderid",
                        doc.prid.toString(),
                        -1,
                        ""
                      );
                      setSelectedProvider(() => doc.name);
                      setPreferredProvider(() => {});
                    }}
                    color="primary"
                    type="submit"
                    fullWidth
                    size="large"
                    variant="text"
                    startIcon={
                      preferredProvider.prid === doc.prid ? (
                        <CheckCircleOutlineIcon
                          className={classes.selectedOption}
                        />
                      ) : (
                        ""
                      )
                    }
                  >
                    {doc.name}
                  </Button>
                </Grid>
              ))}
            <Button
              className={classes.submitButton}
              color="primary"
              disabled={formState.isLoading}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              onClick={() => handlePreferredLocation(oQues.rky)}
            >
              Choose Preferred Location <br />
              <span style={{ textTransform: "capitalize" }}>
                {selectedLocation && `: ${selectedLocation}`}
              </span>
            </Button>
            {preferredLoc &&
              Object.entries(preferredLoc.loc).map(([key, loc]) => (
                <Grid key={key} item md={12} xs={12}>
                  <Button
                    className={classes.listButton}
                    onClick={() => {
                      props.onClick(
                        oQues.rky,
                        "reqsiteid",
                        loc.siid.toString(),
                        -1,
                        ""
                      );
                      setSelectedLocation(() => loc.name);
                      setPreferredLoc(() => {});
                    }}
                    color="primary"
                    type="submit"
                    fullWidth
                    size="large"
                    variant="text"
                    startIcon={
                      preferredLoc.siid === loc.siid ? (
                        <CheckCircleOutlineIcon
                          className={classes.selectedOption}
                        />
                      ) : (
                        ""
                      )
                    }
                  >
                    {loc.name}
                  </Button>
                </Grid>
              ))}
            <Button
              className={classes.submitButton}
              color="primary"
              disabled={formState.isLoading}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              onClick={() => {
                handlePreferredTime(oQues.rky);
                setShowPreferredTimes(
                  (showPreferredTimes) => !showPreferredTimes
                );
              }}
            >
              Choose Preferred Appointment Days/Times
            </Button>
            {preferredTime && showPreferredTimes && (
              <Grid item md={12} xs={12}>
                <Container component={Paper} style={{ paddingBottom: "1rem" }}>
                  <div style={{ display: "flex" }}>
                    <div className={classes.dayChoiceHeading}>Morning</div>
                    <div className={classes.dayChoiceHeading}>Afternoon</div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className={classes.dayChoiceHeading}>Early</div>
                    <div className={classes.dayChoiceHeading}>Late</div>
                    <div className={classes.dayChoiceHeading}>Early</div>
                    <div className={classes.dayChoiceHeading}>Late</div>
                  </div>
                  {displayTimeChoices(0, 0, 1, 2, 3)}
                  {displayTimeChoices(1, 4, 5, 6, 7)}
                  {displayTimeChoices(2, 8, 9, 10, 11)}
                  {displayTimeChoices(3, 12, 13, 14, 15)}
                  {displayTimeChoices(4, 16, 17, 18, 19)}
                  {displayTimeChoices(5, 20, 21, 22, 23)}
                </Container>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

PatientPreferences.propTypes = {
  className: PropTypes.string,
};

export default PatientPreferences;
