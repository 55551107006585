import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  makeStyles,
} from "@material-ui/core";
import validate from "validate.js";

const schema = {
  pendingReason: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  cardHeaderTitle: {
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 600,
  },
  cardSubHeaderTitle: {
    color: "#FFFFFF",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const EditPendingReasonForm = (props) => {
  const classes = useStyles();

  const initialFormState = {
    isValid: false,
    values: {
      pendingReason: "",
      pendingReasonId: null,
      isActive: null,
    },
    touched: {},
    errors: {},
    isLoading: false,
  };

  const [pendingReason, setPendingReason] = useState(
    props.currentPendingReason
  );

  useEffect(() => {
    setPendingReason(props.currentPendingReason);
  }, [props]);

  useEffect(() => {
    const errors = validate(pendingReason.values, schema);

    setPendingReason((user) => ({
      ...user,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [pendingReason.values]);

  const handleInputChange = (event) => {
    event.persist();

    setPendingReason((user) => ({
      ...user,
      values: {
        ...pendingReason.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...pendingReason.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.updatePendingReason(
      pendingReason.values.pendingReasonId,
      pendingReason
    );
  };

  const hasError = (field) =>
    pendingReason.touched[field] && pendingReason.errors[field] ? true : false;

  return (
    <Card>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          subheader="Update pending reason details below"
          title="Edit Pending Reason"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError("pendingReason")}
                fullWidth
                helperText={
                  hasError("pendingReason")
                    ? pendingReason.errors.pendingReason[0]
                    : null
                }
                label="Enter Pending Reason"
                margin="dense"
                name="pendingReason"
                onChange={handleInputChange}
                required
                type="text"
                value={pendingReason.values.pendingReason || ""}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!pendingReason.isValid || props.loading}
            type="submit"
            variant="contained"
          >
            Update Pending Reason
          </Button>
          <Button
            color="primary"
            onClick={() => props.setEditing(false)}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default EditPendingReasonForm;
