import React from "react";

import {
  Card,
  CardContent,
  Typography,
  colors,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  pendingBackground: {
    backgroundColor: colors.yellow[600],
    minWidth: 275,
    width: "99%",
    marginTop: "10px",
    borderWidth: "5px",
  },
  archivedBackground: {
    minWidth: 275,
    width: "99%",
    marginTop: "10px",
    borderWidth: "5px",
    margingRight: "200px",
  },
  title: {
    fontSize: 14,
    fontWeight: 900,
  },
  pos: {
    marginBottom: 12,
  },
});

const ReqStatusReason = (props) => {
  const classes = useStyles();

  const formattedDate = () => {
    const updatedOn = props.updatedOn;
    const formatUpdatedOn = new Date(updatedOn);
    const updatedDate = formatUpdatedOn.toLocaleDateString("en-US");
    const updatedTime = formatUpdatedOn.toLocaleTimeString("en-US");

    return `Last update at ${updatedTime} on ${updatedDate}`;
  };

  return (
    <Card
      className={
        props.status === 2
          ? classes.pendingBackground
          : classes.archivedBackground
      }
      variant="outlined"
    >
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Status - {props.status === 2 ? "Pending" : "Archived"}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {formattedDate()}
        </Typography>
        <Typography variant="body1" component="p">
          {props.statusReason}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReqStatusReason;
