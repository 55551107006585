import React, { useEffect, useRef } from "react";
import { ListItem } from "@material-ui/core";

import Message from "./PatientChatMessage";

import "./PatientChatMessages.css";

const ChatMessages = ({ messages, name, chatSessionId }) => {
  var filteredMessages = null;

  if (messages) {
    filteredMessages = messages.filter(
      (message) => message.room === parseInt(chatSessionId)
    );
  }

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [filteredMessages]);

  return (
    <>
      <div className="messages">
        {filteredMessages
          ? filteredMessages.map((filteredMessage, i) => (
              <ListItem key={i}>
                <Message
                  message={filteredMessage}
                  name={name}
                  chatSessionId={chatSessionId}
                />
              </ListItem>
            ))
          : []}
        <div ref={messagesEndRef} />
      </div>
    </>
  );
};

export default ChatMessages;
