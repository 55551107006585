import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { UserContext } from "../../../UserContext";
import { SocketContext } from "../../../SocketContext";

const SetPendingNote = (props) => {
  const socket = useContext(SocketContext);
  const [open, setOpen] = useState(false);
  const [statusReason, setStatusReason] = useState("");
  const { user } = useContext(UserContext);

  const handleChange = (event) => {
    setStatusReason(() => event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    setOpen(false);
    const message = `Note added by ${user.username} - ${statusReason}`;
    const chatSessionId = props.apptRequestId;
    const userId = props.userId;
    const isPatientMsg = false;
    const isNote = true;

    try {
      socket.emit("sendMessage", {
        message,
        chatSessionId,
        userId,
        isPatientMsg,
        isNote,
      });
      setStatusReason("");
    } catch (error) {
      console.error(error);
    }

    // props.updateCurrent(); // update request detail
  };

  return (
    <>
      <Tooltip title={`Add note`} placement="left-start" arrow>
        <IconButton color="inherit" onClick={handleClickOpen}>
          <NoteAddIcon fontSize="large" color="primary" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <span style={{ fontWeight: 900 }}>Add Note</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your notes below. Notes are not visible to patients.
          </DialogContentText>
          <TextField
            autoFocus
            onChange={handleChange}
            margin="dense"
            id="statusReason"
            label="My note..."
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            size="large"
            variant="text"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetPendingNote;
