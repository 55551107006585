import React, { useState, useEffect, useContext, useRef } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  TextField,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";
import { ApptReqContext } from "../../../ApptReqContext";
import DateRangeIcon from "@material-ui/icons/DateRange";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  grid: {
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  cardBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: "2px",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    paddingTop: 50,
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
  },
  input: {
    background: "white",
  },
  textField: {
    margin: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(4, 0),
  },
  resMessage: {
    marginTop: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  linkExpired: {
    paddingTop: theme.spacing(4),
  },
  prompt: {
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(2),
  },
}));

const PatientInformation = (props) => {
  const classes = useStyles();

  const { oQues, wwConfig, setPatientInfo } = useContext(ApptReqContext);

  const schema = {
    FirstName: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 64,
      },
    },
    PrimaryPhone: {
      presence: {
        allowEmpty: false,
        message: "is required in the format 123-456-7890",
      },
      length: {
        minimum: 12,
      },
      format: {
        pattern: "^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
        flags: "i",
        message: "is required in the format 123-456-7890",
      },
    },
    PrimaryExtension: {
      length: {
        maximum: 12,
      },
    },
    PatientFirstName: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 128,
      },
    },
    LastName: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 64,
      },
    },
    BirthDate: {
      presence:
        wwConfig && wwConfig[4].isVisible && wwConfig[4].isRequired
          ? { allowEmpty: false, message: "is required" }
          : true,
    },
    ZipCode: {
      presence:
        wwConfig && wwConfig[5].isVisible
          ? wwConfig[5].isRequired
            ? { allowEmpty: false, message: "is required" }
            : true
          : false,
      length: {
        minimum:
          wwConfig && wwConfig[5].isVisible && wwConfig[5].isRequired ? 5 : 0,
        maximum: 5,
      },
      format: {
        pattern: "[0-9]+",
        flags: "i",
        message: "can only contain 0-9",
      },
    },
    StreetAddress: {
      presence:
        wwConfig && wwConfig[6].isVisible
          ? wwConfig[6].isRequired
            ? { allowEmpty: false, message: "is required" }
            : true
          : false,
      length: {
        minimum:
          wwConfig && wwConfig[6].isVisible && wwConfig[6].isRequired ? 2 : 0,
        maximum: 150,
      },
    },
    SSN: {
      presence:
        wwConfig && wwConfig[7].isVisible
          ? wwConfig[7].isRequired
            ? {
                allowEmpty: false,
                message: "is required in the format 123-45-6789",
              }
            : true
          : false,
      length: {
        minimum:
          wwConfig && wwConfig[7].isVisible && wwConfig[7].isRequired ? 11 : 0,
        maximum: 11,
      },
    },
    EmailAddress: {
      presence:
        wwConfig && wwConfig[8].isVisible
          ? wwConfig[8].isRequired
            ? {
                allowEmpty: false,
                message: "is required",
              }
            : true
          : false,
      length: {
        minimum:
          wwConfig && wwConfig[8].isVisible && wwConfig[8].isRequired ? 5 : 0,
        maximum: 75,
      },
    },
    InsuranceCompany: {
      presence:
        wwConfig && wwConfig[9].isVisible
          ? wwConfig[9].isRequired
            ? {
                allowEmpty: false,
                message: "is required",
              }
            : true
          : false,
      length: {
        minimum:
          wwConfig && wwConfig[9].isVisible && wwConfig[9].isRequired ? 1 : 0,
        maximum: 75,
      },
    },
    InsurancePolicyNumber: {
      presence:
        wwConfig && wwConfig[10].isVisible
          ? wwConfig[10].isRequired
            ? {
                allowEmpty: false,
                message: "is required",
              }
            : true
          : false,
      length: {
        minimum:
          wwConfig && wwConfig[10].isVisible && wwConfig[10].isRequired ? 1 : 0,
        maximum: 75,
      },
    },
    NameOfInsured: {
      presence:
        wwConfig && wwConfig[11].isVisible
          ? wwConfig[11].isRequired
            ? {
                allowEmpty: false,
                message: "is required",
              }
            : true
          : false,
      length: {
        minimum:
          wwConfig && wwConfig[11].isVisible && wwConfig[11].isRequired ? 1 : 0,
        maximum: 75,
      },
    },
  };

  const focusedElement = useRef(null);

  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      FirstName: "",
      PatientFirstName: "",
      LastName: "",
      StreetAddress: "",
      ZipCode: "",
      BirthDate: null,
      PrimaryPhone: "",
      FormattedPrimaryPhone: "",
      PrimaryExtension: "",
      CanText: true,
      CanEmail: true,
      EmailAddress: "",
      SSN: "",
      InsuranceCompany: "",
      InsurancePolicyNumber: "",
      NameOfInsured: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  });

  const formatPrimaryPhone = (phone) => {
    let formattedPhone = "+1" + phone.replace(/-/g, "");
    return formattedPhone;
  };

  const formatPrimaryPhoneLocal = (phone) => {
    var shortPhone = phone.substring(2);

    var x = shortPhone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = shortPhone = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    return x;
  };

  useEffect(() => {
    if (typeof oQues !== "undefined") {
      setFormState((formState) => ({
        ...formState,
        values: {
          FirstName: !focusedElement.current
            ? oQues?.question?.[9]?.choices[1]?.answer
            : formState.values.FirstName,
          PatientFirstName: !focusedElement.current
            ? oQues?.question?.[9]?.choices[2]?.answer
            : formState.values.PatientFirstName,
          LastName: !focusedElement.current
            ? oQues?.question?.[9]?.choices[3]?.answer
            : formState.values.LastName,
          StreetAddress: !focusedElement.current
            ? oQues?.question?.[9]?.choices[4]?.answer
            : formState.values.StreetAddress,
          ZipCode: !focusedElement.current
            ? oQues?.question?.[9]?.choices[5]?.answer
            : formState.values.ZipCode,
          BirthDate: !focusedElement.current
            ? oQues?.question?.[9]?.choices[6]?.answer
            : formState.values.BirthDate,
          PrimaryPhone:
            !focusedElement.current && oQues?.question?.[9]?.choices[7]?.answer
              ? formatPrimaryPhoneLocal(oQues.question?.[9].choices[7].answer)
              : formState.values.PrimaryPhone || "",
          PrimaryExtension: !focusedElement.current
            ? oQues?.question?.[9]?.choices?.[8]?.answer
            : formState.values.PrimaryExtension,
          CanText:
            !focusedElement.current &&
            oQues?.question?.[9]?.choices[9]?.answer === "0"
              ? false
              : true,
          EmailAddress: !focusedElement.current
            ? oQues?.question?.[9]?.choices[10]?.answer
            : formState.values.EmailAddress,
          CanEmail:
            !focusedElement.current &&
            oQues?.question?.[9]?.choices[11]?.answer === "0"
              ? false
              : true,
          SSN: !focusedElement.current
            ? oQues?.question?.[9]?.choices[12]?.answer
            : formState.values.SSN,
          InsuranceCompany: !focusedElement.current
            ? oQues?.question?.[9]?.choices[13]?.answer
            : formState.values.InsuranceCompany,
          InsurancePolicyNumber: !focusedElement.current
            ? oQues?.question?.[9]?.choices[14]?.answer
            : formState.values.InsurancePolicyNumber,
          NameOfInsured: !focusedElement.current
            ? oQues?.question?.[9]?.choices[15]?.answer
            : formState.values.NameOfInsured,
        },
      }));
      setPatientInfo((formState) => ({
        ...formState,
        values: {
          FirstName: !focusedElement.current
            ? oQues?.question?.[9]?.choices[1]?.answer
            : formState.values.FirstName,
          PatientFirstName: !focusedElement.current
            ? oQues?.question?.[9]?.choices[2]?.answer
            : formState.values.PatientFirstName,
          LastName: !focusedElement.current
            ? oQues?.question?.[9]?.choices[3]?.answer
            : formState.values.LastName,
          StreetAddress: !focusedElement.current
            ? oQues?.question?.[9]?.choices[4]?.answer
            : formState.values.StreetAddress,
          ZipCode: !focusedElement.current
            ? oQues?.question?.[9]?.choices[5]?.answer
            : formState.values.ZipCode,
          PrimaryPhone:
            !focusedElement.current && oQues?.question?.[9]?.choices[7]?.answer
              ? formatPrimaryPhoneLocal(oQues.question?.[9].choices[7].answer)
              : formState.values.PrimaryPhone || "",
          EmailAddress: !focusedElement.current
            ? oQues?.question?.[9]?.choices[10]?.answer
            : formState.values.EmailAddress,
          SSN: !focusedElement.current
            ? oQues?.question?.[9]?.choices[12]?.answer
            : formState.values.SSN,
        },
      }));
    }
  }, [oQues]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    /** determine if Continue button is disabled */
    if (errors) {
      props.isInformationComplete(false);
    } else {
      props.isInformationComplete(true);
    }

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values, props]);

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    setPatientInfo((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    setPatientInfo((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
    }));
  };

  const handleSSNChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    setPatientInfo((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
    }));
  };

  const handleDateChange = (date) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        BirthDate: date,
      },
      touched: {
        ...formState.touched,
        BirthDate: true,
      },
    }));
    props.onClick(oQues.rky, "BirthDate", date, -1, "");
  };

  const handleDateBlur = (date) => {
    // props.onClick(oQues.rky, "BirthDate", date, -1, "");

    // props.onClick(oQues.rky, "BirthDate", formState.values.BirthDate, -1, "");
    // setFormState((formState) => ({
    //   ...formState,
    //   values: {
    //     ...formState.values,
    //     BirthDate: date.target.value,
    //   },
    // }));
    props.onClick(oQues.rky, "BirthDate", formState.values.BirthDate, -1, "");
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    focusedElement.current = document.activeElement.name;
  });

  return (
    <div className={classes.contentBody}>
      <Card className={classes.cardBg} variant="outlined">
        <form>
          <CardContent>
            <Typography className={classes.title} align="center" variant="h1">
              {oQues && oQues.location}
            </Typography>
            <Typography className={classes.prompt} align="center" variant="h4">
              Appointment Request Form
            </Typography>
            <Grid className={classes.grid} spacing={2} container>
              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError("FirstName")}
                  required
                  fullWidth
                  helperText={
                    hasError("FirstName") ? formState.errors.FirstName[0] : null
                  }
                  label="Your First Name"
                  name="FirstName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.FirstName || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onBlur={() =>
                    props.onClick(
                      oQues.rky,
                      "FirstName",
                      formState.values.FirstName,
                      -1,
                      ""
                    )
                  }
                />
              </Grid>

              <Grid item md={3} xs={6}>
                <TextField
                  error={hasError("PrimaryPhone")}
                  fullWidth
                  required
                  helperText={
                    hasError("PrimaryPhone")
                      ? formState.errors.PrimaryPhone[0]
                      : null
                  }
                  label="Call Back Number"
                  name="PrimaryPhone"
                  onChange={handlePhoneChange}
                  type="text"
                  value={formState.values.PrimaryPhone || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onBlur={() =>
                    props.onClick(
                      oQues.rky,
                      "PrimaryPhone",
                      formatPrimaryPhone(formState.values.PrimaryPhone),
                      -1,
                      ""
                    )
                  }
                />
              </Grid>

              <Grid item md={1} xs={3}>
                <TextField
                  error={hasError("PrimaryExtension")}
                  fullWidth
                  helperText={
                    hasError("PrimaryExtension")
                      ? formState.errors.PrimaryExtension[0]
                      : null
                  }
                  label="Ext."
                  name="PrimaryExtension"
                  onChange={handlePhoneChange}
                  type="text"
                  value={formState.values.PrimaryExtension || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onBlur={() =>
                    props.onClick(
                      oQues.rky,
                      "PrimaryExtension",
                      formState.values.PrimaryExtension,
                      -1,
                      ""
                    )
                  }
                />
              </Grid>

              <Grid item md={2} xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.CanText}
                      onChange={handleChange}
                      name="CanText"
                      color="primary"
                      onBlur={() =>
                        props.onClick(
                          oQues.rky,
                          "CanText",
                          formState.values.CanText === false ? "0" : "1",
                          -1,
                          ""
                        )
                      }
                    />
                  }
                  label="Allow Text?"
                  labelPlacement="end"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError("PatientFirstName")}
                  required
                  fullWidth
                  helperText={
                    hasError("PatientFirstName")
                      ? formState.errors.PatientFirstName[0]
                      : null
                  }
                  label="Patient First Name"
                  name="PatientFirstName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.PatientFirstName || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onBlur={() =>
                    props.onClick(
                      oQues.rky,
                      "PatientFirstName",
                      formState.values.PatientFirstName,
                      -1,
                      ""
                    )
                  }
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  error={hasError("LastName")}
                  required
                  fullWidth
                  helperText={
                    hasError("LastName") ? formState.errors.LastName[0] : null
                  }
                  label="Patient Last Name"
                  name="LastName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.LastName || ""}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onBlur={() =>
                    props.onClick(
                      oQues.rky,
                      "LastName",
                      formState.values.LastName,
                      -1,
                      ""
                    )
                  }
                />
              </Grid>

              {wwConfig && wwConfig[4].isVisible && (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid
                    item
                    md={wwConfig && wwConfig[5].isVisible ? 6 : 12}
                    xs={12}
                  >
                    <DatePicker
                      fullWidth
                      error={hasError("BirthDate")}
                      helperText={
                        hasError("BirthDate")
                          ? formState.errors.BirthDate[0]
                          : null
                      }
                      disableToolbar
                      clearable
                      required={wwConfig[4].isRequired}
                      variant="dialog"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      id="BirthDate"
                      label="Patient Date of Birth"
                      disableFuture={true}
                      minDate="01/01/1900"
                      openTo="year"
                      value={formState.values.BirthDate}
                      // InputLabelProps={{ shrink: true }}
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment position="end">
                            <DateRangeIcon />
                          </InputAdornment>
                        ),
                      }}
                      // onChange={() => undefined}
                      onChange={handleDateChange}
                      // onAccept={handleDateChangeAccepted}
                      onBlur={handleDateBlur}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              )}

              {wwConfig && wwConfig[5].isVisible && (
                <Grid
                  item
                  md={wwConfig && wwConfig[4].isVisible ? 6 : 12}
                  xs={12}
                >
                  <TextField
                    error={hasError("ZipCode")}
                    required={wwConfig[5].isRequired}
                    fullWidth
                    helperText={
                      hasError("ZipCode") ? formState.errors.ZipCode[0] : null
                    }
                    label="Patient Zip Code"
                    name="ZipCode"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.ZipCode || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 5 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() => {
                      props.onClick(
                        oQues.rky,
                        "ZipCode",
                        formState.values.ZipCode,
                        -1,
                        ""
                      );
                    }}
                  />
                </Grid>
              )}

              {wwConfig && wwConfig[6].isVisible && (
                <Grid item md={12} xs={12}>
                  <TextField
                    error={hasError("StreetAddress")}
                    fullWidth
                    required={wwConfig[6].isRequired}
                    helperText={
                      hasError("StreetAddress")
                        ? formState.errors.StreetAddress[0]
                        : null
                    }
                    label="Patient Street Address"
                    name="StreetAddress"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.StreetAddress || ""}
                    variant="outlined"
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() => {
                      props.onClick(
                        oQues.rky,
                        "StreetAddress",
                        formState.values.StreetAddress,
                        -1,
                        ""
                      );
                    }}
                  />
                </Grid>
              )}

              {wwConfig && wwConfig[7].isVisible && (
                <Grid
                  item
                  md={wwConfig && wwConfig[8].isVisible ? 6 : 12}
                  xs={12}
                >
                  <TextField
                    error={hasError("SSN")}
                    required={wwConfig[7].isRequired}
                    fullWidth
                    helperText={
                      hasError("SSN") ? formState.errors.SSN[0] : null
                    }
                    label="Patient Social Security Number"
                    name="SSN"
                    onChange={handleSSNChange}
                    type="text"
                    value={formState.values.SSN || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 11 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.onClick(
                        oQues.rky,
                        "SSN",
                        formState.values.SSN,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>
              )}

              {wwConfig && wwConfig[8].isVisible && (
                <Grid
                  item
                  md={wwConfig && wwConfig[7].isVisible ? 4 : 10}
                  xs={9}
                >
                  <TextField
                    error={hasError("EmailAddress")}
                    required={wwConfig[8].isRequired}
                    fullWidth
                    helperText={
                      hasError("EmailAddress")
                        ? formState.errors.EmailAddress[0]
                        : null
                    }
                    label="Patient Email Address"
                    name="EmailAddress"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.EmailAddress || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.onClick(
                        oQues.rky,
                        "EmailAddress",
                        formState.values.EmailAddress,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>
              )}

              {wwConfig && wwConfig[8].isVisible && (
                <Grid item md={2} xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.CanEmail}
                        onChange={handleChange}
                        name="CanEmail"
                        color="primary"
                        onBlur={() =>
                          props.onClick(
                            oQues.rky,
                            "CanEmail",
                            formState.values.CanEmail === false ? "0" : "1",
                            -1,
                            ""
                          )
                        }
                      />
                    }
                    label="Allow Email?"
                    labelPlacement="end"
                  />
                </Grid>
              )}

              {wwConfig && wwConfig[9].isVisible && (
                <Grid
                  item
                  md={wwConfig && wwConfig[10].isVisible ? 6 : 12}
                  xs={12}
                >
                  <TextField
                    error={hasError("InsuranceCompany")}
                    required={wwConfig[9].isRequired}
                    fullWidth
                    helperText={
                      hasError("InsuranceCompany")
                        ? formState.errors.InsuranceCompany[0]
                        : null
                    }
                    label="Insurance Company"
                    name="InsuranceCompany"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.InsuranceCompany || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.onClick(
                        oQues.rky,
                        "InsuranceCompany",
                        formState.values.InsuranceCompany,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>
              )}

              {wwConfig && wwConfig[10].isVisible && (
                <Grid
                  item
                  md={wwConfig && wwConfig[9].isVisible ? 6 : 12}
                  xs={12}
                >
                  <TextField
                    error={hasError("InsurancePolicyNumber")}
                    required={wwConfig[10].isRequired}
                    fullWidth
                    helperText={
                      hasError("InsurancePolicyNumber")
                        ? formState.errors.InsurancePolicyNumber[0]
                        : null
                    }
                    label="Insurance Policy Number"
                    name="InsurancePolicyNumber"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.InsurancePolicyNumber || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.onClick(
                        oQues.rky,
                        "InsurancePolicyNumber",
                        formState.values.InsurancePolicyNumber,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>
              )}

              {wwConfig && wwConfig[11].isVisible && (
                <Grid item md={12} xs={12}>
                  <TextField
                    error={hasError("NameOfInsured")}
                    required={wwConfig[11].isRequired}
                    fullWidth
                    helperText={
                      hasError("NameOfInsured")
                        ? formState.errors.NameOfInsured[0]
                        : null
                    }
                    label="Name of Insured"
                    name="NameOfInsured"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.NameOfInsured || ""}
                    variant="outlined"
                    inputProps={{ maxLength: 75 }}
                    InputProps={{
                      className: classes.input,
                    }}
                    onBlur={() =>
                      props.onClick(
                        oQues.rky,
                        "NameOfInsured",
                        formState.values.NameOfInsured,
                        -1,
                        ""
                      )
                    }
                  />
                </Grid>
              )}

              {message && (
                <div className={classes.resMessage}>
                  <Alert severity={severity}>{message}</Alert>
                </div>
              )}
            </Grid>
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

PatientInformation.propTypes = {
  className: PropTypes.string,
};

export default PatientInformation;
