import React, { useRef, useState } from "react";
import axios from "axios";
import apiUrl from "../../../common/apiUrl";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import VoicemailIcon from "@material-ui/icons/Voicemail";

function ConfirmationDialog(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleOk = () => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={() => {}}
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="dialog-title">
        <span style={{ fontWeight: 900 }}>Patient Voicemail</span>
      </DialogTitle>

      <DialogContent dividers>
        {props.value && (
          <audio controls>
            <source src={props.value} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

const PlayVoicemail = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);

  const handleClickOpen = async () => {
    const fileName = props.otherinfourl;

    try {
      const result = await axios.post(apiUrl + "/appt/vm-url", {
        objectId: fileName,
      });

      const voiceMailUrl = await result.data.s3GetObjUrl;

      setValue(() => voiceMailUrl);
      setOpen(true);

      return voiceMailUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async (newValue) => {
    setValue(() => null);
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        variant="outlined"
        color="primary"
        border={0}
        // style={{ marginRight: "0.5em" }}
        disabled={props.otherinfourl === null ? true : false}
      >
        <VoicemailIcon fontSize="large" />
      </IconButton>
      <Typography
        style={{ display: "inline-block" }}
        color="textPrimary"
        variant="body1"
      >
        Voicemail
      </Typography>
      <List component="div" role="list">
        <ConfirmationDialog
          classes={{
            paper: classes.paper,
          }}
          id="archive-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />
      </List>
    </>
  );
};
export default PlayVoicemail;
