import * as React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));

const PdfDocument = ({ title, document }) => {
  const classes = useStyles();
  const { useState, useEffect } = React;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 0);
  }, []);

  if (!ready) {
    return null;
  } else {
    return (
      <BlobProvider document={document}>
        {({ url, loading, error }) => {
          if (loading) {
            return (
              <span>
                <HourglassFullIcon />
                generating document...
              </span>
            );
          }
          if (!loading && url) {
            return (
              <div
                style={{
                  textDecoration: "none",
                  whiteSpace: "nowrap",
                }}
              >
                <Tooltip title="Click to download PDF">
                  <a
                    href={url}
                    style={{
                      textDecoration: "none",
                      whiteSpace: "nowrap",
                    }}
                    className={classes.error}
                    download
                  >
                    <FontAwesomeIcon
                      style={{
                        verticalAlign: "bottom",
                        fontSize: "1.75rem",
                      }}
                      icon={faFilePdf}
                    />
                  </a>
                </Tooltip>
                {`    `}
                {document?.props?.data && !!document.props.data.length && (
                  <Tooltip title="Click to download CSV">
                    <CSVLink
                      data={document.props.data}
                      filename={`${title}.csv`}
                      className={classes.success}
                    >
                      <FontAwesomeIcon
                        style={{
                          verticalAlign: "bottom",
                          fontSize: "1.75rem",
                        }}
                        icon={faFileCsv}
                      />
                    </CSVLink>
                  </Tooltip>
                )}
              </div>
            );
          }
          if (error) {
            console.error(error);
            return <p>An error occurred</p>;
          }
          return null;
        }}
      </BlobProvider>
    );
  }
};

export default PdfDocument;
