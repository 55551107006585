import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Card,
  Divider,
  CardContent,
  CardActions,
  CardHeader,
  MenuItem,
} from "@material-ui/core";
import validate from "validate.js";

const schema = {
  partnerName: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
  },
  partnerAbbr: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 20,
    },
    format: {
      pattern: "[a-z0-9]+",
      flags: "i",
      message: "can only contain a-z and 0-9",
    },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  stateAbbr: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  partnerUrl: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
};

const EditReferralForm = (props) => {
  const initialFormState = {
    isValid: false,
    values: {
      partnerId: "",
      partnerName: "",
      partnerAbbr: "",
      city: "",
      stateAbbr: "",
      partnerUrl: "",
    },
    touched: {},
    errors: {},
    isLoading: false,
  };

  const [user, setUser] = useState(props.currentUser);

  const [states] = useState([
    {
      State: "Alabama",
      Abbrev: "Ala.",
      Code: "AL",
    },
    {
      State: "Alaska",
      Abbrev: "Alaska",
      Code: "AK",
    },
    {
      State: "Arizona",
      Abbrev: "Ariz.",
      Code: "AZ",
    },
    {
      State: "Arkansas",
      Abbrev: "Ark.",
      Code: "AR",
    },
    {
      State: "California",
      Abbrev: "Calif.",
      Code: "CA",
    },
    {
      State: "Colorado",
      Abbrev: "Colo.",
      Code: "CO",
    },
    {
      State: "Connecticut",
      Abbrev: "Conn.",
      Code: "CT",
    },
    {
      State: "Delaware",
      Abbrev: "Del.",
      Code: "DE",
    },
    {
      State: "District of Columbia",
      Abbrev: "D.C.",
      Code: "DC",
    },
    {
      State: "Florida",
      Abbrev: "Fla.",
      Code: "FL",
    },
    {
      State: "Georgia",
      Abbrev: "Ga.",
      Code: "GA",
    },
    {
      State: "Hawaii",
      Abbrev: "Hawaii",
      Code: "HI",
    },
    {
      State: "Idaho",
      Abbrev: "Idaho",
      Code: "ID",
    },
    {
      State: "Illinois",
      Abbrev: "Ill.",
      Code: "IL",
    },
    {
      State: "Indiana",
      Abbrev: "Ind.",
      Code: "IN",
    },
    {
      State: "Iowa",
      Abbrev: "Iowa",
      Code: "IA",
    },
    {
      State: "Kansas",
      Abbrev: "Kans.",
      Code: "KS",
    },
    {
      State: "Kentucky",
      Abbrev: "Ky.",
      Code: "KY",
    },
    {
      State: "Louisiana",
      Abbrev: "La.",
      Code: "LA",
    },
    {
      State: "Maine",
      Abbrev: "Maine",
      Code: "ME",
    },
    {
      State: "Maryland",
      Abbrev: "Md.",
      Code: "MD",
    },
    {
      State: "Massachusetts",
      Abbrev: "Mass.",
      Code: "MA",
    },
    {
      State: "Michigan",
      Abbrev: "Mich.",
      Code: "MI",
    },
    {
      State: "Minnesota",
      Abbrev: "Minn.",
      Code: "MN",
    },
    {
      State: "Mississippi",
      Abbrev: "Miss.",
      Code: "MS",
    },
    {
      State: "Missouri",
      Abbrev: "Mo.",
      Code: "MO",
    },
    {
      State: "Montana",
      Abbrev: "Mont.",
      Code: "MT",
    },
    {
      State: "Nebraska",
      Abbrev: "Nebr.",
      Code: "NE",
    },
    {
      State: "Nevada",
      Abbrev: "Nev.",
      Code: "NV",
    },
    {
      State: "New Hampshire",
      Abbrev: "N.H.",
      Code: "NH",
    },
    {
      State: "New Jersey",
      Abbrev: "N.J.",
      Code: "NJ",
    },
    {
      State: "New Mexico",
      Abbrev: "N.M.",
      Code: "NM",
    },
    {
      State: "New York",
      Abbrev: "N.Y.",
      Code: "NY",
    },
    {
      State: "North Carolina",
      Abbrev: "N.C.",
      Code: "NC",
    },
    {
      State: "North Dakota",
      Abbrev: "N.D.",
      Code: "ND",
    },
    {
      State: "Ohio",
      Abbrev: "Ohio",
      Code: "OH",
    },
    {
      State: "Oklahoma",
      Abbrev: "Okla.",
      Code: "OK",
    },
    {
      State: "Oregon",
      Abbrev: "Ore.",
      Code: "OR",
    },
    {
      State: "Pennsylvania",
      Abbrev: "Pa.",
      Code: "PA",
    },
    {
      State: "Rhode Island",
      Abbrev: "R.I.",
      Code: "RI",
    },
    {
      State: "South Carolina",
      Abbrev: "S.C.",
      Code: "SC",
    },
    {
      State: "South Dakota",
      Abbrev: "S.D.",
      Code: "SD",
    },
    {
      State: "Tennessee",
      Abbrev: "Tenn.",
      Code: "TN",
    },
    {
      State: "Texas",
      Abbrev: "Tex.",
      Code: "TX",
    },
    {
      State: "Utah",
      Abbrev: "Utah",
      Code: "UT",
    },
    {
      State: "Vermont",
      Abbrev: "Vt.",
      Code: "VT",
    },
    {
      State: "Virginia",
      Abbrev: "Va.",
      Code: "VA",
    },
    {
      State: "Washington",
      Abbrev: "Wash.",
      Code: "WA",
    },
    {
      State: "West Virginia",
      Abbrev: "W.Va.",
      Code: "WV",
    },
    {
      State: "Wisconsin",
      Abbrev: "Wis.",
      Code: "WI",
    },
    {
      State: "Wyoming",
      Abbrev: "Wyo.",
      Code: "WY",
    },
  ]);

  useEffect(() => {
    setUser(props.currentUser);
  }, [props]);

  useEffect(() => {
    const errors = validate(user.values, schema);

    setUser((user) => ({
      ...user,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [user.values]);

  const handleInputChange = (event) => {
    event.persist();

    setUser((user) => ({
      ...user,
      values: {
        ...user.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...user.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePhoneChange = (event) => {
    event.persist();

    var x = event.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    x = event.target.value = !x[2]
      ? x[1]
      : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

    setUser((user) => ({
      ...user,
      values: {
        ...user.values,
        [event.target.name]:
          event.target.type === "checkbox" ? event.target.checked : x,
      },
      touched: {
        ...user.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.updateReferralPartner(user.values.partnerId, user);
  };

  const hasError = (field) =>
    user.touched[field] && user.errors[field] ? true : false;

  return (
    <Card>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader
          subheader="Update referral partner details below"
          title="Edit Referral Partner"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                error={hasError("partnerName")}
                fullWidth
                helperText={
                  hasError("partnerName") ? user.errors.partnerName[0] : null
                }
                label="Referral Partner Name"
                margin="dense"
                name="partnerName"
                onChange={handleInputChange}
                required
                type="text"
                value={user.values.partnerName || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("partnerAbbr")}
                fullWidth
                helperText={
                  hasError("partnerAbbr") ? user.errors.partnerAbbr[0] : null
                }
                label="Referral Partner Abbreviation"
                margin="dense"
                name="partnerAbbr"
                onChange={handleInputChange}
                required
                type="text"
                value={user.values.partnerAbbr || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("partnerUrl")}
                fullWidth
                helperText={
                  hasError("partnerUrl") ? user.errors.partnerUrl[0] : null
                }
                label="Partner Website"
                margin="dense"
                name="partnerUrl"
                onChange={handleInputChange}
                required
                type="text"
                value={user.values.partnerUrl || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={hasError("city")}
                fullWidth
                helperText={hasError("city") ? user.errors.city[0] : null}
                label="City"
                margin="dense"
                name="city"
                onChange={handleInputChange}
                required
                type="text"
                value={user.values.city || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="State"
                margin="dense"
                name="stateAbbr"
                onChange={handleInputChange}
                required
                select
                type="text"
                value={user.values.stateAbbr || ""}
                variant="outlined"
              >
                {states.map((stateAbbr) => (
                  <MenuItem key={stateAbbr.Code} value={stateAbbr.Code}>
                    {stateAbbr.State}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!user.isValid || props.loading}
            type="submit"
            variant="contained"
          >
            Update Referral Partner
          </Button>
          <Button
            color="primary"
            onClick={() => props.setEditing(false)}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default EditReferralForm;
