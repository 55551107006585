import React, { useState, useEffect } from "react";
import apiUrl from "../common/apiUrl";
import axios from "axios";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import PdfDocument from "./PdfDocument";
import ReportReqsProviderByDate from "../../src/views/Dashboard/components/ReportReqsProviderByDate";
import ReportReferralSource from "../../src/views/Dashboard/components/ReportReferralSource";
import ReportApptDetail from "../../src/views/Dashboard/components/ReportApptDetail";
import ReportProviderAppointments from "../../src/views/Dashboard/components/ReportProviderAppointments";
import ReportReferralsByProvider from "../views/Dashboard/components/ReportReferralsByProvider";
import ReportAgentDetail from "../views/Dashboard/components/ReportAgentDetail";

const PDFLink = ({ title, locationId, startDate, endDate, reportId }) => {
  const [error, setError] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [data, setData] = useState();
  const [attempts, setAttempts] = useState(0);
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    let unmounted = false;

    const fetchData = async () => {
      setRequesting(true);
      if (reportId) {
        try {
          const result = await axios.post(apiUrl + "/dashboard/report", {
            locationId: locationId,
            startDate: startDate,
            endDate: endDate,
            reportId: reportId,
            timeZoneOffset: (new Date().getTimezoneOffset() / 60).toString(),
          });

          if (result && !unmounted) {
            var reportData = result.data.data;
            setData(reportData);
            setRequesting(false);
          }
        } catch (error) {
          setError(true);
          setRequesting(false);
        }
      }
    };

    if (locationId && startDate && endDate) {
      fetchData();
    }

    return () => {
      unmounted = true;
    };
  }, [locationId, startDate, endDate, reportId]);

  const getReport = (id) => {
    switch (id) {
      case 1:
        return (
          <PdfDocument
            title={title}
            document={
              <ReportReqsProviderByDate
                data={data}
                title={title}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
        );

      case 2:
        return (
          <PdfDocument
            title={title}
            document={
              <ReportReferralSource
                data={data}
                title={title}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
        );

      case 3:
        return (
          <PdfDocument
            title={title}
            document={
              <ReportApptDetail
                data={data}
                title={title}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
        );

      case 4:
        return (
          <PdfDocument
            title={title}
            document={
              <ReportProviderAppointments
                data={data}
                title={title}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
        );

      case 5:
        return (
          <PdfDocument
            title={title}
            document={
              <ReportReferralsByProvider
                data={data}
                title={title}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
        );

      case 6:
        return (
          <PdfDocument
            title={title}
            document={
              <ReportAgentDetail
                data={data}
                title={title}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
        );

      default:
        break;
    }
  };

  return (
    <p>
      {!requesting && !data && !error && ""}
      {requesting && (
        <span>
          <HourglassFullIcon /> Building report...
        </span>
      )}
      {data === null && !requesting && !error && (
        <span>No data found for the selected time period</span>
      )}
      {data && !requesting && !error && getReport(reportId)}
      {!requesting && error && (
        <>
          <span>There has been an error. </span>
          {attempts < 3 ? (
            <span
              className="clickable"
              onClick={() => {
                setAttempts(attempts + 1);
                setFetchData(!fetchData);
              }}
            >
              Please try again.
            </span>
          ) : (
            <span>Please try again later.</span>
          )}
        </>
      )}
    </p>
  );
};

export default PDFLink;
